import { useAuth, useCompany } from 'store';
import { routes } from 'config';

const Auth = ({
  allowed,
  hasFeature,
  prodOnly,
  component,
  redirect: Redirect,
  comingSoon: ComingSoon,
  layout: Layout,
  layoutType,
}) => {
  const { loggedIn, subLoggedIn, user } = useAuth();
  const { company } = useCompany();
  const permissions = user.roles;
  const production = user.mode === 'production';
  const verified = user.account_verified;

  if (subLoggedIn) return <Redirect to="/onboarding" />;

  if (loggedIn) {
    if (!company?.signed_sla) return component;
    if (verified) {
      if (permissions.includes(allowed) || allowed === 'all') {
        if (prodOnly && !production) {
          return <Redirect to={routes.dashboard.home} />;
        }
        if (
          (hasFeature && company?.features?.includes(hasFeature)) ||
          !hasFeature
        ) {
          return component;
        }
        return (
          <Layout type={layoutType}>
            <ComingSoon />
          </Layout>
        );
      }
      return <Redirect to={routes.dashboard.home} />;
    }
    return <Redirect to={routes.dashboard.base} />;
  }
  return <Redirect to="/" />;
};

export default Auth;

import { InstantPaymentsIllustration } from 'assets';
import ProductInfoScreen from './ProductInfoScreen';

const PaymentAuthScreen = () => {
  return (
    <ProductInfoScreen
      icon={<InstantPaymentsIllustration />}
      title="Quick and easy payment authorisation"
      description="Store customer details easily with no upfront payment using the widget or API."
    />
  );
};

export default PaymentAuthScreen;

import { Icon } from 'okraui-ts';
import { HeaderBankLogos } from 'assets';
import { useBuilder } from 'store';
import classNames from 'classnames';

const BaseScreen = ({
  children,
  className,
  noBack,
  showProgressBar = true,
  showHeader = true,
  headerLogos = <HeaderBankLogos className="logos-svg" />,
}) => {
  const { builder } = useBuilder();
  const { orientation, mode } = builder;

  return (
    <div
      className={classNames('widget-screen-container', {
        mobile: orientation === 'mobile',
        desktop: orientation === 'desktop',
        transparent: mode === 'transparent',
      })}
    >
      <div className="widget-window">
        <div className="widget-window__toolbar">
          <span />
          <span />
          <span />
        </div>

        <div className={classNames('widget-screen')}>
          {orientation === 'desktop' && showProgressBar && (
            <div className="progress">
              <span />
            </div>
          )}
          {showHeader && (
            <div className="widget-screen__header">
              {!noBack && <Icon name="arrowLeft" className="back-svg" />}

              {headerLogos}
              <Icon name="close" className="close-svg" />
            </div>
          )}
          <div className={classNames(['widget', className])}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default BaseScreen;

import { useRef, useState } from 'react';
import classNames from 'classnames';
import {
  TextField,
  Drag,
  Close,
  BankCover,
  Icon,
  StatusChip,
  Tooltip,
  Toggle,
  Popper,
  useToaster,
  SearchField,
} from 'okraui-ts';
import { useBuilder, useBanks } from 'store';
import { SliderIcon } from 'assets';
import {
  formatBanks,
  handleDrag,
  fugaz,
  handleDragOver,
  parseSelected,
} from '../../../data/Functions';

const TopInstitutions = () => {
  const { builder, setBuilder } = useBuilder();
  const { open } = useToaster();
  const { banks } = useBanks();

  const banksListRef = useRef();
  const { institutions, filter, customInstitutions } = builder;
  const filteredBanks = filter?.banks || [];

  const [searchResults, setSearchResults] = useState([]);
  const [search, setSearch] = useState('');

  const institutionToFormat = customInstitutions ?? institutions;

  const preselectedBanks = formatBanks(
    banks,
    institutionToFormat,
    null,
    filteredBanks,
    setBuilder
  );

  const [topBanks, setTopBanks] = useState(preselectedBanks);
  const [selectedFilter, setSelectedFilter] = useState('usage');

  const handleDrop = () => {
    const bankItems = banksListRef.current.children;
    const newSchema = [];
    for (let i = 0; i < bankItems.length; i++) {
      const schemaValue = bankItems[i].getAttribute('data-dragvalue');
      newSchema.push(schemaValue);
    }

    setBuilder('customInstitutions', newSchema);
    setSelectedFilter('none');
  };

  const handleSearch = ({ target: { value } }) => {
    const search = value.replace(/[^0-9a-z-A-Z@. ]/g, '');
    let banksList = [];
    setSearch(search);
    if (search) {
      const regex = new RegExp(search, 'gi');
      banksList = banks.filter(
        (value) => regex.test(value.name) || regex.test(value?.nickname)
      );
    }
    setSearchResults(banksList || []);
  };

  const handleClick = (slug) => {
    if (customInstitutions?.length > 7) {
      return open({
        active: true,
        status: 'warning',
        message: 'Please delete an institution before you add a new one.',
      });
    }
    setSearchResults([]);
    setBuilder('customInstitutions', [...customInstitutions, slug]);
    return setTopBanks(parseSelected(banks, [...customInstitutions, slug]));
  };

  const showList = () => {
    let show = !!search;
    if (searchResults?.length > 1) show = true;
    if (
      searchResults.length === 1 &&
      !customInstitutions.includes(searchResults[0].slug)
    ) {
      show = true;
    }
    return show;
  };

  function sortAlphabetically() {
    const sorted = topBanks.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    const newSchema = sorted.map((bank) => bank.slug);
    setBuilder('customInstitutions', newSchema);
    setTopBanks(sorted);
  }

  function usageOrder() {
    const topBanksSlug = topBanks.map((b) => b.slug);
    const newTopUsage = fugaz.filter((f) => topBanksSlug.includes(f));

    const usageBanks = parseSelected(banks, newTopUsage);
    setBuilder('customInstitutions', newTopUsage);
    setTopBanks(usageBanks);
  }

  return (
    <div className="default-screen__right">
      <div className="filter">
        <p className="medium body3">Top eight (8) institutions</p>
        <Tooltip
          element={<Icon name="info" size="sm" />}
          content={
            <p className="caption  medium">
              Top institutions will be restored to default if you do not replace
              a deleted institution.
            </p>
          }
        />

        <div className="sliders">
          <Popper
            element={
              <div className="sliders__button">
                <SliderIcon />
              </div>
            }
            content={
              <>
                <div
                  className={classNames('filter-option', {
                    active: selectedFilter === 'usage',
                  })}
                  onClick={() => {
                    setSelectedFilter('usage');
                    usageOrder();
                  }}
                >
                  <p className="body3 medium">Usage order</p>
                  {selectedFilter === 'usage' && (
                    <Icon name="check" size="sm" />
                  )}
                </div>

                <div
                  className={classNames('filter-option', {
                    active: selectedFilter === 'alpha',
                  })}
                  onClick={() => {
                    setSelectedFilter('alpha');
                    sortAlphabetically();
                  }}
                >
                  <p className="body3 medium">Alphabetical order</p>

                  {selectedFilter === 'alpha' && (
                    <Icon name="check" size="sm" />
                  )}
                </div>
              </>
            }
          />
        </div>
      </div>
      <TextField
        leftAddon={<Icon name="search" size="sm" />}
        placeholder="Add an institution"
        onChange={handleSearch}
        className="search-form"
      />

      {showList() && (
        <div className="banks-search-results">
          {searchResults.length ? (
            searchResults?.map((bank) => {
              if (!customInstitutions.includes(bank.slug)) {
                return (
                  <div
                    key={`search-bank-${bank.slug}`}
                    onClick={() => handleClick(bank.slug)}
                  >
                    <BankCover bank={bank} />
                  </div>
                );
              }
              return (
                <div key={`search-bank-${bank.slug}`}>
                  <BankCover bank={bank} />
                  <StatusChip>Added</StatusChip>
                </div>
              );
            })
          ) : (
            <p className="empty-search">No result found</p>
          )}
        </div>
      )}

      <div className="bank-list" ref={banksListRef}>
        {topBanks.map((bank) => {
          return (
            <div
              data-dragvalue={bank?.slug}
              key={`your-col-${bank?.slug}`}
              className="bank-list__item"
              draggable={true}
              onDrag={handleDrag}
              onDragOver={handleDragOver}
              onDragEnd={handleDrop}
            >
              <div>
                <Drag />
                <BankCover bank={bank} />
              </div>

              <div
                className="bank-list-remove"
                onClick={() => {
                  const remainingInstitutions = customInstitutions.filter(
                    (slug) => slug !== bank.slug
                  );
                  setTopBanks(parseSelected(banks, remainingInstitutions));
                  setBuilder('customInstitutions', remainingInstitutions);
                }}
              >
                <Close />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const OtherInstitutions = () => {
  const { builder, setBuilder } = useBuilder();
  const { banks } = useBanks();

  const [searchValue, setSearchValue] = useState('');

  const { institutions, filter } = builder;

  const allBankSlugs = filter?.banks || [];

  const parseBanks = (allBanks = banks) => {
    const bnks = [];
    for (let i = 0; i < allBanks.length; i++) {
      const bank = allBanks[i];
      if (!institutions.includes(bank.slug)) {
        bnks.push({
          value: bank.slug,
          display: bank?.nickname || bank?.name,
          bnk: bank,
        });
      }
    }
    return bnks;
  };

  const [searchResults, setSearchResults] = useState(parseBanks());

  const handleChange = (value) => {
    setBuilder('filter', {
      industry_type: '',
      banks: [...institutions, ...value],
    });
    return value;
  };

  const handleSearch = ({ target: { value } }) => {
    const search = value.replace(/[^0-9a-z-A-Z@. ]/g, '');
    setSearchValue(search);

    //  when value is cleared using backsapce
    if (search === '') {
      setSearchResults(parseBanks());
      return;
    }

    let banksList = [];

    if (search) {
      const regex = new RegExp(search, 'gi');
      banksList = banks.filter((bank) => {
        return regex.test(bank.name) || regex.test(bank?.nickname);
      });
    }

    setSearchResults(parseBanks(banksList));
  };

  return (
    <div className="default-screen__right">
      <div className="filter">
        <p className="medium body3">Other institutions</p>
      </div>

      <SearchField
        onChange={handleSearch}
        value={searchValue}
        leftAddon={<Icon name="search" size="sm" />}
        placeholder="Find an institution"
        className="search-form"
      />
      <div className="bank-list">
        {searchResults.map((bank) => {
          return (
            <div
              data-dragvalue={bank.slug}
              key={`your-col-${bank.slug}`}
              className="bank-list__item"
            >
              <BankCover bank={bank.bnk} />
              <div>
                <Toggle
                  size="sm"
                  onChange={({ target }) => {
                    const { checked } = target;

                    if (checked) {
                      handleChange([...allBankSlugs, bank.value]);
                    } else {
                      const { value } = bank;
                      const updatedList = allBankSlugs.filter((bank) => {
                        return bank !== value;
                      });
                      handleChange(updatedList);
                    }
                  }}
                  checked={allBankSlugs.includes(bank.value)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CustomInsitutions = () => {
  const [selectedTag, setSelectedTag] = useState('top');
  return (
    <div className="all-screen custom-institution">
      <div className="header-section">
        <p className="medium body3">Custom institutions</p>
        <div className="options">
          <div
            onClick={() => setSelectedTag('top')}
            className={classNames('option', {
              active: selectedTag === 'top',
            })}
          >
            <p className="medium body3">Top institutions</p>
          </div>

          <div
            onClick={() => setSelectedTag('other')}
            className={classNames('option', {
              active: selectedTag === 'other',
            })}
          >
            <p className="medium body3">Other institutions</p>
          </div>
        </div>
      </div>

      <div className="default-screen__content">
        {selectedTag === 'top' && <TopInstitutions />}
        {selectedTag === 'other' && <OtherInstitutions />}
      </div>
    </div>
  );
};

export default CustomInsitutions;

import { useHistory } from 'react-router-dom';
import { Modal } from 'okraui-ts';
import { useBuilder } from 'store';
import { routes } from 'config';

const CloseModal = ({ active, close }) => {
  const { clearBuilder } = useBuilder();
  const history = useHistory();

  const confirmModal = () => {
    clearBuilder();
    history.push(routes.business.apps);
  };

  return (
    <Modal
      modalClass=""
      active={active}
      confirm={confirmModal}
      title="Exit app builder?"
      type="alert"
      close={close}
      confirmTxt="Exit app builder"
      status="warning"
      closeTxt="Cancel"
    >
      <p className="body2">
        Your progress will be lost and you’ll have to start afresh if you exit
        app builder.
      </p>
    </Modal>
  );
};

export default CloseModal;

import { useState, useEffect } from 'react';
import { Card, Button, PasswordField, useToaster } from 'okraui-ts';
import OkraLogo from 'assets/Images/OkraFullLogo.png';

import { api } from 'config';
import { PasswordChecker } from 'components';

const NewPassword = (props) => {
  const { open } = useToaster();
  const [user, setUser] = useState({});
  useEffect(() => {
    const path = window.location.pathname.split('/');
    const tokenArr = path.slice(3, path.length);
    const email = path[2];
    const token = tokenArr.join('/');
    if (!token || !email) {
      props.history.push('/login');
    } else {
      setUser({ token, email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [cpassword, setcPassword] = useState('');
  const [passwordStatus, setPasswordStatus] = useState(false);
  const [disabledPWButton, setDisabledPWButton] = useState(true);

  const collectPassword = (data) => {
    setPassword(data);
  };

  const collectPasswordStatus = (data) => {
    setPasswordStatus(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    api({
      url: 'auth/change-password',
      body: { ...user, password },
    }).then((res) => {
      setLoading(false);
      if (res.status === 'success') {
        open({
          active: true,
          title: 'Password Reset Successful',
          message: 'Please log in your account',
          status: 'success',
        });
        props.history.push('/login');
      } else {
        open({
          active: true,
          title: 'Password Reset Failed',
          message: res.message || 'Error changing password, incorrect details',
          status: 'error',
        });
      }
    });
  };

  useEffect(() => {
    if (password === cpassword && passwordStatus) {
      setDisabledPWButton(false);
    } else {
      setDisabledPWButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, cpassword]);

  return (
    <div className="auth login-layout new-password">
      <img src={OkraLogo} alt="Okra Logo" className="auth__logo" />
      <Card>
        <form onSubmit={handleSubmit}>
          <h4 className="auth__title">Enter a new password.</h4>
          <PasswordChecker
            label="Password"
            collectPassword={collectPassword}
            passwordStatus={collectPasswordStatus}
          />
          <PasswordField
            label="Confirm Password"
            value={cpassword}
            onChange={(e) => setcPassword(e.target.value)}
            required
          />
          <Button
            type="submit"
            variant="primary"
            loading={loading}
            disabled={disabledPWButton}
          >
            Update password
          </Button>
        </form>
      </Card>
    </div>
  );
};

export default NewPassword;

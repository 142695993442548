const Features = () => (
  <div className="payments-overview-features">
    <div>
      <span className="payments-overview-features__icon icon-green"></span>
      <p className="p-semibold payments-overview-features__title">
        Create a link
      </p>
      <p>
        Create a payment link to sell a product or service, manage a
        subscription, or collect a donation. You can enable one-time, recurring,
        or future payments.
      </p>
    </div>
    <div>
      <span className="payments-overview-features__icon icon-purple"></span>
      <p className="p-semibold payments-overview-features__title">
        Share your link
      </p>
      <p>
        Paste or embed the link anywhere. The same link can be shared with many
        customers and used across multiple channels.
      </p>
    </div>
    <div>
      <span className="payments-overview-features__icon icon-blue"></span>
      <p className="p-semibold payments-overview-features__title">
        Receive payments
      </p>
      <p>
        Use the Dashboard to monitor pending payments, and have funds from
        successful payments deposited into your bank account.
      </p>
    </div>
  </div>
);

export default Features;

import { Toggle, Tooltip, Button } from 'okraui-ts';
import { useBuilder } from 'store';
import { addRemove } from 'helpers';
import clsx from 'classnames';

const Balance = () => {
  const { builder, setBuilder } = useBuilder();
  const { billable_products, balance } = builder;
  const hasBalance = billable_products.includes('balance');

  const instantPaymentEnabled = builder.type === 'one-time';

  return (
    <>
      <div className="product-section-info">
        <p className="semibold body2">Balance</p>
        <p className="subtitle body3">
          Retrieve customers' current and previous balances in real-time.
        </p>

        <a
          href="https://docs.okra.ng/account-data/balance"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="primary-link">Read the docs </Button>
        </a>
      </div>

      <div className="product-section">
        <div
          className={clsx('action-section', {
            disabled: instantPaymentEnabled,
          })}
        >
          <p className="body3 medium">Enable balance</p>
          <Toggle
            checked={hasBalance}
            disabled={instantPaymentEnabled}
            onChange={() => {
              const newPrds = addRemove(billable_products, 'balance');
              setBuilder('billable_products', newPrds);
            }}
          />
        </div>
      </div>

      {hasBalance ? (
        <div className="product-section">
          <div className="action-section account-balance">
            <div className="action-section__content">
              <p className="medium body3">Enable account balance</p>
              <p className="body3 mt-8">
                Allow customers see their account balance before connecting
                their account.
              </p>
            </div>
            <Toggle
              checked={balance?.showBalance}
              onChange={({ target }) => {
                const newValue = { ...balance, showBalance: target.checked };
                setBuilder('balance', newValue);
              }}
            />
          </div>
        </div>
      ) : (
        <Tooltip
          element={
            <div className="product-section">
              <div className="action-section account-balance disabled">
                <div className="action-section__content">
                  <p className="medium body3">Enable account balance</p>
                  <p className="body3 mt-8">
                    Allow customers see their account balance before connecting
                    their account.
                  </p>
                </div>
                <Toggle disabled />
              </div>
            </div>
          }
          content={
            <p className="tooltip-inner caption medium">
              Please enable the Balance product to activate account balance.
            </p>
          }
        />
      )}
    </>
  );
};

export default Balance;

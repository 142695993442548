import classNames from 'classnames';
import { useBuilder } from 'store';
import DefaultInstitutions from './DefaultInstitutions';
import CustomInstitutions from './CustomInstitutions';

const Institutions = () => {
  const { builder } = useBuilder();
  const { institutions_type = 'default' } = builder;
  return (
    <div
      className={classNames('builder-extra-screen', 'institutions-screens', {})}
    >
      {institutions_type === 'default' && <DefaultInstitutions />}
      {institutions_type === 'custom' && <CustomInstitutions />}
    </div>
  );
};

export default Institutions;

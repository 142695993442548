import { Toggle, TextField, TextArea } from 'okraui-ts';
import { useAuth, useCompany, useBuilder } from 'store';

const Account = () => {
  const { builder, setBuilder } = useBuilder();
  const {
    app_title,
    redirect_url,
    widget_success,
    name,
    multi_account,
    continue_cta,
  } = builder;
  const { user } = useAuth();
  const { company } = useCompany();
  const appName = user?.project?.name || company?.app_name || company?.name;
  const widgetName = name === 'This Client' ? appName : name || appName;
  const instantPaymentEnabled =
    builder.type === 'one-time' || builder.debitLater;

  return (
    <>
      <div className="product-section-info">
        <p className="medium body3">Account connected</p>
        <p className="subtitle body3">
          Customize what’s shown to your customers after connecting their bank
          accounts to your app.
        </p>
      </div>
      <div className="product-section">
        <div>
          <TextField
            label="Title"
            placeholder={
              instantPaymentEnabled ? 'Payment successful' : 'Success!'
            }
            labelTooltip=""
            value={app_title}
            onChange={({ target }) => {
              setBuilder('app_title', target.value);
            }}
          />
        </div>

        <div>
          <TextArea
            variant="primary"
            label="Success message"
            placeholder={`Your account has successfully been linked to ${widgetName}.`}
            value={widget_success}
            onChange={({ target }) => {
              setBuilder('widget_success', target.value);
            }}
          />
        </div>

        <div>
          <TextField
            label="Redirect URL (Optional)"
            placeholder="https://example.com"
            labelTooltip="The link users get directed to after using the widget."
            value={redirect_url}
            onChange={({ target }) => {
              setBuilder('redirect_url', target.value);
            }}
          />
        </div>

        <div>
          <TextField
            label="Exit button label"
            placeholder="Continue"
            value={continue_cta}
            onChange={({ target }) => {
              setBuilder('continue_cta', target.value);
            }}
            maxLength={10}
          />
        </div>

        {!instantPaymentEnabled && (
          <div className="mt-8">
            <div className="action-section">
              <p className="body3 medium">Link new account button</p>
              <Toggle
                checked={multi_account}
                onChange={({ target }) => {
                  setBuilder('multi_account', target.checked);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Account;

import { createContext, useContext, useReducer, useMemo } from 'react';
import { createData, readData, clearData } from 'helpers';
import builderReducer, { initState } from './BuilderReducer';

const BuilderContext = createContext();

const BuilderProvider = (props) => {
  const [state, dispatch] = useReducer(builderReducer, initState, () => {
    const localData = readData('builder');
    return localData || initState;
  });
  const value = useMemo(() => [state, dispatch], [state]);
  return <BuilderContext.Provider value={value} {...props} />;
};

const useBuilder = () => {
  const context = useContext(BuilderContext);

  if (!context) {
    throw new Error(`useBuilder must be used within a BuilderProvider`);
  }
  const [state, dispatch] = context;

  const updateBuilder = (data) => {
    const newData = { ...state, ...data };
    createData('builder', newData);
    dispatch({ type: 'UPDATE_BUILDER', data: newData });
    return {
      status: 'success',
      message: 'Updated builder successfully',
      builder: newData,
    };
  };

  const setBuilder = (name, value) => {
    const newData = { ...state, [name]: value };
    createData('builder', newData);
    dispatch({ type: 'UPDATE_BUILDER', data: newData });
    return {
      status: 'success',
      message: 'Updated builder successfully',
      builder: newData,
    };
  };
  const clearBuilder = () => {
    dispatch({ type: 'CLEAR_BUILDER' });
    clearData('builder');
  };
  return {
    builder: state,
    updateBuilder,
    clearBuilder,
    setBuilder,
  };
};

export { BuilderProvider, useBuilder };

import { IdentityIllustration } from 'assets';
import ProductInfoScreen from './ProductInfoScreen';

const IdentityScreen = () => {
  return (
    <ProductInfoScreen
      icon={<IdentityIllustration />}
      title="Validate customers' identities"
      description="The Identity API helps you verify users' identities by accessing the information stored with their financial accounts."
      docsLink="https://docs.okra.ng/account-data/identity"
    />
  );
};

export default IdentityScreen;

import { useState } from 'react';
import { OkraLogo, Close, Button, Tooltip, useToaster } from 'okraui-ts';
import { NavLink } from 'react-router-dom';
import { Mobile, Desktop } from 'assets';
import { useBuilder, useCompany } from 'store';
import { api } from 'config';
import classNames from 'classnames';
import CloseModal from './CloseModal';
import PublishModal from './PublishModal';
import { fugaz } from '../data/Functions';

const Links = [
  { to: '/app-builder/edit/products', name: 'Products' },
  { to: '/app-builder/edit/institutions', name: 'Institutions' },
  {
    to: '/app-builder/edit/widget-properties',
    name: 'Settings',
  },
];

const parseBuilderContext = (builder, hasPaymentEnabled) => {
  const {
    account_type,
    customInstitutions,
    institutions_type,
    auth,
    variablePayment,
    amount: formAmount,
    _id,
    type,
    debitLater,
    ...rest
  } = builder;

  const amount = variablePayment
    ? {
        variablePayment: true,
      }
    : parseFloat(formAmount) > 0
    ? {
        amount: parseFloat(formAmount) * 100,
      }
    : {
        amount: Number(formAmount),
      };

  // const id = type === 'one-time' && !_id ? {} : { _id };

  return {
    ...rest,
    ...amount,
    // ...id,
    account_type: 'individual',
    type: hasPaymentEnabled && debitLater ? 'ongoing' : 'one-time',
    customInstitutions,
    institutions_type,
    auth: {
      ...auth,
      // extre precaution to turn of payment authorization if company doesn't have payments as a feature enabled
      // debitLater: hasPaymentEnabled ? auth.debitLater : false,
    },
    debitLater: hasPaymentEnabled ? debitLater : false,
    institutions: institutions_type === 'custom' ? customInstitutions : fugaz,
  };
};

const EditHeader = () => {
  const { builder, setBuilder } = useBuilder();
  const { open } = useToaster();
  const [closeModal, setCloseModal] = useState(false);
  const {
    company: { features = [] },
  } = useCompany();

  const hasPaymentEnabled = features.includes('payments');

  const isPaymentApp = builder.type === 'one-time' || builder.debitLater;

  const [publishInfo, setPublishInfo] = useState({
    modal: false,
    short_url: builder?.url || '',
    surl: builder?.surl || '',
    _id: builder?._id || '',
  });

  const [loading, setLoading] = useState(false);
  const publish = () => {
    if (builder?.institutions?.length < 8) {
      return open({
        active: true,
        status: 'error',
        message: 'Please select at least 8 institutions to continue',
      });
    }
    setLoading(true);

    return api({
      url: isPaymentApp ? 'pay/link/create' : 'app/create',
      body: parseBuilderContext(builder, hasPaymentEnabled),
    }).then((res) => {
      setLoading(false);
      if (res.status === 'success') {
        const { short_url, surl, _id } = res.data;
        return setPublishInfo({
          modal: true,
          short_url,
          surl,
          _id,
        });
      }
      return open({
        active: true,
        message:
          res.message ||
          'Could not create link, please check details and try again',
        status: 'error',
      });
    });
  };
  return (
    <>
      <div className="edit-header">
        <div>
          <div className="mini-section">
            <OkraLogo />
          </div>
          <div className="edit-header-links">
            {Links.map((link, i) => (
              <NavLink
                to={link.to}
                className="action-text"
                key={`header-link-${i}`}
              >
                {link.name}
              </NavLink>
            ))}
          </div>
        </div>
        <div>
          <div className="mini-section">
            <Tooltip
              element={
                <Button
                  variant="secondary-text"
                  onClick={() => setBuilder('orientation', 'desktop')}
                  className={classNames('orient', {
                    active: builder.orientation === 'desktop',
                  })}
                >
                  <Desktop />
                </Button>
              }
              content={
                <p className="tooltip-inner medium body2">Desktop preview</p>
              }
            />

            <Tooltip
              element={
                <Button
                  variant="secondary-text"
                  onClick={() => setBuilder('orientation', 'mobile')}
                  className={classNames('orient', {
                    active: builder.orientation === 'mobile',
                  })}
                >
                  <Mobile />
                </Button>
              }
              content={
                <p className="tooltip-inner medium body2">Mobile preview</p>
              }
            />
          </div>
          <div className="mini-section not-left">
            <Button size="sm" onClick={publish} loading={loading}>
              Publish App
            </Button>
          </div>
          <div
            className="edit-header-close"
            onClick={() => setCloseModal(true)}
          >
            <Close />
            <p className="action-text medium">Exit</p>
          </div>
        </div>
      </div>
      <CloseModal active={closeModal} close={() => setCloseModal(false)} />
      <PublishModal
        active={publishInfo.modal}
        close={() => setPublishInfo({ modal: false })}
        id={publishInfo._id}
        surl={publishInfo.surl}
        short_url={publishInfo.short_url}
        isPay={isPaymentApp}
      />
    </>
  );
};

export default EditHeader;

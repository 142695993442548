import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Legend } from 'recharts';

const CircleChart = ({ data, caption, title, total }) => {
  const totalData = total || data?.reduce((acc, val) => acc + val.value, 0);

  const Content = () => (
    <div className="center">
      <p className="caption">{caption}</p>{' '}
      <h4>{totalData?.toLocaleString()}</h4>
    </div>
  );

  return (
    <div className="okra-circlechart">
      <h4 className="okra-circlechart-title">{title}</h4>
      <div className="okra-circlechart-content">
        <PieChart width={240} height={240}>
          <Pie
            data={data}
            innerRadius={80}
            outerRadius={120}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
          >
            {data?.map((value, index) => (
              <Cell key={`cell-${index}`} fill={value.color} />
            ))}
          </Pie>
          <Legend
            content={<Content />}
            chartWidth={240}
            chartHeight={240}
            align="center"
            verticalAlign="middle"
          />
        </PieChart>
        <div className="okra-circlechart-info">
          {data?.map(({ name, value, color }, i) => (
            <div key={`circleinfo-${i}`}>
              <p className="caption">{name}</p>
              <h4>
                {value}
                <span className="caption semibold" style={{ color }}>
                  {value
                    ? (((value || 1) / (totalData || 1)) * 100).toFixed(2)
                    : 0}
                  %
                </span>
              </h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

CircleChart.propTypes = {
  caption: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number,
    })
  ),
  title: PropTypes.string,
  total: PropTypes.number,
};

export default CircleChart;

import { createContext, useContext, useReducer, useMemo } from 'react';

const BanksContext = createContext();

const banksReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE': {
      const { data } = action;
      return [...data];
    }
    case 'REMOVE': {
      return [];
    }
    default: {
      return state;
    }
  }
};

const BanksProvider = (props) => {
  const [state, dispatch] = useReducer(banksReducer, [], () => {
    const localData = localStorage.getItem('banks');
    return localData ? JSON.parse(localData) : [];
  });
  const value = useMemo(() => [state, dispatch], [state]);
  return <BanksContext.Provider value={value} {...props} />;
};

const useBanks = () => {
  const context = useContext(BanksContext);
  if (!context) {
    throw new Error(`useBanks must be used within a BanksProvider`);
  }
  const [state, dispatch] = context;
  const updateBanksArray = (array) => {
    const localBanks = localStorage.getItem('banks');
    const parsedLocalBanks = localBanks ? JSON.parse(localBanks) : [];
    // const newBanks = [...banks, ...obj];

    if (!parsedLocalBanks.length) {
      localStorage.setItem('banks', JSON.stringify(array));
      dispatch({ type: 'UPDATE', data: array });
    }
  };
  const remove = () => {
    localStorage.removeItem('banks');
    dispatch({ type: 'REMOVE' });
  };
  return {
    banks: state,
    updateBanksArray,
    remove,
  };
};

export { BanksProvider, useBanks };

import { useState, useEffect, createContext } from 'react';

import io from 'socket.io-client';

import { urls } from 'config';
import { useCompany } from 'store/Company';
import { useSla } from 'store/SLA';
import { useAuth } from 'store/Auth';
import { useBanks } from 'store/Banks';
import HandleSocketMsgs from './SocketHandler';
import { useToaster } from 'okraui-ts';

const socket = io(urls.socket_url, {
  path: '/v1/dash-socket',
  transports: ['websocket'],
  reconnection: true,
  autoConnect: false,
});
const SocketContext = createContext();

const SocketProvider = (props) => {
  const { user, loggedIn, updateUserObj, logout } = useAuth();
  const { company } = useCompany();

  const { updateCompanyObj } = useCompany();
  const { updateSlaObj } = useSla();
  const { updateBanksArray } = useBanks();
  const { open } = useToaster();
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (loggedIn) {
      socket.connect();
    } else {
      socket.disconnect();
    }
    socket.on('connect', () => !connected && loggedIn && setConnected(true));
    socket.connected && loggedIn && !connected && setConnected(true);
    if (connected && !loggedIn) setConnected(false);
    socket.on('disconnect', () => {
      setConnected(false);
    });
    return () => socket.off('dash-msg', HandleSocketMsgs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  useEffect(() => {
    if (connected && loggedIn) {
      const { project } = user;
      socket.emit('init-dash', {
        id: user._id || user.id,
        role: user.role,
        company: company._id,
      });
      if (company && project?._id) {
        socket.emit('get-info', {
          user: user._id || user.id,
          withBanks: true,
        });
      }
      socket.on('dash-msg', (notification) =>
        HandleSocketMsgs(
          notification,
          updateUserObj,
          updateCompanyObj,
          updateSlaObj,
          updateBanksArray,
          open,
          logout
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);
  return <SocketContext.Provider value={socket} {...props} />;
};

export { SocketProvider, socket };

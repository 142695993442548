import PropTypes from 'prop-types';

const DisplayHistory = ({
  search_keeps = [],
  moveTo,
  deleteKeep,
  reactHtmlParser,
  icons = {},
}) => {
  return search_keeps.map((keep, idx) => {
    const { icon, name, location, id } = keep;
    if (icon && name && location) {
      return (
        <div className="search-group-row" key={`row-${idx}`}>
          <div
            className="cursor"
            onClick={() => moveTo(name, icon, location, id)}
          >
            <i className="search-group-row-label">
              {icons[icon] || icons.pages}
            </i>
            <p>{reactHtmlParser(name)}</p>
          </div>
          <i
            className={`search-group-row-action fa fa-times`}
            onClick={() => deleteKeep(idx)}
          ></i>
        </div>
      );
    }
    return true;
  });
};

DisplayHistory.propTypes = {
  deleteKeep: PropTypes.func,
  icons: PropTypes.object,
  moveTo: PropTypes.func,
  reactHtmlParser: PropTypes.func,
  search_keeps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      location: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
};

export default DisplayHistory;

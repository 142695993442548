import { useScrollToTop } from 'hooks';
import { BottomLeftBackground, TopRightBackground } from 'assets';

import { useLocation } from 'react-router-dom';

const baseLoginURL = '/login';

const OnboardingLayout = ({ children, type = '' }) => {
  useScrollToTop();

  const { pathname } = useLocation();

  const showOnLoginRoutes = pathname.includes(baseLoginURL);
  const showLoginBackground = showOnLoginRoutes || pathname === '/';

  return (
    <div className={`onboarding-layout ${type}`}>
      <div className="onboarding-layout-body">
        <div className="onboarding-layout-body-children">{children}</div>

        {showLoginBackground && (
          <BottomLeftBackground className="left-background-image desktop-only" />
        )}

        {showLoginBackground && (
          <TopRightBackground className="right-background-image desktop-only" />
        )}
      </div>
    </div>
  );
};

export default OnboardingLayout;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { TextArea, Modal } from 'okraui-ts';
import { api } from 'config';
import { currencyConverter, formatAsMoney } from 'helpers';
import { Info } from 'assets';
import { useAuth } from 'store';
import { getKeysForApi } from 'helpers/getKeys';

const baseUrl = {
  sandbox: 'https://api.dev.okra.ng/v2/',
  // sandbox: 'http://localhost:5005/v2/',
  dev: 'https://api.dev.okra.ng/v2/',
  staging: 'https://api.staging.okra.ng/v2/',
  production: 'https://api.okra.ng/v2/',
};
const env = process.env.REACT_APP_ENV || 'dev';

const defaultModalOptions = {
  title: 'Initiate refund',
  type: 'standard',
  status: 'success',
  message: '',
  closeTxt: 'Cancel',
};
const RefundModal = ({ active, close, payment }) => {
  const {
    user: { mode },
  } = useAuth();
  const [formData, setFormData] = useState({
    reason: '',
    payment_id: payment._id,
  });
  const [loading, setLoading] = useState(false);
  const [modalOptions, setModalOptions] = useState(defaultModalOptions);
  const [refresh, setRefresh] = useState(false);
  const handleSubmit = async () => {
    // if ((payment.currency === 'USD' && formData.amount < 10) || (payment.currency !== 'USD' && formData.amount < 100)) {
    //   open({
    //     active: true,
    //     status: 'error',
    //     message: 'Your payout amount is less than ₦100/$10',
    //   });
    //   return
    // }
    // if (formData.amount * 100 > payment.amount) {
    //   open({
    //     active: true,
    //     status: 'error',
    //     message: 'You cannot refund more than the payment amount',
    //   });
    //   return
    // }
    setLoading(true);
    const keys = await getKeysForApi();
    const apiKey =
      mode === 'production' ? keys?.production_key : keys?.sandbox_key;

    const { secret: api_key } = apiKey;

    const { status, message = '' } = await api({
      method: 'POST',
      body: {
        payment_id: payment._id,
        // amount: payment.amount,
        note: formData.reason,
        dashboard: true,
        isRefund: true,
      },
      externalUrl: `${baseUrl[env]}pay/refund`,
      token: api_key,
    });

    setLoading(false);
    if (status === 'success') {
      setRefresh(true);
      setModalOptions({
        title: 'Refund successful',
        type: 'alert',
        status: 'success',
        message: 'Refund has been initated',
        closeTxt: 'Done',
      });
    } else {
      setRefresh(false);
      setModalOptions({
        title: 'Error initiating refund',
        type: 'alert',
        status: 'failed',
        message: message || 'Some error occurred, please try again',
        closeTxt: 'Close',
      });
    }
  };

  const handleChange = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const closeModal = () => {
    close(refresh);
    setLoading(false);
    setFormData({ reason: '', payment_id: null });
    setTimeout(() => {
      setRefresh(false);
      setModalOptions(defaultModalOptions);
    }, 1000);
  };

  return (
    <Modal
      title={modalOptions.title}
      active={active}
      close={closeModal}
      confirmTxt="Refund"
      confirm={modalOptions.type === 'standard' ? handleSubmit : null}
      closeTxt={modalOptions.closeTxt}
      loading={loading}
      type={modalOptions.type}
      status={modalOptions.status}
      modalClass={`support-modal  ${loading ? 'refund-modal-loading' : ''}`}
    >
      {modalOptions.type === 'standard' ? (
        <>
          <div className="main-sub-info">
            <div>
              <Info />
            </div>
            <p>Refunds can take up to 24 hours.</p>
          </div>
          <div className="medium space-below">
            <p>Refund amount</p>
            <p>{`${currencyConverter(payment.currency)}${
              payment && payment.amount
                ? formatAsMoney(payment.amount / 100)
                : 0
            }`}</p>
          </div>
          {/* <TextField
              label="How much do you want to refund?"
              placeholder="Amount"
              type="currency"
              name="amount"
              className="space-below"
              currency={payment.currency || 'NGN'}
              value={formData.amount}
              onChange={handleChange}
              minValue={{ USD: 10, NGN: 100 }}
              maxValue={payment.currency === 'USD' ? { USD: payment.amount / 100 } : {NGN: payment.amount / 100}}
              message={
                <span>
                  The maximum refund amount is{' '}
                  <span className="bold">{currencyConverter(payment.currency)}{formatAsMoney(payment.amount / 100)}</span>
                </span>
              }
            /> */}
          <TextArea
            placeholder="Briefly state a reason"
            type="text"
            name="reason"
            label="Reason for refund (Optional)"
            onChange={handleChange}
            value={formData.reason}
          />
        </>
      ) : (
        <p className="center dark-grey small">{modalOptions.message}</p>
      )}
    </Modal>
  );
};

RefundModal.propTypes = {
  active: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  payment: PropTypes.object.isRequired,
};

export default RefundModal;

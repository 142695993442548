import { Link } from 'react-router-dom';
import { Card, Button, ImageStack, Avatar, useToaster } from 'okraui-ts';
import OkraLogo from 'assets/Images/OkraFullLogo.png';
import { ExternalLink } from 'assets';
import { useState } from 'react';
import { api } from 'config';

const Images = (names) => {
  return names.map((name) => {
    return <Avatar name={`${name.firstname} ${name.lastname}`} />;
  });
};

const ExistingTeam = (props) => {
  const [loading, setLoading] = useState(false);
  const { open } = useToaster();

  const { location } = props;
  const { user, company, domain } = location?.state || {};
  if (!company || !user) props.history.push('/register');

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    api({
      url: 'auth/invite',
      body: { user: user._id, company: company._id },
    }).then((res) => {
      setLoading(false);
      if (res.status === 'success') {
        props.history.push('/pending-invite', { type: 'invite', company });
      } else {
        open({
          active: true,
          message:
            res.message ||
            'There was an error inviting you to the team, try again',
          status: 'error',
        });
      }
    });
  };

  return (
    <div className="auth login-layout pending">
      <img src={OkraLogo} alt="Okra Logo" className="auth__logo" />
      <Card className="existing-team">
        <h3>Your team already exists!</h3>
        <p className="info-text">
          We noticed an organization with the domain name {domain} already has
          an account. Please request an invite to join your team.
        </p>

        <div className="existing-team--box">
          <h4>Join your team</h4>
          <p>
            {`${company?.users[0].firstname} ${company?.users[0].lastname}`} and
            others have already joined Okra.
          </p>

          <div>
            <ImageStack images={Images(company?.users)} number={3} />
            <Button
              rightIcon={<ExternalLink />}
              loading={loading}
              onClick={handleSubmit}
            >
              Request an invite
            </Button>
          </div>
        </div>
        <p className="extratext">
          Don’t want to join?{' '}
          <Link
            to={{
              pathname: `/register-success`,
              state: { email: user?.email },
            }}
          >
            Create a new organization
          </Link>
        </p>
      </Card>
    </div>
  );
};

export default ExistingTeam;

import OkraLogo from 'assets/Images/OkraFullLogo.png';
import { SupportModal } from 'components';
import { api, routes } from 'config';
import {
  Button,
  useToaster,
  TextField,
  Checkbox,
  Modal,
  Icon,
} from 'okraui-ts';
import { useEffect, useState } from 'react';
import { readData, generateObjectId } from 'helpers';
import { useCompany, useAuth } from 'store';
import { useHistory } from 'react-router-dom';
import validator from 'validator';

import { QuestionMarkInCircle } from 'assets';
import { SignoutModal } from './Onboarding';

export const SLA = (props) => {
  const { location } = props;
  const history = useHistory();

  const store = readData('auth');

  const { updateCompanyObj } = useCompany();
  const { updateUserAfterSigningSLA } = useAuth();

  const currentUserEmail = location?.state?.email ?? store?.user?.email;

  const [accepted, setAccepted] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showSignoutModal, setShowSignoutModal] = useState(false);
  const [showSLAForm, setShowSLAForm] = useState(false);

  const [loadingState, setLoadingStates] = useState({
    sendingEmail: false,
    signingSLA: false,
    loadingSLA: true,
  });

  const [email, setEmail] = useState(currentUserEmail ?? '');
  const [content, setContent] = useState(null);
  const { open } = useToaster();

  const sendSLAEmail = async () => {
    setLoadingStates({
      ...loadingState,
      sendingEmail: true,
    });
    await api({
      url: 'company/send-sla',
      body: {
        email,
      },
    })
      .then(() => {
        open({
          active: true,
          message: 'Copy sent',
        });
      })
      .finally(() => {
        setLoadingStates({
          ...loadingState,
          sendingEmail: false,
        });
        setEmail('');
      });
  };

  const signSLA = async () => {
    setLoadingStates({
      ...loadingState,
      signingSLA: true,
    });

    await api({
      url: 'company/accept-sla',
      body: {
        email: currentUserEmail,
      },
    }).then((response) => {
      const { status, data } = response;
      setLoadingStates({
        ...loadingState,
        signingSLA: false,
      });
      if (status === 'success') {
        updateCompanyObj(data?.company);
        updateUserAfterSigningSLA(store);
        history.push(routes.dashboard.home);
      }
    });
  };

  useEffect(() => {
    const getSLAContent = async () => {
      const slaContent = await api({
        url: 'company/fetch-sla',
        method: 'GET',
      });

      setLoadingStates({
        ...loadingState,
        loadingSLA: false,
      });

      if (slaContent.data?.html_content) {
        setContent(slaContent.data.html_content);
      }
    };

    getSLAContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableSendButton = !validator.isEmail(email);

  const SLAForm = () => {
    return (
      <Modal active={showSLAForm} type="bare" modalClass="sla-form-mobile">
        <>
          <div className="sla-form-mobile__header">
            <h4 className="semibold">Help </h4>
            <Icon name="close" onClick={() => setShowSLAForm(false)} />
          </div>

          <div className="sla-content__form">
            <p className="semibold body2">Send me a copy </p>
            <p className="body3">
              Share a copy of the service level agreement with yourself or a
              member of your team.
            </p>
            <div className="divider" />

            <TextField
              label="Email address"
              value={email}
              onChangeText={({ value }) => setEmail(value)}
              onChange={() => {}}
              disabled={loadingState.sendingEmail}
            />

            <Button
              onClick={sendSLAEmail}
              className="send-button"
              variant="secondary-neutral"
              loading={loadingState.sendingEmail}
              disabled={disableSendButton}
            >
              Send copy
            </Button>

            <p className="semibold body2">Got questions?</p>
            <p className="body3">
              Reach out to our support team if you need help or clarification.
            </p>

            <Button
              onClick={() => {
                setShowSLAForm(false);
                setShowSupportModal(true);
              }}
              className="support-button"
              variant="primary-text"
            >
              Contact support
            </Button>
          </div>
        </>
      </Modal>
    );
  };

  return (
    <>
      <SignoutModal
        active={showSignoutModal}
        onClose={() => setShowSignoutModal(false)}
        nextRouteLink="/login"
      />

      <SLAForm />

      <SupportModal
        id={generateObjectId()}
        className="sla-support-modal"
        active={showSupportModal}
        close={() => setShowSupportModal(false)}
        modalOptions={{
          title: 'Contact support',
          type: 'standard',
          status: 'success',
          message: '',
          closeTxt: 'Cancel',
          confirmTxt: 'Contact support',
        }}
        placeholder="Write your message"
        type="standard"
      />

      {loadingState?.loadingSLA ? (
        <div className="sla-page-loader">
          <div className="okra-loader" />
          <p>Loading SLA document</p>
        </div>
      ) : (
        <div className="onboarding-container sla">
          <div className="onboarding-container__header">
            <img src={OkraLogo} alt="Okra Logo" />

            <div
              className="mobile-stepper"
              onClick={() => setShowSLAForm(true)}
            >
              <QuestionMarkInCircle />
            </div>
            <Button
              variant="secondary-text"
              onClick={() => setShowSignoutModal(true)}
            >
              Sign out
            </Button>
          </div>

          <div className="sla-content">
            <div className="sla-content__header">
              <h3 className="semibold">Service level agreement</h3>
            </div>

            <div className="sla-content__body">
              <div className="sla-content__body-left">
                <div
                  className="body-content"
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                />
              </div>

              <div className="sla-content__body-right">
                <div className="sla-content__form">
                  <p className="semibold body2">Send me a copy </p>
                  <p className="body3">
                    Share a copy of the service level agreement with yourself or
                    a member of your team.
                  </p>
                  <div className="divider" />

                  <TextField
                    label="Email address"
                    value={email}
                    onChangeText={({ value }) => setEmail(value)}
                    onChange={() => {}}
                    disabled={loadingState.sendingEmail}
                  />

                  <Button
                    onClick={sendSLAEmail}
                    className="send-button"
                    variant="secondary-neutral"
                    disabled={disableSendButton}
                    loading={loadingState.sendingEmail}
                  >
                    Send copy
                  </Button>

                  <p className="semibold body2">Got questions?</p>
                  <p className="body3">
                    Reach out to our support team if you need help or
                    clarification.
                  </p>

                  <Button
                    onClick={() => setShowSupportModal(true)}
                    className="support-button"
                    variant="primary-text"
                  >
                    Contact support
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="sla-footer">
            <Checkbox
              value={accepted}
              onChange={() => setAccepted(!accepted)}
              label="I Agree with the terms of this Agreement"
            />

            <Button
              onClick={signSLA}
              disabled={!accepted}
              loading={loadingState.signingSLA}
            >
              Launch dashboard
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

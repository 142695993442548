import * as React from 'react';

export const ApiStatusIcon = (props) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#clip0_17432_17111)"
        stroke="#627490"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M13 1H3a2 2 0 00-2 2v2a2 2 0 002 2h10a2 2 0 002-2V3a2 2 0 00-2-2zM13 9H3a2 2 0 00-2 2v2a2 2 0 002 2h10a2 2 0 002-2v-2a2 2 0 00-2-2zM3.28 3.25v1.5M5.46 3.25v1.5"
          strokeWidth={1.5}
        />
        <path
          d="M5.46 11.25v1.5M7.65 3.25v1.5M9.83 3.25v1.5M3.28 11.25v1.5"
          strokeWidth={1.2}
        />
      </g>
      <defs>
        <clipPath id="clip0_17432_17111">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

import {
  NavLink,
  Switch,
  Route,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import { Account, Options } from './InnerViews';
import { BrandingScreen, SuccessScreen } from './screens';
import Branding from './Branding';

const Properties = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  return (
    <div className="builder builder-multi builder-products">
      <div className="builder-multi-panel">
        <div className="builder-multi-panel__left">
          <div className="product-sections">
            <div className="section">
              <div className="section-routes">
                <NavLink
                  to="/app-builder/edit/widget-properties/branding"
                  isActive={() =>
                    [
                      '/app-builder/edit/widget-properties',
                      '/app-builder/edit/widget-properties/branding',
                    ].includes(pathname)
                  }
                >
                  Branding
                </NavLink>
                <NavLink to="/app-builder/edit/widget-properties/account-connected">
                  Account connected
                </NavLink>
                <NavLink to="/app-builder/edit/widget-properties/settings">
                  Settings
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="builder-multi-panel__right">
          <Switch>
            <Route
              exact
              path={[path, `${path}/branding`]}
              component={Branding}
            />
            <Route
              exact
              path={[path, `${path}/account-connected`]}
              component={Account}
            />
            <Route exact path={`${path}/settings`} component={Options} />
          </Switch>
        </div>
      </div>
      <div className="builder-wigdet-container">
        <Switch>
          <Route
            exact
            path={[path, `${path}/branding`]}
            component={BrandingScreen}
          />
          <Route
            exact
            path={[path, `${path}/account-connected`]}
            component={SuccessScreen}
          />
        </Switch>
      </div>
    </div>
  );
};

export default Properties;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import {
  Modal,
  CircularClose,
  Dashboard,
  Projects,
  Payment,
  LockOpen,
  Identity,
  Balance,
  ArrowsSort,
  ArrowsMinimize,
  EndUsers,
  Chat,
  Business,
  User,
  Apps,
  Wallet,
  Payout,
  File,
  Audit,
} from 'okraui-ts';
import { Search } from 'assets';
import {
  readData,
  clearData,
  createData,
  removeDuplicates,
  filterPages,
  searchPage,
} from 'helpers';
import { useAuth } from 'store';
import { api } from 'config';
import DisplayHistory from './DisplayHistory';
import DisplayResults from './DisplayResults';

const ICONS = {
  others: <Dashboard />,
  pages: <File />,
  records: <Projects />,
  recordsets: <Projects />,
  accounts: <Payment />,
  auths: <LockOpen />, // lock
  identities: <Identity />,
  balances: <Balance />,
  transactions: <ArrowsSort />,
  transactionsets: <ArrowsSort />,
  incomes: <ArrowsMinimize />,
  customers: <EndUsers />,
  beneficiaries: <Chat />,
  company: <Business />,
  'customer.name': <User />,
  link: <Apps />,
  nuban: <Wallet />,
  type: <Payout />, // account type
  'account.name': <User />,
  bvn: <Identity />,
  users: <User />,
  email: <User />,
  firstname: <User />,
  middlename: <User />,
  lastname: <User />,
  phone: <User />,
  companies: <Business />,
  nin: <Identity />,
  _id: <Audit />,
};
const GlobalSearch = ({ active, close }) => {
  const history = useHistory();
  const { user } = useAuth();
  const keeps = readData('search');
  const [searchWord, setSearchWord] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState({});
  const [isEmpty, setEmpty] = useState(true);
  const [searchMore, setSearchMore] = useState(false);
  const [searchKeeps, setSearchKeeps] = useState(keeps || []);

  const handlePageSearch = (e) => {
    e.preventDefault();
    setLoading(true);
    setSearchMore(false);
    const search = e.target.value.replace(/[^0-9a-z-A-Z@. ]/g, '');
    setEmpty(search.trim() === '');
    setSearchWord(search);
    if (search.trim() !== '') {
      // page search
      const roles = user?.roles || [];
      const pages = filterPages(roles);
      const pagesList = pages.filter((value) => {
        const regex = new RegExp(search, 'gi');
        return regex.test(value.title);
      });
      const pagesObj = {
        pages: { docs: pagesList, total: pagesList.length || 0 },
      };
      setEmpty(pagesList.length === 0);
      setSearchResult(pagesObj);
      setLoading(false);
    } else {
      setEmpty(true);
      setSearchResult({});
      setLoading(false);
    }
  };

  const moveTo = (name, icon, location, id) => {
    const save = searchKeeps;
    let page = '';
    if (icon === 'pages') {
      page = `${location}`;
      save.push({ name, location, icon });
    } else {
      page = `${searchPage[location] || searchPage[name] || searchPage[icon]}/${
        id || ''
      }`;
      save.push({ name, location, icon, id });
    }

    const strip = removeDuplicates(save, (item) => item.name);
    createData('search', strip);
    setSearchKeeps(strip);
    setSearchWord('');
    setSearchResult([]);
    setEmpty(true);
    history.push(page);
    close();
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const search = searchWord;
    const List = searchResult;
    let result = {};
    if (search.trim() !== '') {
      setEmpty(search.trim() === '');
      setLoading(true);
      setSearchMore(true);
      const controller = new AbortController();
      api({
        url: 'search/general',
        signal: controller.signal,
        body: { searchTerm: search },
      })
        .then((res) => {
          result = { ...List, ...res.data };
          const totalCount =
            res.data &&
            Object.values(result).reduce((t, { total }) => t + total, 0);
          setSearchResult(result);
          setLoading(false);
          setEmpty(totalCount === 0);
          if (totalCount === 1) {
            const page = result?.pages?.docs?.[0];
            page && moveTo(page.title, 'pages', page.location);
          }
        })
        .catch(() => {
          setSearchResult({});
          setLoading(false);
          setEmpty(true);
        });
    }
  };

  const clearSearch = () => {
    setSearchWord('');
    setSearchResult({});
    setEmpty(true);
  };
  const clearKeep = () => {
    setSearchKeeps([]);
    clearData('search');
  };
  const deleteKeep = (pos) => {
    const save = searchKeeps;
    save.splice(pos, 1);
    createData('search', save);
    setSearchKeeps([]);
  };

  const empty = !searchWord && isEmpty;

  return (
    <Modal active={active} type="bare" modalClass="global-search">
      <div className="top-search">
        <div className="search-box">
          <i className="fa-search">
            <Search />
          </i>
          <form onSubmit={handleSearch}>
            <input
              placeholder="Search"
              value={searchWord}
              autoComplete="off"
              onChange={handlePageSearch}
            />
            {searchWord && !loading && <button type="submit">Search</button>}
          </form>
          {searchWord && !loading && (
            <i className="fa-close" onClick={clearSearch}>
              <CircularClose />
            </i>
          )}
          {loading && (
            <span data-testid="search-loader" className="okra-loader" />
          )}
        </div>
        <button className="close_search" onClick={close}>
          &times;
        </button>
      </div>

      {!empty ? (
        <div className="search_result">
          {!searchMore && isEmpty ? (
            <div>
              <p className="search-text dif">
                Press enter for more search results on "{searchWord}"
              </p>
            </div>
          ) : isEmpty ? (
            <div className="search_empty">
              <i className="fa-search">
                <Search />
              </i>
              <p className="search-text">
                We couldn't find anything relating to "{searchWord}"
              </p>
              <p className="search-info">
                Search again with different keywords and be mindful of
                typos/errors that may affect your search results.
              </p>
            </div>
          ) : (
            <DisplayResults
              searchResult={searchResult}
              moveTo={moveTo}
              reactHtmlParser={ReactHtmlParser}
              icon={ICONS}
            />
          )}
        </div>
      ) : (
        <div className="search_result">
          {searchKeeps.length > 0 && (
            <>
              <div className="row-flex">
                <p>Recent Searches</p>
                <button className="okra-btn slim clear" onClick={clearKeep}>
                  Clear all
                </button>
              </div>
              <div className="line" />
            </>
          )}
          {searchKeeps.length > 0 ? (
            <DisplayHistory
              search_keeps={searchKeeps}
              moveTo={moveTo}
              deleteKeep={deleteKeep}
              reactHtmlParser={ReactHtmlParser}
              icons={ICONS}
            />
          ) : (
            <div className="search_empty">
              <i className="fa-search">
                <Search />
              </i>
              <p className="search-text">Explore Your Dashboard</p>
              <p className="search-info">
                Your customers, pages, & apps are just a click away.
              </p>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

GlobalSearch.propTypes = {
  active: PropTypes.bool,
  close: PropTypes.func,
};

export default GlobalSearch;

import { Radio, Button, Checkbox } from 'okraui-ts';
import cx from 'classnames';
import { useBuilder } from 'store';
import { GTBLogo } from 'assets';
import BaseScreen from './BaseScreen';

const AccountSelectionScreen = () => {
  const { builder } = useBuilder();
  const { selection_type } = builder;

  const isMultipleAccount = selection_type === 'multiple';
  const isSingleAccount = selection_type === 'single';

  return (
    <BaseScreen className="balance-screen" title="Select an Account" grey>
      <div className="balance-container account-selection">
        <p className="semibold subtitle">
          {isMultipleAccount
            ? 'Select one or more accounts'
            : 'Select an account'}
        </p>

        <div className="balance-container__header">
          <p className="caption">Account name</p>
          <p className="medium caption">Grace Ademola-Adenle</p>
        </div>

        <div className="balance-container__bank">
          <div className="bank-icon">
            <GTBLogo />
          </div>

          <p className="semibold body3">Guaranty Trust Bank</p>
        </div>

        <div className="bank-container-list">
          <div
            className={cx('bank-container-list__item', {
              checkbox: isMultipleAccount,
              radio: isSingleAccount,
              none: selection_type === 'none',
            })}
          >
            <div className="balance-account">
              <p className="caption medium">Savings</p>
              <p className="caption">*** *** 1234</p>
            </div>

            {isSingleAccount && (
              <div className="balance-check">
                <Radio checked />
              </div>
            )}
            {isMultipleAccount && (
              <div className="balance-check">
                <Checkbox checked />
              </div>
            )}
          </div>

          <div
            className={cx('bank-container-list__item', {
              checkbox: isMultipleAccount,
              radio: isSingleAccount,
              none: selection_type === 'none',
            })}
          >
            <div className="balance-account">
              <p className="caption medium">Current</p>
              <p className="caption">*** *** 5678</p>
            </div>

            {isSingleAccount && (
              <div className="balance-check">
                <Radio />
              </div>
            )}

            {isMultipleAccount && (
              <div className="balance-check">
                <Checkbox checked />
              </div>
            )}
          </div>

          <div className="bank-container-list__item">
            <div className="balance-account">
              <p className="caption medium">Other</p>
              <p className="caption">*** *** 4321</p>
            </div>

            {isSingleAccount && (
              <div className="balance-check">
                <Radio />
              </div>
            )}

            {isMultipleAccount && (
              <div className="balance-check">
                <Checkbox />
              </div>
            )}
          </div>
        </div>
        <div className="balance-container__footer">
          <Button variant="neutral">Continue</Button>
        </div>
      </div>
    </BaseScreen>
  );
};

export default AccountSelectionScreen;

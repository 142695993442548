import { Toggle, StatusChip, Button } from 'okraui-ts';
import { useBuilder } from 'store';
import clsx from 'classnames';

const Auth = () => {
  const { builder, setBuilder } = useBuilder();
  const { auth } = builder;
  const instantPaymentEnabled = builder.type === 'one-time';

  return (
    <>
      <div className="product-section-info">
        <p className="semibold body2">
          Authentication <StatusChip>Enabled</StatusChip>
        </p>
        <p className="body3 subtitle">
          Verify customers' bank login credentials.
        </p>

        <a
          href="https://docs.okra.ng/account-data/auth"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="primary-link">Read the docs </Button>
        </a>
      </div>

      <div className="product-section">
        <div
          className={clsx('action-section', {
            disabled: instantPaymentEnabled,
          })}
        >
          <div className="action-section__content">
            <p className="medium body3">Enable manual auth </p>
            <p className="body3 mt-8">
              Allow customers to authenticate with BVN and account number.
            </p>
          </div>
          <Toggle
            checked={auth?.manual}
            disabled={instantPaymentEnabled}
            onChange={({ target }) => {
              const newValue = { ...auth, manual: target.checked };
              setBuilder('auth', newValue);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Auth;

// Layouts
import {
  AuthSection,
  DashboardSection,
  OnboardingSection,
  EmptySection,
} from 'security/section';
// Sections
import { OnboardingLayout, DashboardLayout, BuilderLayout } from 'layouts';
// Pages
import { BuilderProvider } from 'store';
import { routes } from 'config';

import {
  Login,
  Register,
  Records,
  SingleRecord,
  Auths,
  Identities,
  SingleIdentity,
  Balances,
  SingleBalance,
  Transactions,
  SingleTrans,
  Income,
  SingleIncome,
  ProductsOverview,
  // Links,
  Refunds,
  // SingleLink,
  PaymentRecords,
  SinglePaymentRecord,
  SingleBulkRecord,
  BulkPaymentDetails,
  InitiateDebit,
  Payouts,
  SinglePayout,
  Onboarding,
  RegisterSuccess,
  Home,
  TwoFactor,
  ResetPassword,
  ResetPasswordSuccess,
  NewPassword,
  InvitedUser,
  // Libraries,
  AuditLogs,
  SingleAuditLogs,
  Profile,
  Notification,
  Projects,
  SingleProject,
  APIKeys,
  Wallet,
  SingleAPICall,
  SingleInvoice,
  Customers,
  SingleCustomers,
  Apps,
  Team,
  TeamProcess,
  Billing,
  // Pricing,
  NewUserView,
  Builder,
  Callbacks,
  Clients,
  Accounts,
  SingleApp,
  NotFound,
  Logout,
  Webhook,
  Events,
  PendingInvite,
  ExistingTeam,
  VerifyInvite,
  SingleRefund,
  SLA,
} from './pages';

// {
//     path: '', // required
//     exact: '',
//     component: '', // required Component
//     layout: '', // ['auth', 'dash', 'builder'] required Component
//     layoutType: '', // string optional
//     allowed: '', // all / ['page_name'] optional (required for dashsection)
//     section: '', // ['auth', 'onboarding', 'verified'] //required Component
//     hasFeature: '', // null / something
//     prodOnly: '', // null / true
//     comingSoon: '', // null / Component
//     state: Component || null // provider
// }

const pages = [
  {
    path: '/',
    exact: true,
    component: Login,
    layout: OnboardingLayout,
    section: AuthSection,
    title: 'Sign into your account',
    description: 'Verify your credentials .',
  },
  {
    path: routes.auth.login,
    component: Login,
    layout: OnboardingLayout,
    section: AuthSection,
    title: 'Sign into your account',
    description: 'Verify your credentials .',
  },
  {
    path: routes.auth.verify,
    component: Login,
    layout: OnboardingLayout,
    section: AuthSection,
    title: 'Verify your email',
    description: 'Verify your email to gain access to your account.',
  },
  {
    path: routes.auth.twoFactorAuthentication,
    component: TwoFactor,
    layout: OnboardingLayout,
    section: AuthSection,
    title: 'Two Factor Authentication',
    description: 'Verify your credentials with this two-step verification.',
  },
  {
    path: routes.auth.pendingInvite,
    component: PendingInvite,
    // component: ExistingTeam,
    layout: OnboardingLayout,
    // layoutType: 'plain',
    section: EmptySection,
    title: 'Pending Invite',
    description: 'Your invite is pending approval.',
  },
  {
    path: routes.auth.verifyToken,
    component: VerifyInvite,
    // component: ExistingTeam,
    layout: OnboardingLayout,
    // layoutType: 'plain',
    section: EmptySection,
    title: 'Verify Invite',
    description: `Verify your team member's invite.`,
  },

  {
    path: routes.auth.existingTeam,
    component: ExistingTeam,
    layout: OnboardingLayout,
    // layoutType: 'plain',
    section: AuthSection,
    title: 'Existing Team',
    description: 'Your invite has been sent to your admin.',
  },
  {
    path: routes.auth.resetPassword,
    exact: true,
    component: ResetPassword,
    layout: OnboardingLayout,
    section: AuthSection,
    title: 'Reset Password',
    description: 'Reset your Okra password.',
  },
  {
    path: routes.auth.resetPasswordSuccess,
    component: ResetPasswordSuccess,
    layout: OnboardingLayout,
    section: AuthSection,
    title: 'Reset Password Success',
    description: 'Successfully changed your password, now you can sign in.',
  },

  {
    path: routes.auth.reset,
    component: NewPassword,
    layout: OnboardingLayout,
    section: AuthSection,
    title: 'Set New Password',
    description: 'Set a new password to get access into your Okra account.',
  },
  {
    path: routes.auth.invite,
    component: InvitedUser,
    layout: OnboardingLayout,
    section: AuthSection,
    title: 'Invite User',
    description:
      "Verify your details and set a new password to get access into your Team's account.",
  },
  {
    path: routes.auth.register,
    exact: true,
    component: Register,
    layout: OnboardingLayout,
    layoutType: 'plain',
    section: AuthSection,
    title: 'Sign up to Okra',
    description:
      'Create an account to manage and create your applications and to view and manage your user data.',
  },
  {
    path: routes.auth.registerSuccess,
    component: RegisterSuccess,
    layout: OnboardingLayout,
    layoutType: 'plain',
    section: AuthSection,
    title: 'Registeration Successful',
    description:
      'Congratulations, you have successfully created an Okra account.',
  },
  {
    path: routes.dashboard.home,
    component: Home,
    allowed: 'home',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Home',
  },

  {
    path: routes.auth.logout,
    component: Logout,
    allowed: 'all',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Logout',
  },
  {
    path: routes.business.clients,
    component: Clients,
    allowed: 'clients',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Clients',
  },
  {
    path: routes.settings.business,
    exact: true,
    component: Projects,
    allowed: 'projects',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Projects',
  },
  {
    path: routes.business.apps,
    exact: true,
    component: Apps,
    allowed: 'links',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Apps',
    state: BuilderProvider,
  },
  {
    path: routes.settings.singleBusiness,
    component: SingleProject,
    allowed: 'projects',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Project',
  },
  {
    path: [
      routes.business.appsConfiguration,
      routes.business.appsIntegration,
      routes.business.appsRecord,
    ],
    component: SingleApp,
    allowed: 'links',
    layout: DashboardLayout,
    section: DashboardSection,
  },

  {
    path: routes.payments.base,
    exact: true,
    component: PaymentRecords,
    allowed: 'payments',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Overview | Payments',
    hasFeature: 'payment',
    comingSoon: NewUserView,
  },
  {
    path: routes.payments.overview,
    exact: true,
    component: PaymentRecords,
    allowed: 'payments',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Overview | Payments',
    hasFeature: 'payment',
    comingSoon: NewUserView,
  },
  // {
  //   path: routes.payments.links,
  //   exact: true,
  //   component: Links,
  //   allowed: 'payments',
  //   layout: DashboardLayout,
  //   section: DashboardSection,
  //   title: 'Links | Payments',
  //   hasFeature: 'payment',
  //   comingSoon: NewUserView,
  // },
  // {
  //   path: routes.payments.singleLink,
  //   component: SingleLink,
  //   allowed: 'payments',
  //   layout: DashboardLayout,
  //   section: DashboardSection,
  //   title: 'Links | Payments',
  //   hasFeature: 'payment',
  //   comingSoon: NewUserView,
  // },

  {
    path: [
      routes.payments.records,
      routes.payments.singleRecords,
      routes.payments.bulkRecords,
    ],
    exact: true,
    component: PaymentRecords,
    allowed: 'payments',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Records | Payments',
    hasFeature: 'payment',
    comingSoon: NewUserView,
  },
  {
    path: routes.payments.recordsInitiateDebit,
    exact: true,
    component: InitiateDebit,
    allowed: 'payments',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Initiate Debit | Payments',
    hasFeature: 'payment',
    comingSoon: NewUserView,
  },
  {
    path: routes.payments.singleRecord,
    exact: true,
    component: SinglePaymentRecord,
    allowed: 'payments',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Payments | Payments',
    hasFeature: 'payment',
    comingSoon: NewUserView,
  },
  {
    path: routes.payments.singleRefund,
    exact: true,
    component: SingleRefund,
    allowed: 'payments',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Payments | Refund',
    hasFeature: 'payment',
    comingSoon: NewUserView,
  },
  {
    path: routes.payments.refunds,
    exact: true,
    component: Refunds,
    allowed: 'payments',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Payments | Refunds',
    hasFeature: 'payment',
    comingSoon: NewUserView,
  },
  {
    path: routes.payments.singleBulkRecord,
    exact: true,
    component: SingleBulkRecord,
    allowed: 'payments',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Bulk Payments | Payments',
    hasFeature: 'payment',
    comingSoon: NewUserView,
  },

  {
    path: routes.payments.bulkPaymentDetails,
    exact: true,
    component: BulkPaymentDetails,
    allowed: 'payments',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Bulk Payment Details | Payments',
    hasFeature: 'payment',
    comingSoon: NewUserView,
  },

  {
    path: routes.payments.payouts,
    exact: true,
    component: Payouts,
    allowed: 'payments',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Payouts | Payments',
    hasFeature: 'payment',
    comingSoon: NewUserView,
  },
  {
    path: routes.payments.singlePayout,
    exact: true,
    component: SinglePayout,
    allowed: 'payments',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Payouts | Payments',
    hasFeature: 'payment',
    comingSoon: NewUserView,
  },
  {
    path: routes.data.records,
    exact: true,
    component: Records,
    allowed: 'records',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Records',
  },
  {
    path: routes.data.singleRecord,
    component: SingleRecord,
    allowed: 'records',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Records',
  },
  {
    path: routes.data.authenication,
    exact: true,
    component: Auths,
    allowed: 'auths',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Auths',
  },
  {
    path: routes.data.identity,
    exact: true,
    component: Identities,
    allowed: 'identities',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Identities',
  },

  {
    path: routes.onboarding.base,
    component: Onboarding,
    layout: OnboardingLayout,
    layoutType: 'plain',
    section: OnboardingSection,
    title: 'Onboarding',
  },

  {
    path: routes.onboarding.sla,
    component: SLA,
    layout: OnboardingLayout,
    layoutType: 'plain',
    title: 'Onboarding',
  },
  {
    path: routes.data.overview,
    exact: true,
    component: ProductsOverview,
    allowed: 'products',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Overview',
  },
  {
    path: routes.data.singleIdentity,
    component: SingleIdentity,
    allowed: 'identities',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Identities',
  },
  {
    path: routes.data.balance,
    exact: true,
    component: Balances,
    allowed: 'balances',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Balances',
  },
  {
    path: routes.data.singleBalance,
    component: SingleBalance,
    allowed: 'balances',
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: routes.data.income,
    exact: true,
    component: Income,
    allowed: 'incomes',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Incomes',
  },
  {
    path: routes.data.singleIncome,
    component: SingleIncome,
    allowed: 'incomes',
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: routes.data.transactions,
    exact: true,
    component: Transactions,
    allowed: 'transactions',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Transactions',
  },
  {
    path: routes.data.singleTransaction,
    component: SingleTrans,
    allowed: 'transactions',
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: routes.settings.auditLogs,
    exact: true,
    component: AuditLogs,
    allowed: 'settings',
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: routes.settings.singleAuditLog,
    component: SingleAuditLogs,
    allowed: 'settings',
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: [routes.settings.profile, routes.settings.businessProfile],
    exact: true,
    component: Profile,
    allowed: 'settings',
    layout: DashboardLayout,
    section: DashboardSection,
  },

  {
    path: routes.settings.notifications,
    exact: true,
    component: Notification,
    allowed: 'settings',
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: '/settings/accounts',
    exact: true,
    component: Accounts,
    allowed: 'bank_accounts',
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: [routes.settings.team, routes.settings.teamInvited],
    exact: true,
    component: Team,
    allowed: 'team',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Team',
  },
  {
    path: routes.settings.teamProcess,
    component: TeamProcess,
    exact: true,
    allowed: 'team',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Add Team Member',
  },
  {
    path: routes.settings.singleTeamProcess,
    component: TeamProcess,
    allowed: 'team',
    layout: DashboardLayout,
    section: DashboardSection,
    title: 'Edit Team Member',
  },
  {
    path: routes.business.base,
    exact: true,
    component: Wallet,
    allowed: 'wallet',
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: routes.business.wallet,
    exact: true,
    component: Wallet,
    allowed: 'wallet',
    prodOnly: true,
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: routes.business.businessCallbacks,
    exact: true,
    component: Callbacks,
    // allowed: 'callbacks',
    // layout: DashboardLayout,
    // section: DashboardSection,
  },
  {
    path: routes.business.singleWalletReceipt,
    component: SingleAPICall,
    allowed: 'plan',
    prodOnly: true,
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: routes.business.singleWalletInvoice,
    component: SingleInvoice,
    allowed: 'plan',
    prodOnly: true,
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: routes.business.customers,
    exact: true,
    component: Customers,
    allowed: 'customers',
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: routes.business.singleCustomer,
    exact: true,
    component: SingleCustomers,
    allowed: 'customers',
    layout: DashboardLayout,
    layoutType: 'full-dashboard',
    section: DashboardSection,
  },
  {
    path: [
      routes.settings.billing,
      routes.settings.billingPlan,
      routes.settings.billingHistory,
    ],
    exact: true,
    component: Billing,
    allowed: 'plan',
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: routes.appBuilder.base,
    component: Builder,
    allowed: 'links',
    layout: BuilderLayout,
    section: DashboardSection,
    state: BuilderProvider,
  },
  {
    path: routes.developers.apiKeys,
    component: APIKeys,
    allowed: 'keys',
    exact: true,
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: routes.developers.webhook,
    component: Webhook,
    allowed: 'webhooks',
    exact: true,
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: routes.developers.events,
    component: Events,
    allowed: 'events',
    exact: true,
    prodOnly: true,
    layout: DashboardLayout,
    section: DashboardSection,
  },
  {
    path: '/**',
    exact: true,
    component: NotFound,
    layoutType: 'plain',
    allowed: 'all',
    title: 'Not Found',
    description: 'Can not found your page.',
  },
];

export default pages;

export default function Compose(props) {
  const { components = [], children, ...rest } = props;
  return (
    <>
      {components.reduceRight((acc, Provider) => {
        return <Provider {...rest}>{acc}</Provider>;
      }, children)}
    </>
  );
}

import { Button, Toggle, Radio } from 'okraui-ts';
import { useBuilder } from 'store';
import { addRemove } from 'helpers';
import clsx from 'classnames';

const TRANSACTION_HISTORY_OPTIONS = [
  {
    label: '24 months',
    value: 24,
  },
  {
    label: '12 months',
    value: 12,
  },
  {
    label: '6 months',
    value: 6,
  },
  {
    label: '3 months',
    value: 3,
  },
];

const Transactions = () => {
  const { builder, setBuilder } = useBuilder();
  const { billable_products, limit } = builder;
  const hasTransactions = billable_products.includes('transactions');
  const instantPaymentEnabled = builder.type === 'one-time';

  return (
    <>
      <div className="product-section-info">
        <p className="semibold body2">Transactions</p>
        <p className="subtitle body3">
          Access up to 24 months of transaction history.
        </p>

        <a
          href="https://docs.okra.ng/reference/api/Transactions"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="primary-link">Read the docs</Button>
        </a>
      </div>

      <div className="product-section">
        <div
          className={clsx('action-section', {
            disabled: instantPaymentEnabled,
          })}
        >
          <p className="body3 medium">Enable transactions</p>
          <Toggle
            checked={hasTransactions}
            disabled={instantPaymentEnabled}
            onChange={() => {
              const newPrds = addRemove(billable_products, 'transactions');
              setBuilder('billable_products', newPrds);
            }}
          />
        </div>
      </div>

      <div className="product-section">
        <div className="action-section transaction-history">
          <p className="body3 medium">Transaction history</p>
        </div>

        <div className="transaction-history__options">
          {TRANSACTION_HISTORY_OPTIONS.map((option) => {
            return (
              <Radio
                checked={limit === option.value}
                disabled={!hasTransactions}
                key={option.label}
                onClick={() => {
                  setBuilder('limit', option.value);
                }}
                label={option.label}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Transactions;

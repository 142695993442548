// TODO pleasefix the correct pages
export const pages = [
  { title: 'Home', location: '/home', permission: 'home' },
  { title: 'Getting Started', location: '/getting_started' },
  {
    title: 'Integrations',
    location: '/getting_started/integrations',
    permission: 'integrations',
  },
  {
    title: 'Libraries',
    location: '/getting_started/integrations',
    permission: 'integrations',
  },
  // { title: 'Links', location: '/links', permission: 'links' },
  // { title: 'Apps', location: '/links', permission: 'links' },
  {
    title: 'Finance Stats',
    location: '/finance-stats',
    permission: 'finance_stats',
  },
  { title: 'Wallet', location: '/wallet', permission: 'wallet' },
  { title: 'Payment', location: '/payment', permission: 'payment' },
  { title: 'Reconcilation', location: '/data/recon', permission: 'recon' },
  { title: 'Customers', location: '/customers', permission: 'customers' },
  { title: 'Records', location: '/data/records', permission: 'records' },
  { title: 'Auth', location: '/data/auth', permission: 'auths' },
  { title: 'Identity', location: '/data/identity', permission: 'identity' },
  { title: 'Accounts', location: '/data/accounts', permission: 'accounts' },
  { title: 'Balance', location: '/data/balance', permission: 'balances' },
  {
    title: 'Transactions',
    location: '/data/transactions',
    permission: 'transactions',
  },
  { title: 'Income', location: '/data/income', permission: 'incomes' },
  { title: 'Clients', location: '/clients', permission: 'clients' },
  { title: 'Companies', location: '/companies', permission: 'companies' },

  { title: 'Wallets', location: '/wallets', permission: 'wallets' },
  { title: 'Leads', location: '/leads', permission: 'leads' },
  { title: 'Bitly', location: '/bitly', permission: 'bitly' },
  { title: 'Banks', location: '/banks', permission: 'banks' },
  { title: 'Audit Logs', location: '/audit-logs', permission: 'logs' },
  { title: 'Profile', location: '/settings/profile' },
  { title: 'Plans', location: '/settings/billings/plans', permission: 'plans' },
  {
    title: 'Addons',
    location: '/settings/billings/addons',
    permission: 'plans',
  },
  {
    title: 'Billing',
    location: '/settings/billings/overview',
    permission: 'plans',
  },

  { title: 'Team', location: '/settings/team', permission: 'team' },
  {
    title: 'Bank Account',
    location: '/settings/bank/accounts',
    permission: 'bank_accounts',
  },
  { title: 'API Keys', location: '/settings/keys', permission: 'keys' },
  {
    title: 'Notifications',
    location: '/settings/notifications',
    permission: 'notifications',
  },
  {
    title: 'Incident Support',
    location: '/support/incident',
    permission: 'incident',
  },
  {
    title: 'Premium Support',
    location: '/support/premium',
    permission: 'premium',
  },
];
export const filterPages = (permissions) => {
  const pagesList = pages.filter((value) => {
    return permissions.includes(value.permission) || !value.permission;
  });
  return pagesList;
};

export const searchPage = {
  users: '/business/customers',
  companies: '/internal/companies',
  customers: '/business/customers',
  records: '/data/records',
  // 'accounts': '/accounts',
  auths: '/data/auth',
  identities: '/data/identity',
  balances: '/data/balance',
  transactions: '/data/transactions',
  incomes: '/data/income',
  transactionsets: '/data/transactions',
};

const permissions = [
  { section: 'dashboard', value: 'home', name: 'Home' },
  // {section: 'dashboard', value: 'links', name: 'Links' },
  { section: 'dashboard', value: 'projects', name: 'Business' },

  { section: 'business', value: 'wallet', name: 'Wallet' },
  { section: 'business', value: 'customers', name: 'Customers' },

  { section: 'products', value: 'records', name: 'Records' },
  { section: 'products', value: 'auths', name: 'Auths' },
  { section: 'products', value: 'identities', name: 'Identity' },
  { section: 'products', value: 'balances', name: 'Balance' },
  { section: 'products', value: 'transactions', name: 'Transactions' },
  { section: 'products', value: 'incomes', name: 'Incomes' },
  { section: 'products', value: 'recon', name: 'Reconcilation' },
  // { section: 'products', value: 'collections', name: 'Collections' },
  { section: 'payments', value: 'payments', name: 'Payments' },

  { section: 'settings', value: 'logs', name: 'Logs' },
  { section: 'settings', value: 'plan', name: 'Billing' },
  { section: 'settings', value: 'team', name: 'Team' },
  { section: 'settings', value: 'bank_accounts', name: 'Bank Accounts' },
  // { section: 'settings', value: 'keys', name: 'API Keys' },
  { section: 'settings', value: 'notifications', name: 'Notifications' },

  { section: 'developers', value: 'keys', name: 'Api Keys' },
  { section: 'developers', value: 'webhooks', name: 'Webhooks' },
  { section: 'developers', value: 'events', name: 'Events' },

  // { section: 'support', value: 'incident', name: 'Incident Support' },
  // { section: 'support', value: 'premium', name: 'Premium Support' },

  { section: 'internal', value: 'finance_stats', name: 'Finance Stats' },
  { section: 'internal', value: 'clients', name: 'Clients' },
  { section: 'internal', value: 'sales', name: 'Sales' },
  { section: 'internal', value: 'companies', name: 'Companies' },
  { section: 'internal', value: 'plans', name: 'Plans' },
  { section: 'internal', value: 'wallets', name: 'Wallets' },
  { section: 'internal', value: 'bitly', name: 'Bitly' },
  { section: 'internal', value: 'banks', name: 'Banks' },
];

export const getAllPermissions = (isOwner = false) => {
  if (isOwner) return permissions;
  const pagesList = permissions.filter((value) => {
    return value.section !== 'internal';
  });
  return pagesList;
};

const groups = [
  {
    group: 'dashboard',
    pages: [
      'home',
      // 'links',
      'projects',
    ],
  },
  { group: 'business', pages: ['wallet', 'customers'] },
  {
    group: 'products',
    pages: [
      'records',
      'auths',
      'identities',
      'accounts',
      'balances',
      'transactions',
      'incomes',
      'recon',
    ],
  },
  {
    group: 'payments',
    pages: ['payments'],
  },
  {
    group: 'settings',
    pages: ['logs', 'plan', 'team', 'bank_accounts', 'notifications'],
  },
  {
    group: 'developers',
    pages: ['keys', 'webhooks', 'events'],
  },
  {
    group: 'internal',
    pages: [
      'finance_stats',
      'clients',
      'sales',
      'companies',
      'plans',
      'wallets',
      'bitly',
      'banks',
    ],
  },
];
export const updateList = (role, roles) => {
  const permissions = new Set(roles);
  if (permissions.has(role)) {
    permissions.delete(role);
    const group = groups.find((value) => {
      return value.pages.includes(role);
    });
    const newArray = Array.from(permissions);
    if (!group.pages.some((r) => newArray.includes(r)))
      permissions.delete(group.group);
  } else {
    const group = groups.find((value) => {
      return value.pages.includes(role);
    });
    group && group.group && permissions.add(group.group);
    permissions.add(role);
  }
  return Array.from(permissions);
};
export const updateGroup = (section, roles) => {
  const permissions = new Set(roles);
  const group = groups.find((value) => {
    return value.group === section;
  });
  if (permissions.has(section)) {
    permissions.delete(section);
    const removeSectionArr = Array.from(permissions);
    roles = removeSectionArr.filter((el) => !group.pages.includes(el));
  } else {
    permissions.add(section);
    const addSectionArr = Array.from(permissions);
    roles = [...addSectionArr, ...group.pages];
  }
  return roles;
};
export const updateRoles = (roles) => {
  const permissions = new Set(roles);
  roles.forEach((role) => {
    const group = groups.find((value) => {
      return value.pages.includes(role);
    });
    group && group.group && permissions.add(group.group);
  });
  return Array.from(permissions);
};
export const AllValues = getAllPermissions().reduce(
  (arr, value) => [...arr, value.value],
  []
);

const RolesHandler = (role) => {
  const toRemove = {
    owner: [],
    admin: [],
    finance: [
      'products',
      // 'links',
      'intelligence',
      'regional-insights',
      'records',
      'auths',
      'identities',
      'identity',
      'accounts',
      'balances',
      'transactions',
      'incomes',
      'recon',
      'payments',
      'collections',
      'team',
      'plan',
      'bank_accounts',
      'finance_stats',
      'clients',
      'sales',
      'companies',
      'plans',
      'wallets',
      'services',
      'projects',
      'keys',
      'webhooks',
      'events',
      'developers',
    ],
    developer: [
      'intelligence',
      'regional-insights',
      'logs',
      'team',
      'bank_accounts',
      'business',
      'plan',
      'finance_stats',
      'clients',
      'sales',
      'companies',
      'plans',
      'wallets',
      'leads',
      'bitly',
      'projects',
    ],
    user: [
      'business',
      'internal',
      // 'links',
      'projects',
      'customers',
      'callbacks',
      'wallet',
      'intelligence',
      'regional-insights',
      'recon',
      'payments',
      'collections',
      'plan',
      'logs',
      'plan',
      'team',
      'bank_accounts',
      'keys',
      'finance_stats',
      'clients',
      'sales',
      'companies',
      'plans',
      'wallets',
      'leads',
      'bitly',
      'banks',
      'premium',
      'services',
      'keys',
      'webhooks',
      'events',
      'developers',
    ],
  };
  const owner_only = [
    'internal',
    'finance_stats',
    'clients',
    'sales',
    'companies',
    'plans',
    'wallets',
    'leads',
    'bitly',
    'banks',
  ];
  const check_role = role.split('-');
  let roles = [];
  if (check_role.length === 1) {
    roles = AllValues.filter(
      (permission) => !toRemove[role].includes(permission)
    );
  } else if (check_role.length === 2) {
    const RemoveMore = [...owner_only, ...toRemove[check_role[1]]];
    roles = AllValues.filter((permission) => !RemoveMore.includes(permission));
  }
  return roles;
};

export const findRoles = (type, rolesPayload) => {
  let roles = [];
  if (type !== 'custom') {
    roles = RolesHandler(type);
  } else {
    roles = rolesPayload || RolesHandler('client-user');
  }
  return roles;
};

export const checkActiveUrl = (tabs, pathname) => {
  const urlTab = tabs.find(({ url }) => url === pathname);
  if (urlTab) {
    return tabs.indexOf(urlTab);
  }

  return 0;
};

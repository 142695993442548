import React, { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import { routes } from 'config';
import { Avatar, Button, Popper, Icon, StatusChip, ListItem } from 'okraui-ts';
import { LogoutIcon, OffIcon, UserAltIcon, DoubleSortIcon } from 'assets';
import { useOutsideClick } from 'hooks';

import { menu } from '../../layouts/DashLayout/SideNavData';

export const roleChanger = (role) => {
  if (role) {
    const splitRole = role.split('-');
    if (splitRole[1]) return splitRole[1];
    return role;
  }
  return '';
};

const MenuItems = ({ navItems, toggleSideNav, userRoles, isProd }) => {
  const history = useHistory();
  const [openSection, setOptionSection] = useState([]);
  const [animateSection, setAnimateSection] = useState([]);
  const idRef = useRef(null);

  const { pathname } = history.location;

  const isActiveRoute = (route) => {
    return pathname.includes(route);
  };

  function goToRoute(url) {
    history.push(url);
  }

  useEffect(() => {
    const baseSectionURL = pathname.split('/')?.[1];
    setOptionSection([...openSection, `/${baseSectionURL}`]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => {
      idRef.current?.scrollIntoView();
    }, 0);
  }, []);

  const showDropdown = (item) => {
    if (openSection.includes(item)) {
      setOptionSection(openSection.filter((route) => route !== item));
      setAnimateSection(animateSection.filter((route) => route !== item));
    } else {
      setOptionSection([...openSection, item]);
      setAnimateSection([...animateSection, item]);
    }
  };

  const menu = useMemo(() => {
    return navItems
      .map((item) => {
        const navItem = item;
        const mainRouteIsAllowed = userRoles.includes(item.permission);

        if (!mainRouteIsAllowed) return null;

        if (mainRouteIsAllowed && item.children) {
          const children = item?.children.filter(
            (child) =>
              userRoles.includes(child.permission) &&
              (!child.prodOnly || (child.prodOnly && isProd))
          );
          navItem.children = children;
        }

        return navItem;
      })
      .filter(Boolean);
  }, [navItems, isProd, userRoles]);

  return (
    <div className="sidebar-menu-container">
      {menu.map((item) => {
        if (item?.children && !item.group) {
          const showChildren = openSection.includes(item.route);
          const animate = animateSection.includes(item.route);

          return (
            <React.Fragment key={item.route}>
              <div className="navitem-section" key={item.route}>
                <div
                  data-state={
                    (animate && 'animate') || (showChildren && 'show-children')
                  }
                  className="navitem-primary"
                  onClick={() => {
                    showDropdown(item.route);
                  }}
                >
                  <div className="route-icon">
                    {item.label && <Icon name="chevronRight" />}
                  </div>
                  <p className="body3 medium">{item.label}</p>
                </div>

                {showChildren &&
                  item.children.map((child) => {
                    const url = child.route;
                    return (
                      <div
                        onClick={() => {
                          toggleSideNav();
                          goToRoute(url);
                        }}
                        className={cx('navitem-secondary', {
                          'active-route': isActiveRoute(url),
                        })}
                        key={child.route}
                        ref={isActiveRoute(url) ? idRef : null}
                      >
                        <div className="route-icon">{child.icon}</div>
                        <p className="body3">{child.label}</p>

                        {child.badge && (
                          <span className="badge medium">{child.badge}</span>
                        )}

                        {child.status && (
                          <StatusChip variant="info">{child.status}</StatusChip>
                        )}
                      </div>
                    );
                  })}
              </div>
            </React.Fragment>
          );
        }
        if (item?.children && item?.group) {
          return (
            <React.Fragment key={item.route}>
              <div
                className="navitem-section navitem-section-group"
                key={item.route}
              >
                <p className={`navitem-group caption regular ${item?.group}`}>
                  {item?.group}
                </p>

                {item.children.map((child) => {
                  const url = child.route;
                  return (
                    <div
                      onClick={() => {
                        toggleSideNav();
                        goToRoute(url);
                      }}
                      className={cx('navitem-secondary', {
                        'active-route': isActiveRoute(url),
                      })}
                      key={child.route}
                    >
                      <div className="route-icon">{child.icon}</div>
                      <p className="body3">{child.label}</p>

                      {child.badge && (
                        <span className="badge medium">{child.badge}</span>
                      )}

                      {child.status && (
                        <StatusChip variant="info">{child.status}</StatusChip>
                      )}
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={item.route}>
            {item?.group ? (
              <p className={`navitem-group caption regular ${item?.group}`}>
                {item?.group}
              </p>
            ) : (
              <div
                className={cx('navitem-route', {
                  'active-route': isActiveRoute(item.route),
                })}
                key={item.route}
                onClick={() => {
                  toggleSideNav();
                  goToRoute(item.route);
                }}
              >
                <div className="route-icon">{item.icon}</div>
                <p className="body3 medium">{item.label}</p>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const SideNav = (props) => {
  const {
    user,
    logout,
    logoutAll,
    switchProject,
    menu,
    showMobileSideNav,
    toggleSideNav,
    allowed,
    prod,
  } = props;

  const { project, projects, roles } = user || {};

  const containerRef = useRef();
  const history = useHistory();

  const genereateProjectsToShow = () => {
    return projects
      .filter(({ name, _id }) => project._id !== _id && name)
      .splice(0, 3);
  };

  const projectsToShow = genereateProjectsToShow();
  const hasProjectAccess = roles.includes('projects');

  useOutsideClick(containerRef, () => {
    if (!showMobileSideNav) return null;
    return toggleSideNav(false);
  });

  function ellipseName(name, count = 20) {
    return name.length >= count ? `${name.slice(0, count)}...` : name;
  }

  return (
    <div
      className={cx('sidenav-container', {
        'mobile-menu-open': showMobileSideNav,
      })}
      ref={containerRef}
    >
      <Popper
        className="company-toggle"
        renderInPortal
        contentClassName="company-select-dropdown"
        element={
          <div className="selected-company">
            <Avatar name={project?.name} />
            <p className="body3">{ellipseName(project?.name)}</p>
            <DoubleSortIcon />
          </div>
        }
        content={
          <div>
            <ListItem
              label={ellipseName(project?.name)}
              active
              isCheckIconVisible
              icon={<Avatar name={project?.name?.charAt(0)} />}
            />

            {projectsToShow.length ? (
              <>
                <ListItem label="OTHER BUSINESSES" disabled />

                <div className="company-select_section">
                  {projectsToShow.map((project) => {
                    return (
                      <ListItem
                        label={ellipseName(project?.name)}
                        icon={<Avatar name={project?.name?.charAt(0)} />}
                        onClick={() => switchProject(project._id)}
                        key={project._id}
                      />
                    );
                  })}

                  <ListItem
                    className="see-all-btn"
                    onClick={() => history.push(routes.settings.business)}
                    label="See all businesses"
                  />
                </div>
              </>
            ) : null}

            {hasProjectAccess && (
              <div className="company-select_footer">
                <Button
                  size="sm"
                  leftIcon={<Icon name="add" size="sm" />}
                  onClick={() =>
                    history.push(`${routes.settings.business}?new=true`)
                  }
                >
                  Add new business
                </Button>
              </div>
            )}
          </div>
        }
      />

      <MenuItems
        navItems={menu}
        toggleSideNav={() => toggleSideNav(false)}
        userRoles={allowed}
        isProd={prod}
      />

      <Popper
        className="user-toggle"
        renderInPortal
        offset={-50}
        contentClassName="user-toggle-dropdown"
        element={
          <div className="user-profile">
            <div className="user-profile__content">
              <p className="body3 medium fname">{`${user?.firstname} ${user?.lastname}`}</p>
              <p className="caption regular email">{user?.email}</p>
            </div>

            <DoubleSortIcon />
          </div>
        }
        content={
          <div>
            <div
              className="dropdown-option"
              onClick={() => history.push(routes.settings.profile)}
            >
              <UserAltIcon />
              <p className="medium body3">Profile</p>
            </div>

            <div className="dropdown-option" onClick={logout}>
              <LogoutIcon />
              <p className="medium body3">Sign out</p>
            </div>

            <div className="dropdown-option" onClick={logoutAll}>
              <OffIcon />
              <p className="medium body3">Sign out of all sessions</p>
            </div>
          </div>
        }
      />
    </div>
  );
};

SideNav.defaultProps = {
  allowed: [],
  prod: false,
  sections: {},
  menu,
  user: {},
  company: {},
  logout: () => {},
  showMobileSideNav: false,
  toggleSideNav: () => {},
};

export default SideNav;

import EmailValidation from 'emailvalid';

export const ev = new EmailValidation({
  allowFreemail: true,
  blacklist: ['random.com'],
});
export const isFilled = (directors) => {
  let filled = 0;
  let partial = 0;
  for (let i = 0; i < directors.length; i++) {
    const dir = directors[i];
    const emailChecker = ev.check(dir.email);
    if (
      (dir.name &&
        dir.name.trim() !== '' &&
        (dir.email.trim() === '' || !emailChecker.valid)) ||
      (dir.name.trim() === '' && dir.email.trim() !== '' && emailChecker.valid)
    ) {
      partial++;
    }
    if (
      dir.name &&
      dir.name.trim() !== '' &&
      dir.email &&
      dir.email.trim() !== '' &&
      emailChecker.valid
    ) {
      filled++;
    }
  }
  return { filled, partial };
  // return filled === directors.length;
};

export const checkDup = (dirs, type, value, len = 1, isString = false) => {
  const isDup = dirs.filter((form) => {
    return isString ? form === value : form[type] === value;
  });
  return isDup.length > len;
};

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Modal,
  Button,
  Success,
  Close,
  Checkbox,
  View,
  CopyElement,
} from 'okraui-ts';
import { useBuilder } from 'store';
import { CustomLink } from 'assets';
import { routes, appUrl } from 'config';

const PublishModal = ({ active, close, id, surl, short_url, isPay }) => {
  const [saveSettings, setSaveSettings] = useState(false);
  const [viewAppDetails, setViewAppDetails] = useState(false);
  const { clearBuilder } = useBuilder();
  const history = useHistory();

  const confirmModal = () => {
    !saveSettings && clearBuilder();
    history.push(`${routes.business.appsIntegrationId}/${id}`);
  };

  const closeModal = () => {
    close();
    !saveSettings && clearBuilder();
    history.push(routes.business.apps);
  };

  return (
    <Modal active={active} type="bare" modalClass="publish-modal">
      <div className="close" onClick={closeModal}>
        <Close />
      </div>
      <div className="success">
        <Success />
      </div>
      <h5 className="semibold">Congratulations!</h5>
      <p className="body2">Your app is published and it’s now live.</p>

      <div className="link-section">
        <CopyElement element={appUrl(short_url, surl, isPay)} />
        <a
          href={`${appUrl(short_url, surl, isPay)}`}
          target="_blank"
          rel="noreferrer"
          className="body3"
        >
          View app <View />
        </a>
      </div>

      <div className="details">
        {viewAppDetails ? (
          <div className="details__expanded">
            <div className="custom-section">
              <div className="custom-section__icon">
                <CustomLink />
              </div>
              <div>
                <p className="medium">
                  Give your link a professional look with custom URL.
                </p>
              </div>
            </div>

            <div className="exit-section">
              <Checkbox
                checked={saveSettings}
                onChange={(e) => setSaveSettings(e.target.checked)}
                label="Don’t show this again"
              />
              <Button onClick={confirmModal}>Integrate app</Button>
            </div>
          </div>
        ) : (
          <div className="details__button">
            <Button onClick={() => setViewAppDetails(true)}>
              View app details
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PublishModal;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { TextField, Select, Button, ImageStack, useToaster } from 'okraui-ts';
import EmailValidation from 'emailvalid';
import validator from 'validator';
import { urls, env, api, environments } from 'config';
import OkraLogo from 'assets/Images/OkraFullLogo.png';

import { checkFormData, countries, query, questionnaire } from 'helpers';
import {
  FirstBank,
  UBA,
  Access,
  GT,
  Zenith,
  MoreBanks,
  JS,
  ReactLibrary,
  Angular,
  Flutter,
  Vue,
  MoreLibrary,
  Aella,
  Renmoney,
  Swipe,
  Umba,
  MoreCustomers,
} from 'assets';
import { PasswordChecker } from 'components';

const { SANDBOX, DEV } = environments;

const ev = new EmailValidation({
  allowFreemail: true,
  blacklist: ['random.com'],
});
const Register = () => {
  const history = useHistory();
  const { open } = useToaster();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    country: '',
    phone: '',
    referral: '',
  });
  const isFormDataValid = checkFormData(formData);

  /* eslint-disable no-unused-vars */
  const [password, setPassword] = useState('');
  const [passwordStatus, setPasswordStatus] = useState(false);

  const [invalidPhone, setInvalidPhone] = useState(false);

  useEffect(() => {
    const email = query('ref');
    const preEmail = query('email');
    if (email) {
      setLoading(true);
      api({
        url: 'user/invite/search',
        body: { email },
      }).then((res) => {
        setLoading(false);
        if (res.status === 'success') {
          history.push('/invite', { invite: res.data });
        } else {
          open({
            active: true,
            title: 'Invitation not found',
            message: 'Please confirm from your company and try again',
            status: 'error',
          });
        }
      });
    } else if (preEmail) {
      setFormData((prev) => ({ ...prev, email: preEmail }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setFormData((preData) => ({
      ...preData,
      [name]: value,
    }));

    if (e.target.name === 'email') {
      setFormData({ ...formData, email: e.target.value.trim().toLowerCase() });
    }
    if (e.target.name === 'phone') {
      setFormData({ ...formData, phone: e.target.value.replace(/\s/g, '') });
    }
  };

  const collectPassword = (data) => {
    setPassword(data);
    setFormData({ ...formData, password: data });
  };

  const collectPasswordStatus = (data) => {
    setPasswordStatus(data);
  };
  useEffect(() => {
    if (formData.phone === '') {
      setFormData({ ...formData, phone: ' ' });
    }
  }, [formData.phone]);

  const submit = () => {
    if (isFormDataValid && passwordStatus) {
      let isEmailValid;
      if (env === SANDBOX || env === DEV) {
        isEmailValid = validator.isEmail(formData.email);
      } else {
        const checker = ev.check(formData.email);
        isEmailValid = checker.valid;
      }
      if (!isEmailValid) {
        open({
          active: true,
          title: 'Oops',
          message: 'Please try again with a valid email.',
          status: 'error',
        });
        return;
      }
      setLoading(true);
      api({
        url: 'auth/register',
        body: formData,
      })
        .then((res) => {
          setLoading(false);
          if (res.status === 'success') {
            open({
              active: true,
              title: `You've done it! 🎉`,
              message: 'Welcome to Okra, time to build the impossible.',
              status: 'success',
            });
            if (res.data.company) {
              history.push('/existing-team', {
                user: res.data.user,
                company: res.data.company,
                domain: res.data.domain,
              });
            } else {
              history.push('/register-success', { email: formData.email });
            }
          } else {
            let title = '';
            let message =
              res.message || 'Error registering user, please try again';
            if (res?.message?.includes('exists')) {
              title = "You're already in!";
              message =
                'This email exists already with us, check again or reset your password.';
            }
            open({
              active: true,
              title,
              message,
              status: 'error',
            });
          }
        })
        .catch(() => {
          setLoading(false);
          open({
            active: true,
            message: 'Error registering user, please try again',
            status: 'error',
          });
        });
    } else {
      open({
        active: true,
        message: 'Please fill all the information and try again',
        status: 'error',
      });
    }
  };

  const phoneFilter = /[0-9+]/g;
  const phoneArray = formData?.phone.match(phoneFilter);

  useEffect(() => {
    if (formData.phone === ' ') {
      setInvalidPhone(false);
    } else if (
      formData?.phone?.length === phoneArray?.length &&
      formData?.phone?.length > 6
    ) {
      setInvalidPhone(false);
    } else {
      setInvalidPhone(true);
    }
  }, [formData?.phone, phoneArray]);

  const completeBanks = [
    <FirstBank />,
    <UBA />,
    <Access />,
    <GT />,
    <Zenith />,
    <MoreBanks className="auth-more" />,
  ];

  const plugAndPlay = [
    <JS />,
    <ReactLibrary />,
    <Angular />,
    <Flutter />,
    <Vue />,
    <MoreLibrary className="auth-more" />,
  ];

  const happyCustomers = [
    <Aella />,
    <Renmoney />,
    <Swipe />,
    <Umba />,
    <MoreCustomers className="auth-more" />,
  ];

  return (
    <div className="auth register-layout split">
      <div className="register-layout__left">
        <div className="register-layout__left-content">
          <img src={OkraLogo} alt="Okra Logo" className="auth__logo" />
          <h4 className="semibold">Enabling your product innovation</h4>
          <div className="split right_space">
            <TextField
              label="First name"
              name="firstname"
              placeholder="Enter name"
              size="md"
              type="text"
              onChange={handleChange}
              required
            />
            <TextField
              label="Last name"
              name="lastname"
              placeholder="Enter name"
              type="text"
              onChange={handleChange}
              required
            />
          </div>
          <TextField
            label="Work email"
            name="email"
            placeholder="example@example.com"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <PasswordChecker
            label="Enter Password"
            collectPassword={collectPassword}
            passwordStatus={collectPasswordStatus}
          />

          <TextField
            phoneInput={true}
            label="Phone number"
            name="phone"
            placeholder="000000000"
            value={formData.phone}
            maxLength={15}
            onChange={handleChange}
          />

          <Select
            label="How did you hear about Okra?"
            options={questionnaire}
            name="referral"
            placeholder="Please Select"
            onChange={handleChange}
            value={formData.referral}
            showSearch
          />

          <Select
            label="Country"
            options={countries}
            name="country"
            placeholder="Find your country"
            onChange={handleChange}
            value={formData.country}
            showSearch={true}
          />

          <span className="auth__infotext plain">
            By clicking on 'create account', you agree to Okra's
            <a
              href={`${urls.base_url}privacy-policy`}
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              href={`${urls.base_url}end-user-agreement`}
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use.
            </a>
          </span>
          <Button
            type="submit"
            loading={loading}
            variant="primary"
            onClick={submit}
            size="md"
            disabled={!isFormDataValid || invalidPhone}
          >
            Create account
          </Button>
          <p className="auth__infotext">
            Already have an account? <Link to="/login">Sign In</Link>
          </p>
        </div>
      </div>
      <div className="register-layout__right">
        <div className="register-layout__right-content">
          <p className="auth__title body1 semibold">Complete bank coverage</p>
          <p className="auth__subtitle">
            Connect customer accounts from over 20 banks and alternative
            financial institutions.
          </p>

          <ImageStack
            images={completeBanks}
            className="register-layout__imgs"
          />

          <p className="auth__title body1 semibold">Plug and play</p>
          <p className="auth__subtitle body2">
            Interact with our API from our array of libraries and SDKs.
          </p>
          <ImageStack images={plugAndPlay} className="register-layout__imgs" />

          <p className="auth__title body1 semibold">400+ happy customers</p>
          <p className="auth__subtitle body2">
            Innovation is for companies of all sizes, from startup to
            enterprise.
          </p>
          <ImageStack
            images={happyCustomers}
            className="register-layout__imgs"
          />
        </div>
      </div>
    </div>
  );
};

export default Register;

import { getPillVariant } from 'helpers';
import { Pill, TextField, FileUploaderBasic } from 'okraui-ts';
import { useState } from 'react';

const IDs = ({
  verificationStatus,
  handleChange,
  type = 'extras',
  descriptions,
  title,
  icon,
  name,
  textField = true,
  className = '',
  required = true,
}) => {
  const [textFieldName, setTextFieldName] = useState('');

  return (
    <div
      className={`request-access-section request-access-section__${type}-id ${className}`}
    >
      <span className="request-access-section__icon">{icon}</span>
      <div className="request-access-section__container">
        <div className="request-access-section__header">
          <p>
            {title}
            {required && !verificationStatus && (
              <span className="request-access-section__required">*</span>
            )}
          </p>
          {verificationStatus && (
            <Pill
              variant={getPillVariant[verificationStatus]}
              className="text-capitalize"
            >
              {verificationStatus}
            </Pill>
          )}
        </div>
        <p className="dark-grey-font">
          {descriptions[verificationStatus || 'none']}
        </p>
        {(!verificationStatus || verificationStatus === 'failed') &&
          (textField ? (
            <>
              <TextField
                name="idName"
                placeholder="Please enter your name"
                value={textFieldName}
                onChange={(e) => setTextFieldName(e.target.value)}
              />

              <FileUploaderBasic
                placeholder="ID"
                onError={() =>
                  handleChange({
                    target: {
                      name,
                      value: { name: textFieldName.trim(), id: null },
                    },
                  })
                }
                onChange={(file) =>
                  handleChange({
                    target: {
                      name,
                      value: { name: textFieldName.trim(), id: file },
                    },
                  })
                }
                onClear={() =>
                  handleChange({
                    target: {
                      name,
                      value: { name: textFieldName.trim(), id: null },
                    },
                  })
                }
                maxSize={2000}
                disabled={
                  textFieldName.trim() === '' || textFieldName.trim() === ' '
                }
              />
            </>
          ) : (
            <FileUploaderBasic
              placeholder="ID"
              onError={() => handleChange({ target: { name, value: null } })}
              onChange={(file) =>
                handleChange({
                  target: { name, value: file },
                })
              }
              onClear={() =>
                handleChange({
                  target: { name, value: null },
                })
              }
              maxSize={3000}
            />
          ))}
      </div>
    </div>
  );
};

export default IDs;

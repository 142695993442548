import { createContext, useContext, useReducer, useMemo } from 'react';
import { api } from 'config';

const SlaContext = createContext();

const slaReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE': {
      const { data } = action;
      return { ...data };
    }
    case 'REMOVE': {
      return {};
    }
    default: {
      return state;
    }
  }
};

const SlaProvider = (props) => {
  const [state, dispatch] = useReducer(slaReducer, {}, () => {
    const localData = localStorage.getItem('sla');
    return localData ? JSON.parse(localData) : {};
  });
  const value = useMemo(() => [state, dispatch], [state]);
  return <SlaContext.Provider value={value} {...props} />;
};

const useSla = () => {
  const context = useContext(SlaContext);
  if (!context) {
    throw new Error(`useSla must be used within a SlaProvider`);
  }
  const [state, dispatch] = context;
  const updateSlaObj = (obj) => {
    const Sla = localStorage.getItem('sla')
      ? JSON.parse(localStorage.getItem('sla'))
      : {};
    const newSla = { ...Sla, ...obj };
    localStorage.setItem('sla', JSON.stringify(newSla));
    dispatch({ type: 'UPDATE', data: newSla });
    return { status: 'success', data: newSla };
  };
  const remove = () => {
    localStorage.removeItem('sla');
    dispatch({ type: 'REMOVE' });
  };
  const sendSLA = (sla) => {
    const obj = {
      token: sla?._id,
    };
    return api({ url: 'sla/get', body: obj }) // TODO rename
      .then((res) => {
        if (res.status === 'success') {
          return updateSlaObj(res);
        }
        return {
          status: 'error',
          message: 'Error sending sla, please try again',
        };
      })
      .catch(() => {
        return {
          status: 'error',
          message: 'Error sending sla, please try again',
        };
      });
  };
  const generateSLA = (sla) => {
    const obj = {
      sla_owner: sla.owner,
    };
    return api({ url: 'sla/generate', body: obj })
      .then((res) => {
        if (res.status === 'success') {
          return updateSlaObj(res.data.sla);
        }
        return {
          status: 'error',
          message: 'Error sending sla, please try again',
        };
      })
      .catch(() => {
        return {
          status: 'error',
          message: 'Error sending sla, please try again',
        };
      });
  };
  return {
    sla: state,
    updateSlaObj,
    remove,
    sendSLA,
    generateSLA,
  };
};

export { SlaProvider, useSla };

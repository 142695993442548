import {
  NavLink,
  Switch,
  Route,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import {
  Auth,
  Balance,
  Identity,
  Transactions,
  InstantPayment,
  Payments,
} from './InnerViews';
import {
  AuthScreen,
  BalanceScreen,
  TransScreen,
  IdentityScreen,
  InstantPaymentScreen,
  PaymentAuthScreen,
} from './screens';
import { useBuilder } from '../../../store';
import { Tooltip } from 'okraui-ts';

const Products = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { builder } = useBuilder();
  const { billable_products } = builder;
  const instantPaymentEnabled = builder.type === 'one-time';

  const authEnabled =
    builder.auth?.manual ||
    ['income', 'identity', 'balance', 'transactions'].some((item) =>
      billable_products.includes(item)
    );

  const PRODUCT_SECTIONS = [
    {
      title: 'COLLECT PAYMENTS',
      routes: [
        {
          to: '/app-builder/edit/products/instant-payment',
          name: 'Instant payment',
          isDisabled: authEnabled,
          isPayments: instantPaymentEnabled,
        },

        {
          to: '/app-builder/edit/products/payments-auth',
          name: 'Payment authorisation',
          isDisabled: false,
          isPayments: instantPaymentEnabled,
        },
      ],
    },
    {
      title: 'RETRIEVE DATA',
      routes: [
        {
          to: '/app-builder/edit/products/authentication',
          isActive: [
            '/app-builder/edit/products',
            '/app-builder/edit/products/authentication',
          ],
          name: 'Authentication',
          isDisabled: instantPaymentEnabled,
          isPayments: instantPaymentEnabled,
        },

        {
          to: '/app-builder/edit/products/identity',
          name: 'Identity',
          isDisabled: instantPaymentEnabled,
          isPayments: instantPaymentEnabled,
        },
        {
          to: '/app-builder/edit/products/balance',
          name: 'Balance',
          isDisabled: instantPaymentEnabled,
          isPayments: instantPaymentEnabled,
        },
        {
          to: '/app-builder/edit/products/transactions',
          name: 'Transactions',
          isDisabled: instantPaymentEnabled,
          isPayments: instantPaymentEnabled,
        },
      ],
    },
  ];

  return (
    <div className="builder builder-multi builder-products">
      <div className="builder-multi-panel">
        <div className="builder-multi-panel__left">
          <div className="product-sections">
            {PRODUCT_SECTIONS.map((section, index) => {
              return (
                <div className="section" key={index}>
                  <caption>{section.title}</caption>
                  <div className="section-routes">
                    {section.routes.map((route, index) => {
                      if (route.isDisabled) {
                        return (
                          <div
                            key={`disabled-link-${index}`}
                            className="disabled-section-routes"
                          >
                            <Tooltip
                              element={route.name}
                              content={
                                route.isPayments ? (
                                  <p className="caption medium">
                                    Instant payment is currently enabled. To
                                    access data products, please disable instant
                                    payment.
                                  </p>
                                ) : (
                                  <p className="caption medium">
                                    Data products are currently enabled. To
                                    access instant payment, please disable all
                                    data products.
                                  </p>
                                )
                              }
                            />
                          </div>
                        );
                      }

                      return (
                        <NavLink
                          to={route.to}
                          isActive={
                            route.isActive
                              ? () => route.isActive.includes(pathname)
                              : null
                          }
                          key={`products-link-${index} body3 semibold`}
                        >
                          {route.name}
                        </NavLink>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="builder-multi-panel__right">
          <Switch>
            <Route
              exact
              path={[path, `${path}/authentication`]}
              component={Auth}
            />
            <Route path={`${path}/balance`} component={Balance} />
            <Route path={`${path}/identity`} component={Identity} />
            {/* <Route path={`${path}/income`} component={Income} /> */}
            <Route path={`${path}/transactions`} component={Transactions} />
            <Route path={`${path}/payments-auth`} component={Payments} />
            <Route
              path={`${path}/instant-payment`}
              component={InstantPayment}
            />
          </Switch>
        </div>
      </div>

      <div className="builder-wigdet-container">
        <Route
          exact
          path={[path, `${path}/authentication`]}
          component={AuthScreen}
        />
        <Route exact path={`${path}/balance`} component={BalanceScreen} />
        <Route exact path={`${path}/identity`} component={IdentityScreen} />
        {/* <Route exact path={`${path}/income`} component={IncomeScreen} /> */}
        <Route exact path={`${path}/transactions`} component={TransScreen} />
        <Route
          exact
          path={`${path}/payments-auth`}
          component={PaymentAuthScreen}
        />
        <Route
          exact
          component={InstantPaymentScreen}
          path={`${path}/instant-payment`}
        />
      </div>
    </div>
  );
};

export default Products;

import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Card, TextField, Button, useToaster } from 'okraui-ts';
import validator from 'validator';
import { api } from 'config';
import OkraLogo from 'assets/Images/OkraFullLogo.png';

const ResetPassword = (props) => {
  const { open } = useToaster();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    api({
      url: 'auth/reset-password',
      body: { email },
    }).then((res) => {
      setLoading(false);
      if (res.status === 'success') {
        props.history.push('/reset-password-success', { email });
      } else {
        open({
          active: true,
          title: 'Oops!',
          message:
            res.message || 'The email field is incorrect. Please try again',
          status: 'error',
        });
      }
    });
  };

  const enabled = validator.isEmail(email);
  return (
    <div className="auth login-layout">
      <img src={OkraLogo} alt="Okra Logo" className="auth__logo" />
      <Card>
        <form onSubmit={handleSubmit}>
          <h4 className="auth__title">Reset your password.</h4>
          <p className="auth__subtitle">
            Enter the email associated with your account and we'll send you a
            link to reset your password.
          </p>
          <TextField
            label="Email"
            placeholder="email@example.com"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
            required
          />
          <Button
            type="submit"
            variant="primary"
            loading={loading}
            disabled={!enabled}
          >
            Send reset link
          </Button>
          <Link to="/login" className="center-text spaced p">
            Return to Log in
          </Link>
        </form>
      </Card>
    </div>
  );
};

export default ResetPassword;

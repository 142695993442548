import { fugaz } from 'pages/Builder/data/Functions';

export const initState = {
  _id: null,
  app: null,
  short_url: null,
  billable_products: ['auth'],
  logo: 'https://dash.okra.ng/static/media/OkraFullLogo.4bc5f093.png', // missing logo
  name: '',
  color: '#222C3B',
  background_color: '#009E74',
  limit: 24,
  filter: {
    industry_type: 'all',
    banks: [],
  },
  corporate: null,
  connectMessage: 'Select an account to continue',
  widget_success: '',
  widget_failed: '',
  callback_url: '',
  currency: 'NGN',
  exp: '',
  success_title: '',
  success_message: '',
  redirect_url: '',
  mode: 'solid',
  continue_cta: '',
  account_type: 'individual',
  institutions: fugaz,
  customInstitutions: fugaz,
  selection_type: 'none',
  institutions_type: 'default',
  template: null,
  enableAutoConnect: false,
  debitLater: false,
  auth: {
    ussd: false,
    manual: false,
    debitType: 'one-time',
  },
  balance: {
    showBalance: false,
    enableAutoConnect: false,
  },
  identity: {
    selfieVerify: false,
  },
  guarantors: {
    status: false,
    message: '',
    number: 1,
  },
  directors: {
    status: false,
    message: '',
    number: 1,
  },
  transactions: {
    enhancedBalance: false,
    geoLocation: false,
    manual: false,
    spending_patterns: false,
    assets_liabilities: false,
  },
  multi_account: true,
  support_email: '',
  terms: 'fixed',
  amount: '',
  note: '',
  payment_subtext: '',
  landingMessage: '',
  debitFrequency: 'oneOff',
  oneOffDate: '',
  initialAmount: '',
  oneOffStartDate: '',
  recurPeriod: '',
  oneOffEndDate: '',
  variablePayment: false,
  bankDisplayStyle: 'list',
  type: '',

  // landingMessage: '',
  // account: null ,
  // debitFrequency: 'oneOff',
  // oneOffDate: '',
  // recurringTimes: 1,
  // recurPeriod: 1,
  // recipientAccount: false,
  // showMandate: false,
  // oneOffStartDate: '',
  // oneOffEndDate: '',
  orientation: 'desktop',
};
const builderReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_BUILDER': {
      const { data } = action;
      return data;
    }
    case 'CLEAR_BUILDER': {
      return initState;
    }
    default: {
      return state;
    }
  }
};

export default builderReducer;

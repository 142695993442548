import React from 'react';
import PropTypes from 'prop-types';
import { PaystackButton } from 'react-paystack';

import { env } from 'config';

const Paystack = ({
  loading,
  amount,
  email,
  callback,
  text,
  companyId,
  projectId,
  onSuccess = () => null,
  onClose = () => null,
}) => {
  const config = {
    text,
    reference: new Date().getTime().toString(),
    email,
    amount: parseFloat(amount) * 100,
    publicKey:
      env === 'production' || env === 'production-sandbox'
        ? process.env.REACT_APP_PAYSTACK_KEY_LIVE
        : process.env.REACT_APP_PAYSTACK_KEY_TEST,
    metadata: { owner_id: companyId, project_id: projectId },
    channels: ['card', 'bank', 'ussd', 'qr', 'mobile_money', 'bank_transfer'],
  };

  const handlePaystackSuccessAction = (response) => {
    onSuccess(response);
  };

  const handlePaystackCloseAction = () => {
    onClose();
  };

  return (
    <div className="pay-stack">
      <PaystackButton
        {...config}
        className={`button primary-button md-button ${
          loading ? 'loading' : ''
        }`}
        disabled={loading}
        callback={callback}
        onSuccess={handlePaystackSuccessAction}
        onClose={handlePaystackCloseAction}
      />
    </div>
  );
};

Paystack.propTypes = {
  amount: PropTypes.number,
  companyId: PropTypes.string,
  email: PropTypes.string,
  loading: PropTypes.bool,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
  text: PropTypes.string,
};

export default Paystack;

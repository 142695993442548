import { useAuth, useCompany, useBuilder } from 'store';
import { Button, Icon } from 'okraui-ts';
import { limitWord } from 'helpers';
import cx from 'classnames';
import { OkraLogoGreen, FCLogo } from 'assets';
import BaseScreen from './BaseScreen';

const BrandingScreen = ({ showFCLogo }) => {
  const { builder } = useBuilder();
  const { name, orientation, logo } = builder;
  const { user } = useAuth();
  const { company } = useCompany();
  const appName = user?.project?.name || company?.app_name || company?.name;
  const widgetName = limitWord(
    name === 'This Client' ? appName : name || appName,
    25,
    false
  );

  return (
    <BaseScreen
      showProgressBar={false}
      className="branding-screen"
      headerLogos={
        logo && !showFCLogo ? (
          <img className="okra-widget-logo" src={logo} alt="Your Logo" />
        ) : (
          <FCLogo className="okra-widget-logo fcLogo" />
        )
      }
    >
      <h5 className="semibold">
        {widgetName} uses Okra to authorize future payments from your bank
        account.
      </h5>
      <div
        className={cx('widget-info-box', {
          mobile: orientation === 'mobile',
        })}
      >
        <div>
          <Icon name="lock" />
          <div className="content">
            <p className="body3 medium">Secure</p>
            <p className="caption">
              Okra uses secure encryption to transfer your information.
            </p>
          </div>
        </div>

        <div>
          <Icon name="eyeOff" />
          <div className="content">
            <div className="body3 medium">Private</div>
            <p className="caption">
              Your credentials will never be accessible to {widgetName} or Okra.
            </p>
          </div>
        </div>
      </div>

      <div className="branding-screen__footer">
        <div>
          <Icon name="lock2" />
          <p className="body2 caption">Secured by</p>
          <OkraLogoGreen />
        </div>
        <Button variant="neutral">Continue</Button>
      </div>
    </BaseScreen>
  );
};

export default BrandingScreen;

import BaseScreen from './BaseScreen';
import { useBuilder } from 'store';
import { CurrencyField, convertToCommaNumber, Button } from 'okraui-ts';

const InstantPaymentsScreen = () => {
  const { builder } = useBuilder();

  return (
    <BaseScreen
      className="instant-payment-screen"
      title={`Complete payment to FoodCourt`}
      grey
      showProgressBar={false}
    >
      <p className="body2 semibold">Complete payment to FoodCourt</p>
      <span className="caption">
        {builder?.payment_subtext || 'Please see payment details below:'}
      </span>
      <div className="divider"></div>

      <div className="payment-reason">
        <p className="subheading">Pay For</p>
        <span className="bold">{builder?.note || 'Burger and chips'}</span>

        <div className="divider"></div>
      </div>

      <div className="payment-amount">
        {!builder?.variablePayment ? (
          <>
            <p className="subheading">Amount</p>
            <span className="bold">
              {`₦${convertToCommaNumber(builder.amount || 100)}` || '₦0.00'}
            </span>
            <div className="divider"></div>{' '}
          </>
        ) : (
          <CurrencyField
            label="Enter the payment amount"
            disabledCurrencySelector
          />
        )}
      </div>

      <div className="payment-footer">
        {!builder.variablePayment && (
          <div className="total">
            <p className="subheading">Total:</p>
            <span className="bold">
              {`₦${convertToCommaNumber(builder.amount || '100')}` || '₦0.00'}
            </span>
          </div>
        )}

        <Button variant="neutral">Make payment</Button>
      </div>
    </BaseScreen>
  );
};

export default InstantPaymentsScreen;

import { Card } from 'okraui-ts';
import OkraLogo from 'assets/Images/OkraFullLogo.png';
import { RequestSent } from 'assets';
import { useEffect } from 'react';
import { useAuth } from 'store';

const PendingInvite = (props) => {
  const { ClearOnboarding } = useAuth();
  const { location } = props;
  const { company, type } = location?.state || {};
  if (!company) props.history.push('/login');

  useEffect(() => {
    if (type === 'exists') ClearOnboarding();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Copies = {
    invite: {
      title: 'Invite notification sent!',
      info: `Your invitation request was successfully sent${
        company?.name ? ` to ${company.name.trim()}` : ''
      }. Please wait for your admin to approve so you can claim your account.`,
    },
    exists: {
      title: 'This RC number already exists!',
      info: `We've sent a notification to the company's admin. Please wait for your admin to approve so we can add you to the account.`,
    },
  };

  return (
    <div className="auth login-layout pending">
      <img src={OkraLogo} alt="Okra Logo" className="auth__logo" />
      <Card className="pending-invite">
        <RequestSent />
        <h3>{Copies[type || 'invite'].title}</h3>
        <p>{Copies[type || 'invite'].info}</p>
      </Card>
    </div>
  );
};

export default PendingInvite;

import { renderToStaticMarkup } from 'react-dom/server';

export const removeDuplicates = (data, key) => {
  return [...new Map(data.map((item) => [key(item), item])).values()];
};

const special = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
];
const deca = [
  'twent',
  'thirt',
  'fort',
  'fift',
  'sixt',
  'sevent',
  'eight',
  'ninet',
];

export const paymentIntervals = [
  { label: 'Daily', value: 'Daily' },
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Quarterly', value: 'Quarterly' },
  { label: 'Biannually', value: 'Biannually' },
  { label: 'Annually', value: 'Annually' },
];

export const notificationSchedule = [
  {
    label: 'Hourly',
    value: 'hourly',
  },
  {
    label: 'Twice daily',
    value: 'twice_daily',
  },
  {
    label: 'Three times daily',
    value: 'tri_daily',
  },
  {
    label: 'Four times daily',
    value: 'four_daily',
  },
  {
    label: 'Daily',
    value: 'daily',
  },
];

export const stringifyNumber = (n) => {
  if (n < 20) return special[n];
  if (n % 10 === 0) return `${deca[Math.floor(n / 10) - 2]}ieth`;
  return `${deca[Math.floor(n / 10) - 2]}y-${special[n % 10]}`;
};

export const checkFormData = (formData) => {
  return formData && Object.values(formData).every(Boolean);
};

export const decamelize = (str, separator = '_') => {
  return str
    .replace(/([a-z\d])([A-Z])/g, `$1${separator}$2`)
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, `$1${separator}$2`)
    .toLowerCase();
};

export const resetSnakeCase = (str) => {
  return str.replace(/-/g, ` `);
};

export const downloadPdfOfElement = (elementId, title) => {
  const { body } = document;
  if (title) document.title = title;
  const originalBodyInnerHTML = body.innerHTML;
  const element = document.getElementById(elementId) ?? body;
  body.innerHTML = element.innerHTML;
  window.print();
  body.innerHTML = originalBodyInnerHTML;
  window.location.reload();
};

export const downloadPdfOfJSX = (jsx, title) => {
  const { body } = document;
  if (title) document.title = title;
  const originalBodyInnerHTML = body.innerHTML;
  body.innerHTML = renderToStaticMarkup(jsx);
  window.print();
  body.innerHTML = originalBodyInnerHTML;
  window.location.reload();
};

export const getNameFromSlug = (slug) => {
  return slug.replace(/-/g, ' ');
};

export const addRemove = (array = [], value) => {
  if (!value || typeof value !== 'string') return array;
  const setArray = new Set(array);
  if (setArray.has(value)) {
    setArray.delete(value);
  } else {
    setArray.add(value);
  }
  return Array.from(setArray);
};

import { useAuth, useCompany, useBuilder } from 'store';
import { Button, convertToCommaNumber, Icon } from 'okraui-ts';
import { SuccessIcon } from 'assets';
import cx from 'classnames';

import BaseScreen from './BaseScreen';

const SuccessScreen = () => {
  const { builder } = useBuilder();
  const {
    app_title,
    widget_success,
    name,
    multi_account,
    continue_cta,
    orientation,
    amount,
  } = builder;
  const { user } = useAuth();
  const { company } = useCompany();
  const appName = user?.project?.name || company?.app_name || company?.name;
  const widgetName = name === 'This Client' ? appName : name || appName;
  const instantPaymentEnabled =
    builder.type === 'one-time' || builder.debitLater;

  const formattedAmount =
    `₦${convertToCommaNumber(amount || '100')}` || '₦0.00';
  const SuccessMessage = instantPaymentEnabled ? (
    <p className="body2">
      {widget_success ||
        `Your payment of ${formattedAmount} to ${widgetName} has been completed.`}
    </p>
  ) : (
    <p className="body2">
      {widget_success ||
        `Your account has successfully been linked to ${widgetName}.`}
    </p>
  );

  const SuccessTitle = instantPaymentEnabled
    ? app_title || 'Payment successful'
    : app_title || 'Success!';

  return (
    <BaseScreen
      className="success-screen"
      showHeader={false}
      title="Link Successful"
      showProgressBar={false}
      grey
    >
      <div
        className={cx('success-container', {
          mobile: orientation === 'mobile',
        })}
      >
        <div className="icon">
          <SuccessIcon />
        </div>

        <h4 className="semibold">{SuccessTitle}</h4>
        {SuccessMessage}

        <div className="success-container__footer">
          {!instantPaymentEnabled && multi_account && (
            <Button
              variant="secondary"
              leftIcon={<Icon name="add" size="sm" />}
            >
              Link new account
            </Button>
          )}
          <Button variant="neutral">
            {continue_cta || (multi_account ? 'Continue' : 'Done')}
          </Button>
        </div>
      </div>
    </BaseScreen>
  );
};

export default SuccessScreen;

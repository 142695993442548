import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { abbreviateNumber } from 'helpers';
import PropTypes from 'prop-types';

const Content = ({ label }) => (
  <p className="caption" style={{ transform: 'rotate(-90deg)' }}>
    {label}
  </p>
);

const LineGraph = ({ data, XAxisKey, Lines, label, CustomToolTip }) => (
  <ResponsiveContainer width="100%" height="100%" aspect={4.0 / 1.0}>
    <LineChart
      width={730}
      height={250}
      data={data}
      margin={{ top: 0, right: 15, left: 0, bottom: 0 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={XAxisKey} />
      <YAxis tickFormatter={(tickItem) => abbreviateNumber(tickItem)} />
      {CustomToolTip && <Tooltip content={<CustomToolTip />} />}
      <Legend
        wrapperStyle={{ left: -40 }}
        content={<Content label={label} />}
        layout="veritical"
        align="left"
        verticalAlign="middle"
      />
      {Lines &&
        Lines.map((oneLine, i) => (
          <Line
            key={`line-${i}`}
            type="linear"
            dot={false}
            dataKey={oneLine.key}
            stroke={oneLine.color}
          />
        ))}
    </LineChart>
  </ResponsiveContainer>
);

LineGraph.propTypes = {
  data: PropTypes.array,
  CustomToolTip: PropTypes.elementType,
  label: PropTypes.string,
  Lines: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, color: PropTypes.string })
  ),
  XAxisKey: PropTypes.string,
};

export default LineGraph;

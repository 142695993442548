import React, { useState, useEffect } from 'react';
import { CircleCheck, CloseCircle } from 'assets';
import { PasswordField } from 'okraui-ts';

const PasswordChecker = (props) => {
  const [password, setPassword] = useState('');
  const [revealOptions, setRevealOptions] = useState(false);
  const [passwordNo, setPasswordNo] = useState(0);
  const [hasSymbol, setHasSymbol] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [isPasswordOK, setIsPasswordOK] = useState(false);
  const [passwordbar, setPasswordBar] = useState(0);

  useEffect(() => {
    props.collectPassword(password);
    props.passwordStatus(isPasswordOK);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, isPasswordOK]);

  const checkPasswordStrength = (password) => {
    setPasswordNo(password.length);

    let counter = 0;

    // Check password length
    if (password.length > 7) {
      counter += 1;
    }
    // Check for numbers
    if (password.match(/\d/)) {
      setHasNumber(true);
      counter += 1;
    } else {
      setHasNumber(false);
    }

    // Check for mixed case
    if (password.match(/[A-Z]/)) {
      setHasUppercase(true);
      counter += 1;
    } else {
      setHasUppercase(false);
    }

    if (password.match(/[a-z]/)) {
      setHasLowerCase(true);
      counter += 1;
    } else {
      setHasLowerCase(false);
    }

    // Check for special characters
    if (password.match(/[^a-zA-Z\d]/)) {
      setHasSymbol(true);
      counter += 1;
    } else {
      setHasSymbol(false);
    }

    if (counter === 3 || counter === 4) {
      setPasswordBar(1);
      setIsPasswordOK(false);
    } else if (counter === 5) {
      setPasswordBar(2);
      setIsPasswordOK(true);
    } else if (counter < 3) {
      setPasswordBar(0);
      setIsPasswordOK(false);
    }
  };

  const handleChange = (e) => {
    checkPasswordStrength(e.target.value);
    setPassword(e.target.value);
  };

  return (
    <>
      <span onClick={() => setRevealOptions(true)}>
        <PasswordField
          label={props.label}
          name="password"
          placeholder="Enter new Password"
          onChange={handleChange}
          onBlur={() => {
            if (isPasswordOK) setRevealOptions(false);
          }}
          required
        />
      </span>
      <div
        className={`passwordChecker ${revealOptions && 'revealOptions'} ${
          props.className ?? ''
        }`}
      >
        <div className="passwordChecker__bars">
          <div
            className={`passwordChecker__bars__red${
              password.length > 0 ? ' weakStrength' : ''
            }`}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div
            className={`passwordChecker__bars__yellow${
              passwordbar >= 1 ? ' goodStrength' : ''
            }`}
          >
            <span> </span>
            <span> </span>
            <span> </span>
          </div>
          <div
            className={`passwordChecker__bars__green${
              passwordbar >= 2 ? ' strongStrength' : ''
            }`}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="passwordChecker__citeria">
          <div className="passwordChecker__citeria__text">
            <p>{passwordNo} characters</p>
          </div>
          <div className="passwordChecker__citeria__list">
            <p>
              <span>{hasLowerCase ? <CircleCheck /> : <CloseCircle />}</span>
              Lowercase
            </p>
            <p>
              <span>{hasUppercase ? <CircleCheck /> : <CloseCircle />}</span>
              Uppercase
            </p>
            <p>
              <span>{hasNumber ? <CircleCheck /> : <CloseCircle />}</span>
              Number
            </p>
            <p>
              <span>{hasSymbol ? <CircleCheck /> : <CloseCircle />}</span>
              Symbols
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordChecker;

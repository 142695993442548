import { useState } from 'react';
import classNames from 'classnames';
import { useBuilder } from 'store';
import { Products, ArrowLeft, Button, Icon, StatusChip } from 'okraui-ts';
import { InfoIcon, EditPencilIcon } from 'assets';
import BaseHeader from './components/BaseHeader';
import { options, freeProducts } from './data/CustomData';

const Custom = (props) => {
  const { history } = props;
  const { updateBuilder, builder } = useBuilder();
  const { template } = builder;
  let defaultTemp = options[0];

  if (template) {
    defaultTemp = options.find((opt) => opt.value.template);
  }

  const [selected, setSelected] = useState(defaultTemp);

  const handleBack = () => {
    history.push('/app-builder/landing');
  };

  const handleEdit = (add) => {
    history.push('/app-builder/edit/widget-properties');
    if (add) {
      updateBuilder(selected?.value);
    }
  };

  return (
    <div className="builder-custom">
      <BaseHeader />
      <div className="builder-custom-content">
        <div className="inner-content">
          <h4 className="semibold title">
            What do you want to build with Okra?
          </h4>
          <div className="options">
            {options.map((opt, i) => (
              <div
                key={`option-${i}`}
                onClick={() => setSelected(opt)}
                className={classNames('option', {
                  active: opt.option === selected?.option,
                })}
              >
                <p className="medium">{opt.option}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="builder-custom-content__products">
          {selected ? (
            <div className="inner-content">
              <div className="inner-content__recommended">
                <h4 className="semibold">Recommended products</h4>
                {selected.products?.map((prd, i) => {
                  const isFreeProduct = freeProducts.includes(
                    prd.toLowerCase()
                  );

                  return (
                    <div className="product" key={`prd-${i}`}>
                      <div className="icon">
                        <Icon name="check" />
                      </div>
                      <p className="body2">{prd}</p>

                      {isFreeProduct && (
                        <StatusChip
                          variant="info"
                          size="sm"
                          className="free-tag"
                        >
                          FREE
                        </StatusChip>
                      )}
                    </div>
                  );
                })}
              </div>

              {selected?.addons && (
                <div className="inner-content__addons">
                  <h4 className="semibold">Recommended add-ons</h4>
                  {selected?.addons?.map((addon, i) => (
                    <div className="product" key={`prd-${i}`}>
                      <div className="icon">
                        <Icon name="check" />
                      </div>
                      <p className="body2">{addon}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="builder-custom-content__empty-state">
              <Products />
              <p>
                👈 Select your use case to see a recommended setup. If it’s not
                perfect, you can make adjustments within the builder.
              </p>
            </div>
          )}
        </div>

        <div className="builder-custom-content__alertbar">
          <InfoIcon />
          <p className="body2">
            You can edit these recommended products and add-ons in the app
            builder
          </p>
        </div>

        <div className="builder-custom-content__footer">
          <Button variant="secondary" onClick={handleBack}>
            <ArrowLeft />
            Back
          </Button>
          <div>
            <Button variant="primary-text" onClick={handleEdit}>
              Skip
            </Button>
            <Button onClick={() => handleEdit(true)} className="edit-button">
              <EditPencilIcon />
              Edit App
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Custom;

import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const defaultTitle = `Welcome to Okra Client's Dashboard`;
const defaultDescription =
  'Sign up or log in to the Okra Dashboard to manage and create your applications, as well view and manage your user data';
const defaultKeywords = 'Dashboard';

const SEO = ({
  title = defaultTitle,
  desc = defaultDescription,
  keywords = defaultKeywords,
}) => (
  <Helmet
    titleTemplate="Okra Dashboard | %s"
    defaultTitle="Okra Dashboard"
    defer={false}
  >
    <title itemProp="name" lang="en">
      {title}
    </title>
    <meta name="description" content={desc} />
    <meta name="keywords" content={keywords} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={desc} />
  </Helmet>
);

SEO.propTypes = {
  desc: PropTypes.string,
  keywords: PropTypes.string,
  title: PropTypes.string,
};

export default SEO;

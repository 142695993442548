export const isInternal = (role) => {
  return !(
    (role &&
      role.split('-') &&
      role.split('-')[0] &&
      role.split('-')[0] === 'client') ||
    role === 'custom'
  );
};

const internal = [];
const external = [];
export const permitted = (permissions, roles, role) => {
  if (permissions === 'internal') {
    permissions = internal;
  } else if (permissions === 'external') {
    permissions = external;
  }
  const getAllPermissions = [...roles, role];
  return permissions.some((allowed) => getAllPermissions.includes(allowed));
};

export const bareRole = (role = 'custom') => {
  const RoleArray = role?.split('-');
  return RoleArray && RoleArray[1] ? RoleArray[1] : role;
};

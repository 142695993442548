import { lazy } from 'react';

const PaymentRecords = lazy(() => import('./Records'));
const SinglePaymentRecord = lazy(() => import('./SinglePayments/SingleRecord'));
const SingleRefund = lazy(() => import('./SinglePayments/SingleRefund'));

const InitiateDebit = lazy(() => import('./SinglePayments/InitiateDebit'));
const SingleBulkRecord = lazy(() => import('./BulkPayments/SingleBulkRecord'));
const BulkPaymentDetails = lazy(() =>
  import('./BulkPayments/BulkPaymentDetails')
);

export {
  PaymentRecords,
  SinglePaymentRecord,
  SingleBulkRecord,
  InitiateDebit,
  BulkPaymentDetails,
  SingleRefund,
};

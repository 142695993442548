import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import { FlagsmithProvider } from 'flagsmith/react';
import flagsmith from 'flagsmith';
import { ToasterProvider } from 'okraui-ts';

import Auth from 'security';
import { GoogleAnalytics } from 'hooks';
import { NetworkNotification } from 'components';
import {
  AuthProvider,
  CompanyProvider,
  SlaProvider,
  BanksProvider,
  SocketProvider,
} from 'store';
import Routes from './routes';
import Combine from './store/CombineTopProviders';

const queryClient = new QueryClient();

const Dashboard = () => (
  <>
    <NetworkNotification />
    <HelmetProvider>
      <FlagsmithProvider
        flagsmith={flagsmith}
        options={{
          cacheFlags: true,
          // environmentID: process.env.REACT_APP_FLAGSMITH_KEY,
          environmentID: '4hqxb5wpqB29uQRrrrWqCj',
        }}
      >
        <Router>
          <ToasterProvider>
            <GoogleAnalytics />
            <Combine
              components={[
                AuthProvider,
                CompanyProvider,
                SlaProvider,
                BanksProvider,
                SocketProvider,
              ]}
            >
              <QueryClientProvider client={queryClient}>
                <Switch>
                  {Routes.map(({ ...rest }, idx) => (
                    <Auth key={`route-${idx}`} {...rest} />
                  ))}
                </Switch>
              </QueryClientProvider>
            </Combine>
          </ToasterProvider>
        </Router>
      </FlagsmithProvider>
    </HelmetProvider>
  </>
);

export default Dashboard;

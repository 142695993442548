export const limitWord = (name = '', limit = 30, addDots = true) => {
  if (name?.length > limit) {
    name = `${name.slice(0, limit)}${addDots ? '...' : ''}`;
  }
  return name || ' ';
};

export const capitalized = (data) => {
  const result =
    data && typeof data === 'string'
      ? data.charAt(0).toUpperCase() + data.slice(1)
      : data;
  return result;
};

import { routes } from 'config';
import { useAuth } from 'store';

const Auth = ({ component, redirect: Redirect }) => {
  const { loggedIn, subLoggedIn } = useAuth();

  if (!loggedIn && !subLoggedIn) {
    return component;
  }
  if (subLoggedIn) {
    return <Redirect to={routes.onboarding.base} />;
  }
  return <Redirect to={routes.dashboard.home} />;
};

export default Auth;

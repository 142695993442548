import { useQuery, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { api } from 'config';

const useFetch = (url, key, filter = {}, condition = true, dispatchFunc) => {
  const controller = new AbortController();
  const { signal } = controller;
  const queryClient = useQueryClient();
  const fetchRows = (filter) =>
    condition &&
    ((dispatchFunc && dispatchFunc(filter, signal)) ||
      api({
        url,
        signal,
        body: {
          ...filter,
        },
      }));

  const { isLoading, isError, error, data, isFetching, isPreviousData } =
    useQuery([key, filter, url], () => fetchRows(filter), {
      keepPreviousData: false,
      staleTime: 1000 * 60 * 3,
      retry: false,
      cacheTime: 1000 * 60 * 3,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    });

  // stateTime & cacheTime - 3 mins
  if (typeof data === 'string' && data.includes('Request Aborted')) {
    // clear data if cancelled
    queryClient.invalidateQueries(key);
  }

  const theError = isError
    ? error.message
    : data?.status === 'error'
    ? data?.message || true
    : false;

  useEffect(() => {
    // return () => controller?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return {
    loading: isLoading || isFetching,
    error: theError,
    data: data?.data,
    isPreviousData,
  };
};

export default useFetch;

import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import validator from 'validator';

import {
  Card,
  TextField,
  Button,
  getFormValues,
  useToaster,
  PasswordField,
} from 'okraui-ts';
import { api } from 'config';
import { useAuth } from 'store';
import OkraLogo from 'assets/Images/OkraFullLogo.png';

const Login = (props) => {
  const { access } = useAuth();
  const { open } = useToaster();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const path = window.location.pathname.split('/');
    if (path?.length && path[1] === 'verify') {
      const tokenArr = path.slice(3, path.length);
      const token = tokenArr.join('/');
      const email = path[2];
      if (token && email) {
        open({
          active: true,
          message: 'Verifying your email, please wait...',
          status: 'success',
        });
        api({
          url: 'auth/verify-email',
          body: { email, token },
        }).then((res) => {
          setLoading(false);
          if (res.status === 'success') {
            open({
              active: true,
              title: 'Verification Successful',
              message: 'Please log in your account',
              status: 'success',
            });
            props.history.push('/login');
          } else {
            open({
              active: true,
              title: 'Verification failed',
              message:
                res.message ||
                'Error verifying user, please confirm details and try again',
              status: 'error',
            });
          }
        });
      } else {
        props.history.push('/login');
      }
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [formData, setFormData] = useState({
    email: {
      value: '',
      error: '',
      valid: false,
    },
    password: {
      value: '',
      error: '',
      valid: false,
    },
  });
  const handleFormData = ({ target }, valid) => {
    setFormData({
      ...formData,
      [target.name]: {
        value: target.value,
        valid,
        error: '',
      },
    });

    if (target.name === 'email') {
      setFormData({
        ...formData,
        email: {
          value: target.value.trim().toLowerCase(),
          valid,
          error: '',
        },
      });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const formValues = getFormValues(formData);
    const { status, message, data } = await access('auth/login', formValues);
    if (data || message) setLoading(false);

    if (status === 'success') {
      if (message === 'sla_not_signed') {
        props.history.push('/sla', {
          email: data,
        });
      }
      if (message === '2fa') {
        props.history.push('/two-factor-authentication', { email: data });
      }

      if (message === 'invite')
        props.history.push('/pending-invite', {
          company: data,
          type: 'invite',
        });
    } else if (message && message.includes('Passwords')) {
      const loginAttempts = data && data.loginAttempts;
      setFormData((prev) => ({
        ...prev,
        password: {
          ...formData.password,
          valid: false,
        },
      }));
      loginAttempts &&
        open({
          active: true,
          title: message,
          message: `The email or password you entered is incorrect. Please check and try again. You have ${
            3 - loginAttempts
          } attempts left.`,
          status: 'error',
        });
    } else if (message) {
      let title = '';
      let status = 'error';
      if (message.includes('verify')) {
        title = 'Hold on';
        status = 'warning';
      }
      open({
        active: true,
        title,
        message,
        status,
      });
    }
  };
  const submit = async (e) => {
    e.preventDefault();
    handleSubmit();
  };
  const { email, password } = formData;
  const enabled = validator.isEmail(email.value) && password.value;
  return (
    <div className="auth login-layout">
      <img src={OkraLogo} alt="Okra Logo" className="auth__logo" />
      <Card>
        <form onSubmit={submit}>
          <h4 className="auth__title">Sign in</h4>
          <TextField
            label="Email"
            name="email"
            type="email"
            placeholder=""
            value={email.value.trim().toLowerCase()}
            onChange={handleFormData}
            required
          />
          <PasswordField
            label="Password"
            name="password"
            placeholder=""
            value={password.value}
            onChange={handleFormData}
            required
          />
          <Button
            type="submit"
            variant="primary"
            loading={loading}
            onClick={submit}
            disabled={!enabled}
          >
            Login
          </Button>
          <Link to="/reset-password" className="center-text spaced p medium">
            Forgot Your Password?
          </Link>
        </form>
      </Card>
    </div>
  );
};

export default Login;

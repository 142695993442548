export const routes = {
  auth: {
    login: '/login',
    verify: '/verify',
    twoFactorAuthentication: '/two-factor-authentication',
    pendingInvite: '/pending-invite',
    verifyToken: '/verify-invite/:token',
    existingTeam: '/existing-team',
    resetPassword: '/reset-password',
    resetPasswordSuccess: '/reset-password-success',
    reset: '/reset',
    invite: '/invite',
    register: '/legacy-signup',
    registerSuccess: '/register-success',
    logout: '/logout',
  },

  onboarding: {
    base: '/onboarding',
    sla: '/sla',
  },

  dashboard: {
    home: '/dashboard/home',
  },

  business: {
    base: '/business',
    wallet: '/business/wallet',

    singleWalletReceipt: '/business/wallet/receipts/:id',
    singleWalletInvoice: '/business/wallet/invoices/:id',

    businessCallbacks: '/business/callbacks',

    apps: '/business/apps',
    appsConfiguration: '/business/apps/configuration/:id',
    appConfigurationId: '/business/apps/configuration',
    appsIntegration: '/business/apps/integration/:id',
    appsIntegrationId: '/business/apps/integration',
    appsRecord: '/business/apps/records/:id',
    appsRecordId: '/business/apps/records',

    customers: '/business/customers',
    singleCustomer: '/business/customers/:id',
    clients: '/business/clients',
  },

  data: {
    overview: '/data/overview',
    records: '/data/records',
    singleRecord: '/data/records/:id',
    authenication: '/data/auths',
    identity: '/data/identities',
    singleIdentity: '/data/identities/:id',
    balance: '/data/balance',
    singleBalance: '/data/balances/:id',
    transactions: '/data/transactions',
    singleTransaction: '/data/transactions/:id',
    income: '/data/incomes',
    singleIncome: '/data/incomes/:id',
    reconciliation: '/data/reconciliation',
  },

  payments: {
    base: '/payments',
    overview: '/payments/overview',

    records: '/payments/records',
    singleRecords: '/payments/records/single-records',
    bulkRecords: '/payments/records/bulk-records',
    singleBulkRecord: '/payments/records/bulk-records/:id',
    bulkPaymentDetails: '/payments/records/bulk-records/:fileId/:paymentId',
    recordsInitiateDebit: '/payments/records/initiate-debit',
    singleRecord: '/payments/records/:id',
    singleRefund: '/payments/refund/:id',

    links: '/payments/links',
    singleLink: '/payments/links/:id',

    transfers: '/payments/transfers',
    payouts: '/payments/payouts',
    singlePayout: '/payments/payouts/:id',
    disputes: '/payments/disputes',

    refunds: '/payments/refunds',
    refund: '/payments/refund',
  },

  developers: {
    apiKeys: '/developers/api-keys',
    webhook: '/developers/webhook',
    events: '/developers/events',
    bankStatus: '/developers/bank-status',
  },

  settings: {
    billing: '/settings/billing',
    billingPlan: '/settings/billing/plans',
    billingHistory: '/settings/billing/history',
    billingPlanPricing: '/settings/billing/plans/pricing',

    profile: '/settings/profile',
    businessProfile: '/settings/profile/business',
    company: '/settings/company',

    team: '/settings/team',
    teamInvited: '/settings/team/invited',
    teamProcess: '/settings/team/process',
    singleTeamProcess: '/settings/team/process/:userid',

    notifications: '/settings/notifications',
    auditLogs: '/settings/audit-logs',
    singleAuditLog: '/settings/audit-logs/:id',

    business: '/settings/business',
    singleBusiness: '/settings/business/:id',
  },

  appBuilder: {
    base: '/app-builder',
    edit: {
      products: '/app-builder/edit/products',
    },
  },
};

import { SEO } from 'components';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'store';
import { AuthSection, DashboardSection } from 'security/section';
import { OnboardingLayout, DashboardLayout } from 'layouts';

const Auth = (prps) => {
  const {
    component: Component,
    layout: Layout,
    layoutType,
    section: Section,
    title,
    desc,
    state: Provider,
    ...rest
  } = prps;
  const { loggedIn } = useAuth();
  const LayoutCompoent =
    Layout || (loggedIn ? DashboardLayout : OnboardingLayout);
  const SectionComponent =
    Section || (loggedIn ? DashboardSection : AuthSection);

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <SEO title={title} desc={desc} />
            <SectionComponent
              {...prps}
              component={
                <LayoutCompoent type={layoutType}>
                  {Provider ? (
                    <Provider>
                      <Component {...props} />
                    </Provider>
                  ) : (
                    <Component {...props} />
                  )}
                </LayoutCompoent>
              }
              redirect={Redirect}
            />
          </>
        );
      }}
    />
  );
};

export default Auth;

import { useState } from 'react';
import {
  NavLink,
  Switch,
  Route,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import { Icon } from 'okraui-ts';

import {
  // AccountType,
  AccountSelection,
  Institutions,
  InstitutionsInner,
} from './InnerViews';
import {
  AccountSelectionScreen,
  InstitutionScreen,
  // AccountTypeScreen,
} from './screens';

const Links = [
  // {
  //   to: '/app-builder/edit/institutions/account-type',
  //   isActive: [
  //     '/app-builder/edit/institutions',
  //     '/app-builder/edit/institutions/account-type',
  //   ],
  //   name: 'Account type',
  // },
  {
    to: '/app-builder/edit/institutions/account-selection',
    name: 'Account selection',
  },
  {
    to: '/app-builder/edit/institutions/financial-institutions',
    name: 'Financial institutions',
  },
];
const Products = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [showInnerPanel, setShowInnerPanel] = useState(true);

  const allowInnerPanel = pathname.includes(
    '/app-builder/edit/institutions/financial-institutions'
  );

  return (
    <div className="builder builder-multi builder-products builder-">
      <div className="builder-multi-panel" style={{ position: 'relative' }}>
        <div className="builder-multi-panel__left">
          <div className="product-sections">
            <div className="section">
              <div className="section-routes">
                {Links.map((link, i) => (
                  <NavLink
                    to={link.to}
                    isActive={
                      link.isActive
                        ? () => link.isActive.includes(pathname)
                        : null
                    }
                    key={`institutions-link-${i}`}
                  >
                    {link.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="builder-multi-panel__right">
          <Switch>
            {/* <Route */}
            {/*  exact */}
            {/*  path={[path, `${path}/account-type`]} */}
            {/*  component={AccountType} */}
            {/* /> */}
            <Route
              exact
              path={[path, `${path}/account-selection`]}
              component={AccountSelection}
            />
            <Route
              exact
              path={`${path}/financial-institutions`}
              component={Institutions}
            />
          </Switch>
        </div>

        {allowInnerPanel && (
          <div
            className="layout-toggle"
            onClick={() => setShowInnerPanel(!showInnerPanel)}
          >
            <Icon
              size="sm"
              name={showInnerPanel ? 'chevronLeft' : 'chevronRight'}
            />
          </div>
        )}

        {allowInnerPanel && showInnerPanel && (
          <div className="builder-multi-panel__right">
            <Switch>
              <Route
                exact
                path={`${path}/financial-institutions`}
                component={InstitutionsInner}
              />
            </Switch>
          </div>
        )}
      </div>
      <div className="builder-wigdet-container institution-container">
        {/* <Route */}
        {/*  exact */}
        {/*  path={[path, `${path}/account-type`]} */}
        {/*  component={AccountTypeScreen} */}
        {/* /> */}
        <Route
          exact
          path={[path, `${path}/account-selection`]}
          component={AccountSelectionScreen}
        />
        <Route
          exact
          path={`${path}/financial-institutions`}
          component={InstitutionScreen}
        />
      </div>
    </div>
  );
};

export default Products;

import { Toggle, Tooltip } from 'okraui-ts';
import { useBuilder, useCompany } from 'store';
import clsx from 'classnames';

const Payments = () => {
  const { company } = useCompany();
  const { builder, setBuilder } = useBuilder();
  const { debitLater } = builder;

  const { features = [] } = company;
  const hasPayment = debitLater;
  const hasPaymentEnabled = features.includes('payments');
  const instantPaymentEnabled = builder.type === 'one-time';

  return (
    <>
      <div className="product-section-info">
        <p className="semibold body2">Payments</p>
        <p className="subtitle body3">
          Collect future payments from your customers.{' '}
        </p>
      </div>

      {hasPaymentEnabled ? (
        <div className="product-section">
          <div
            className={clsx('action-section', {
              disabled: instantPaymentEnabled,
            })}
          >
            <p className="body3 medium">Enable payments authorisation</p>
            <Toggle
              checked={hasPayment || instantPaymentEnabled}
              disabled={instantPaymentEnabled}
              onChange={({ target }) => {
                const { checked } = target;
                setBuilder('debitLater', checked);
              }}
            />
          </div>
        </div>
      ) : (
        <Tooltip
          className="payments-tooltip"
          element={
            <>
              <div className="product-section">
                <div className="action-section disabled">
                  <div className="action-section__content">
                    <p className="medium body3">Enable payments</p>
                  </div>
                  <Toggle disabled />
                </div>
              </div>
            </>
          }
          content={
            <p className="tooltip-inner caption medium">
              Sorry, access to payments requires additional{' '}
              <span className="primary">verification</span>.
            </p>
          }
        />
      )}
    </>
  );
};

export default Payments;

import { routes } from 'config';
import { OkraLogo, Close } from 'okraui-ts';
import { useHistory } from 'react-router-dom';

const BaseHeader = () => {
  const history = useHistory();

  const close = () => {
    history.push(routes.business.apps);
  };
  return (
    <div className="base-header">
      <OkraLogo />
      <div className="base-header-close" onClick={close}>
        <Close />
        <p className="body2 medium">Exit</p>
      </div>
    </div>
  );
};

export default BaseHeader;

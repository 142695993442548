export const options = [
  {
    option: 'Lending',
    products: [
      'Authentication',
      'Balance',
      'Income',
      'Identity',
      'Transactions',
    ],
    addons: ['Show balance', 'Enable AutoConnect', 'Payment Authorization'],
    value: {
      template: 'lending',
      billable_products: [
        'auth',
        'identity',
        'balance',
        'transactions',
        'income',
      ],
      balance: {
        showBalance: true,
        enableAutoConnect: true,
      },
      debitLater: true,
      auth: {
        debitType: 'one-time',
      },
    },
  },
  {
    option: 'Personal finance',
    products: ['Authentication', 'Balance', 'Transactions', 'Identity'],
    addons: ['Enable AutoConnect'],
    value: {
      template: 'personal_finance',
      billable_products: ['auth', 'identity', 'balance', 'transactions'],
      balance: {
        enableAutoConnect: true,
      },
      transactions: {
        enhancedBalance: false,
        geoLocation: false,
        manual: false,
        spending_patterns: true,
      },
    },
  },
  {
    option: 'Digital banking',
    products: ['Authentication', 'Identity', 'Transactions'],
    addons: ['Payment Authorization'],
    value: {
      template: 'digital_banking',
      billable_products: ['auth', 'identity', 'transactions'],
      auth: {
        debitType: 'one-time',
      },
      debitLater: true,
      identity: {
        selfieVerify: true,
      },
    },
  },
  {
    option: 'Insurance',
    products: ['Authentication', 'Identity', 'Income'],
    addons: ['Enable balance'],
    value: {
      template: 'insurance',
      billable_products: ['auth', 'identity', 'income'],
      transactions: {
        assets_liabilities: true,
      },
    },
  },
  {
    option: 'Remittance',
    products: ['Authentication', 'Identity'],
    addons: ['Payment Authorization'],
    value: {
      template: 'remitance',
      billable_products: ['auth', 'identity'],
      auth: {
        ussd: true,
        debitType: 'one-time',
      },
      debitLater: true,
      identity: {
        selfieVerify: true,
      },
    },
  },
];

export const freeProducts = ['authentication'];

import { useState, useEffect } from 'react';
import { Card, Button, Checkbox, PasswordField, useToaster } from 'okraui-ts';
import { api, urls } from 'config';
import OkraLogo from 'assets/Images/OkraFullLogo.png';

import { PasswordChecker } from 'components';

const InvitedUser = (props) => {
  const { open } = useToaster();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [accept, setAccept] = useState(false);
  const [invite, setInvite] = useState({});
  const [passwordStatus, setPasswordStatus] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    api({
      url: 'auth/register',
      body: {
        ...invite,
        company: invite.company._id,
        password,
      },
    }).then((res) => {
      setLoading(false);
      if (res.status === 'success') {
        open({
          active: true,
          title: 'Successful',
          message: 'Please log in to view your dashboard',
          status: 'success',
        });
        props.history.push('/login');
      } else if (res.status === 'error') {
        let title = '';
        if (res?.message?.include && res.message.includes('exists'))
          title = "You're already in!";
        open({
          active: true,
          title,
          message: res.message || 'Error registering user, please try again',
          status: 'error',
        });
      }
    });
  };

  const collectPassword = (data) => {
    setPassword(data);
  };

  const collectPasswordStatus = (data) => {
    setPasswordStatus(data);
  };
  useEffect(() => {
    const { location } = props;
    const propInvite = location?.state?.invite;
    if (!propInvite) props.history.push('/register');
    setInvite(propInvite);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="auth login-layout invited">
      <img src={OkraLogo} alt="Okra Logo" className="auth__logo" />
      <Card>
        <form onSubmit={handleSubmit}>
          <h4 className="auth__center-title">Join your team on Okra</h4>
          <p className="auth__center-subtitle">
            You’ve been invited to join {invite?.company?.name} on Okra. Create
            a password to join the team.
          </p>

          <PasswordChecker
            label="Password"
            className="auth__invitePassword"
            collectPassword={collectPassword}
            passwordStatus={collectPasswordStatus}
          />
          <PasswordField
            label="Confirm password"
            name="confirm-password"
            placeholder=""
            value={cPassword}
            onChange={(e) => setCPassword(e.target.value)}
            required
          />

          <Checkbox
            label={
              <span className="auth__infotext plain">
                I accept Okra’s{' '}
                <a
                  href={`${urls.base_url}privacy-policy`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a
                  href={`${urls.base_url}end-user-agreement`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use.
                </a>
              </span>
            }
            onChange={(e) => setAccept(e.target.checked)}
            className="auth__checkbox"
          />
          <Button
            type="submit"
            variant="primary"
            loading={loading}
            disabled={
              !password || !accept || password !== cPassword || !passwordStatus
            }
          >
            Join the team
          </Button>
        </form>
      </Card>
    </div>
  );
};

export default InvitedUser;

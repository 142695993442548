const handleNotes = (note, open) => {
  const { status, msg } = note;
  open({
    active: true,
    message: msg,
    status,
  });
};

const handleDownload = (note, open) => {
  const { link } = note;
  window.location.href = link;
  handleNotes(note, open);
};

const HandleSocketMsgs = (
  msg,
  updateUser,
  updateCompany,
  updateSla,
  updateBanksArray,
  open,
  logout
) => {
  if (msg.alert) handleNotes(msg.alert, open);
  switch (msg.action) {
    case 'connected':
      // connected;
      break;
    case 'note':
      handleNotes(msg.data, open);
      break;
    case 'download':
      handleDownload(msg.data, open);
      break;
    case 'logout':
      logout();
      break;
    case 'update':
      if (msg?.state === 'sla' && msg.data) updateSla(msg.data);
      if (msg?.state === 'banks' && msg.data) updateBanksArray(msg.data);
      if (msg?.state === 'company' && msg.data) updateCompany(msg.data);
      if (msg?.state === 'user' && msg.data) updateUser(msg.data);
      break;
    default:
    // nothing;
  }
};

export default HandleSocketMsgs;

import React from 'react';
import { Card } from 'okraui-ts';
import PropTypes from 'prop-types';

import ProfileUploader from 'components/ProfileUploader';

const ProfileCard = ({
  imageData,
  alt,
  onFileSelected,
  title,
  subTitle,
  children,
  loading,
}) => {
  return (
    <div className="profile-card">
      <Card className="profile-card--card">
        <div className="card-header">
          <ProfileUploader
            imageData={imageData}
            alt={alt}
            sendImage={onFileSelected}
            loading={loading}
          />
          <section className="header-info">
            <div>
              <h5 className="semibold">{title}</h5>
              <p className="body2">{subTitle}</p>
            </div>
          </section>
        </div>
        <section className="profile-card--children">
          <div className="profile-card--right" />
          <div className="profile-card--left">{children}</div>
        </section>
      </Card>
    </div>
  );
};

ProfileCard.defaultProps = {
  alt: 'Upload Your Profile Picture',
  title: 'Company',
  subTitle: 'Lagos',
  onFileSelected: () => '',
  loading: false,
};

ProfileCard.propTypes = {
  onFileSelected: PropTypes.func,
  alt: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  imageData: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ProfileCard;

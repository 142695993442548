import { useBanks } from 'store';
import { BankCover, Icon, Tooltip } from 'okraui-ts';

const selectedBanks = [
  'first-bank-of-nigeria',
  'united-bank-for-africa',
  'guaranty-trust-bank',
  'access-bank',
  'zenith-bank',
  'ecobank-nigeria',
  'stanbic-ibtc-bank',
  'first-city-monument-bank',
];
const CustomInsitutions = () => {
  const { banks } = useBanks();
  const bnks = banks.filter((b) => !selectedBanks.includes(b.slug));
  const topBanks = banks.filter((b) => selectedBanks.includes(b.slug));

  return (
    <div className="all-screen">
      <div className="header-section">
        <p className="medium body3">Default institution</p>
        <Tooltip
          element={<Icon name="info" size="sm" color="#627490" />}
          content={
            <p className="caption medium">
              The top eight (8) institutions are visible by default. Other
              institutions are searchable.
            </p>
          }
        />
      </div>

      <div className="category">
        <p className="subtitle body3">Top eight (8) institutions</p>
      </div>

      <div className="default-screen__content">
        <div className="banks-display">
          {topBanks.map((bank, i) => (
            <div className="banks-display__item" key={`bnk-${i}-${bank.slug}`}>
              <BankCover bank={bank} />
            </div>
          ))}
        </div>
      </div>

      <div className="category">
        <p className="subtitle body3">Other institutions</p>
      </div>
      <div className="default-screen__content">
        <div className="banks-display">
          {bnks.map((bank, i) => (
            <div className="banks-display__item" key={`bnk-${i}-${bank.slug}`}>
              <BankCover bank={bank} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomInsitutions;

import {
  PaymentsTitleIcon,
  PaymentsHero,
  Aella,
  Casava,
  Renmoney,
  Swipe,
  Umba,
  MoreCustomers,
} from 'assets';

import { Button, ImageStack } from 'okraui-ts';
import { Link } from 'react-router-dom';

const Hero = ({ requestExists, requestLoader, requestPayment }) => (
  <div className="payments-overview-hero">
    <div className="payments-overview-hero__text">
      <div>
        <div className="payments-overview-hero__tag">
          <PaymentsTitleIcon />
          <span className="caption caption-semibold">Payments</span>
        </div>
        <h2 className="payments-overview-hero__title">
          Receive instant bank to bank payments in minutes.
        </h2>
        <p className="payments-overview-hero__desc">
          Use payment links to send various types of payments with ease. Create
          a full payment plan page in just a few clicks and share the link with
          your customers—no code required.
        </p>
        <div className="payments-overview-hero__actions">
          <Button loading={requestLoader} onClick={requestPayment}>
            {requestExists ? 'Check verification status' : 'Request access'}
          </Button>
          <a
            href="https://docs.okra.ng"
            className="button primary-text-button primary-link"
            target="_blank"
            rel="noreferrer"
          >
            Read the docs
          </a>
        </div>
      </div>
      <div>
        <h4>Building with Payments</h4>
        <p>
          Join all of the amazing companies building with Okra Payments API and
          start to receive payments in minutes.
        </p>
        <div className="payments-overview-hero__brands">
          <ImageStack
            images={[
              <Aella />,
              <Casava />,
              <Renmoney />,
              <Swipe />,
              <Umba />,
              <MoreCustomers />,
            ]}
          />
        </div>
        <div className="payments-overview-hero__actions">
          <Link to="https://docs.okra.ng">
            <Button variant="primary-text">Contact sales</Button>
          </Link>
        </div>
      </div>
    </div>
    <div className="payments-overview-hero__image">
      <PaymentsHero />
    </div>
  </div>
);

export default Hero;

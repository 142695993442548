export const statuses = {
  pending: 'warning',
  approved: 'success',
  rejected: 'critical',
  verified: 'success',

  warning: 'Pending',
  success: 'Approved',
  critical: 'Rejected',
};

export const statusConverter = (stat) => {
  stat = stat.toLowerCase();
  return statuses[stat || 'rejected'];
};

export const checkIfExists = (value, array, field) => {
  return array.some((obj) => {
    return obj[field] === value.toString();
  });
};
function checkStatus(status, compare, extra) {
  return extra ? status === compare || extra === status : status === compare;
}

export const checkStatusArray = (StatArr) => {
  let status;
  if (StatArr.every((stat) => checkStatus(stat, 'approved', 'verified'))) {
    status = 'success';
  } else if (
    StatArr.every((stat) => checkStatus(stat, 'rejected')) ||
    StatArr.some((stat) => checkStatus(stat, 'rejected'))
  ) {
    status = 'critical';
  } else if (
    StatArr.every((stat) => checkStatus(stat, 'pending')) ||
    StatArr.some((stat) => checkStatus(stat, 'pending'))
  ) {
    status = 'warning';
  }
  return status;
};

export const convertDataURIToFile = (dataURI, name) => {
  if (!dataURI || !name) return null;

  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ia], { type: mimeString });

  return new File([blob], name, { type: blob.type });
};

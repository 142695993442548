export const picker = {
  naira: '₦',
  ngn: '₦',
  dollar: '$',
  usd: '$',
  pound: '£',
  gbp: '£',
  eur: '€',
  euro: '€',
};

export const maxMixRate = {
  naira: { max: 10000000, min: 100 },
  ngn: { max: 10000000, min: 100 },
  dollar: { max: 20000, min: 20 },
  usd: { max: 20000, min: 20 },
  pound: { max: 10000, min: 10 },
  gbp: { max: 10000, min: 10 },
  eur: { max: 15000, min: 15 },
  euro: { max: 15000, min: 15 },
};

export const currencyConverter = (currency = 'ngn') => {
  currency = currency?.toLowerCase();
  return picker[currency || 'ngn'];
};

export const formatAsMoney = (amount, unit = 2, isCentOrKobo = false) => {
  if (typeof amount === 'number') {
    if (isCentOrKobo) {
      return (parseFloat(amount) / 100).toLocaleString('en', {
        minimumFractionDigits: unit,
        maximumFractionDigits: unit,
      });
    }

    return parseFloat(amount).toLocaleString('en', {
      minimumFractionDigits: unit,
      maximumFractionDigits: unit,
    });
  }

  return 0;
};

export const checkRate = (amount, currency = 'ngn') => {
  if (!Number(amount)) return true;
  if (amount < maxMixRate[currency].min) return true;
  if (amount > maxMixRate[currency].max) return true;
  return false;
};

import { Select, TextField, FileUploader, Pill } from 'okraui-ts';

import { getPillVariant, countries } from 'helpers';
import { CompanyIcon } from 'assets/Icons';

const OperatingAddress = ({ verificationStatus, formData, handleChange }) => {
  const {
    operating_country,
    operating_state,
    operating_city,
    operating_address,
    operating_building,
  } = formData;

  const descriptions = {
    none: 'Please enter the details of your company operating address',
    pending:
      'Your address verification is also undergoing checks by our team. This could take up to 2 working days, please be patient.',
    failed:
      'Your operating address could not be verified. Please ensure that you entered the correct address and upload a proof of address document.',
    success: 'Your company operating address is fully verified.',
  };

  return (
    <div className="request-access-section">
      <span className="request-access-section__icon">
        <CompanyIcon />
      </span>
      <div className="request-access-section__container">
        <div className="request-access-section__header">
          <p>
            Operating address{' '}
            {!verificationStatus && (
              <span className="request-access-section__required">*</span>
            )}
          </p>
          {verificationStatus && (
            <Pill
              variant={getPillVariant[verificationStatus]}
              className="text-capitalize"
            >
              {verificationStatus}
            </Pill>
          )}
        </div>
        <p className="dark-grey-font">
          {descriptions[verificationStatus || 'none']}
        </p>
        {(!verificationStatus || verificationStatus === 'failed') && (
          <div>
            <Select
              label="Country"
              name="operating_country"
              placeholder="Select country"
              options={countries}
              value={operating_country}
              onChange={handleChange}
            />
            <TextField
              label="State"
              name="operating_state"
              value={operating_state}
              placeholder="Enter state"
              onChange={handleChange}
            />
            <TextField
              label="City"
              name="operating_city"
              value={operating_city}
              placeholder="Enter city"
              onChange={handleChange}
            />
            <TextField
              label="Address"
              name="operating_address"
              value={operating_address}
              placeholder="Enter office address or landmark"
              onChange={handleChange}
            />
            <TextField
              label="Building/office number"
              name="operating_building"
              placeholder="Enter building number"
              value={operating_building}
              onChange={handleChange}
            />
            <div className="input-container">
              <label className="caption caption-semibold">
                Proof of operating address
              </label>
              <FileUploader
                onError={() =>
                  handleChange({
                    target: { name: 'operating_proof', value: null },
                  })
                }
                onChange={(file) =>
                  handleChange({
                    target: { name: 'operating_proof', value: file },
                  })
                }
              />
            </div>
            <div className="upload-instructions">
              <div className="caption">
                Please upload one of the documents mentioned below, which cannot
                be more than six (6) months old:
              </div>
              <ul>
                <li className="caption dark-grey-font">
                  Utility bill e.g. PHCN bill
                </li>
                <li className="caption dark-grey-font">
                  Bank statement that clearly states current operating address
                </li>
                <li className="caption dark-grey-font">
                  Tax assessment/remittance
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OperatingAddress;

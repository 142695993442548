/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useToaster, Drawer, Button, UserFull, BankAlt, Add } from 'okraui-ts';
import { Calendar2 } from 'assets/Icons';
import { useCompany } from 'store';
import { api } from 'config';

import { getErrorMsg } from 'helpers';
import CACForms from './CACForms';
import IDs from './IDs';
import OperatingAddress from './OperatingAddress';

const initialFormData = {
  cac_form_type: '',
  cac_2: null,
  cac_7: null,
  cac_1_1: null,
  reg_application: null,
  operating_country: '',
  operating_state: '',
  operating_city: '',
  operating_address: '',
  operating_building: '',
  operating_proof: null,
  gov_license: null,
  owner_ids: [],
};

const RequestAccess = ({
  active,
  toggleRequestAccess,
  getCacStatus,
  getAddressStatus,
  getMainlicenseStatus,
}) => {
  const { updateCompanyObj, company } = useCompany();
  const { open } = useToaster();

  const [formData, setFormData] = useState({
    ...initialFormData,
  });
  const [formLoader, setFormLoader] = useState(false);

  const [cacVerificationStatus, setCacVerificationStatus] = useState(
    getCacStatus()
  );
  const [addressVerificationStatus, setAddressVerificationStatus] = useState(
    getAddressStatus()
  );
  const [mainRegLicenseVeificationStatus, setMainRegLicenseVeificationStatus] =
    useState(getMainlicenseStatus());

  const [count, setCount] = useState(1);

  const addID = [];

  const idVeificationStatus = ''; // TODO: get the correct value.

  const isSubmitButtonEnabled = (formData) => {
    return (
      formData.gov_license !== null &&
      formData.owner_ids.length > 0 &&
      (formData.cac_1_1 !== null ||
        (formData.cac_2 !== null && formData.cac_7 !== null) ||
        formData.reg_application !== null)
    );
  };

  const isDisabled = !isSubmitButtonEnabled(formData);

  const editID = (arr, val) => {
    const { name, id } = val;
    let appendVal = true;
    const newArr = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].name === name) {
        if (id !== null) {
          newArr.push(val);
        }
        appendVal = false;
      } else {
        newArr.push(arr[i]);
      }
    }
    if (appendVal) newArr.push(val);
    return newArr;
  };

  const handleChange = ({ target }) => {
    if (target.name === 'owner_ids') {
      let tempArr = [...formData.owner_ids];
      if (formData.owner_ids.length < 1) {
        tempArr = [...formData.owner_ids, target.value];
      } else {
        tempArr = editID(formData.owner_ids, target.value);
      }
      setFormData({
        ...formData,
        owner_ids: tempArr,
      });
    } else {
      setFormData({
        ...formData,
        [target.name]: target.value,
      });
    }
  };

  const submitVerification = async () => {
    setFormLoader(true);

    const data = new FormData();
    data.append('page', 'payment');

    Object.keys(formData).forEach((key) => {
      if (key === 'owner_ids') {
        formData[key].forEach((item, _) => {
          data.append('owner_ids', item.id);
          data.append('owner_names', item.name);
        });
      } else {
        const value = formData[key];
        value && data.append(key, value);
      }
    });

    try {
      const res = await api({
        url: 'company/request',
        body: data,
        formData: true,
      });

      if (res.status === 'success') {
        toggleRequestAccess(false);
        updateCompanyObj({ ...res.data });
        open({
          active: true,
          status: 'success',
          title: 'Verification in progress',
          message:
            'Our team is reviewing the documents you submitted. This process could take up to 2 business days. Thank you for your patience.',
          action: (
            <Button
              variant="primary-text"
              onClick={() => {
                toggleRequestAccess(true);
              }}
            >
              Check verification status
            </Button>
          ),
        });
      } else {
        open({
          active: true,
          status: 'error',
          message: res?.message || 'An error occured. Please try again.',
        });
      }
      setFormLoader(false);
    } catch (error) {
      const message = getErrorMsg();
      open({
        active: true,
        status: 'error',
        message,
      });
      setFormLoader(false);
    }
  };

  const mainRegLicenseDescriptions = {
    none: 'Please upload business main regulatory license',
    pending:
      'Your license is undergoing checks by our team. This could take up to 2 working days, please be patient.',
    failed:
      'Your license could not be verified.Please ensure that you uploaded the correct license.',
    success: 'Your license is fully verified',
  };

  const idDescriptions = {
    none: 'Please Upload Government issued ID',
    pending:
      'Your ID is undergoing checks by our team. This could take up to 2 working days, please be patient.',
    failed:
      'Your ID could not be verified.Please ensure that you uploaded the correct document.',
    success: 'Your ID is fully verified',
  };

  for (let i = 0; i < count; i++) {
    if (i === 0) {
      addID.push(
        <IDs
          verificationStatus={mainRegLicenseVeificationStatus}
          handleChange={handleChange}
          type="main"
          descriptions={idDescriptions}
          name={'owner_ids'}
          key={`owner-${i}`}
          title={'Government issued ID'}
          icon={<UserFull />}
        />
      );
    } else {
      addID.push(
        <IDs
          verificationStatus={mainRegLicenseVeificationStatus}
          handleChange={handleChange}
          key={`owner-${i}`}
          descriptions={idDescriptions}
          name={'owner_ids'}
          title={'Government issued ID'}
          icon={<UserFull />}
          required={false}
        />
      );
    }
  }

  useEffect(() => {
    setCacVerificationStatus(getCacStatus());
    setAddressVerificationStatus(getAddressStatus());
    setMainRegLicenseVeificationStatus(getMainlicenseStatus());
  }, [company, getCacStatus, getAddressStatus, getMainlicenseStatus]);

  return (
    <Drawer
      active={active}
      close={() => toggleRequestAccess(false)}
      title={
        cacVerificationStatus ||
        addressVerificationStatus ||
        mainRegLicenseVeificationStatus
          ? 'Verification Status'
          : 'Request access to Payments'
      }
      body={
        <div className="request-access-container">
          <div className="request-access-header">
            {cacVerificationStatus || addressVerificationStatus ? (
              <>
                <span className="request-access-header__icon">
                  <Calendar2 />
                </span>
                <div>
                  <p className="p-medium">Application received on:</p>
                  <p className="dark-grey-font">5 February, 2021</p>{' '}
                  {/* TODO */}
                </div>
              </>
            ) : (
              <p className="p-medium">
                Please complete this verification process to gain access to
                Payments.
              </p>
            )}
          </div>
          <div>
            <CACForms
              verificationStatus={cacVerificationStatus}
              formData={formData}
              handleChange={handleChange}
            />
            <IDs
              verificationStatus={mainRegLicenseVeificationStatus}
              handleChange={handleChange}
              type="main"
              descriptions={mainRegLicenseDescriptions}
              name={'gov_license'}
              title={'Main Regulatory License'}
              icon={<BankAlt />}
              className={'main-reg-license'}
              textField={false}
            />
            <div className="request-access-section request-access-section__ids">
              {addID.map((component) => component)}
              {(!mainRegLicenseVeificationStatus ||
                mainRegLicenseVeificationStatus === 'failed') && (
                <Button
                  type="button"
                  size="sm"
                  children={'Add'}
                  rightIcon={<Add />}
                  onClick={() => {
                    setCount(count + 1);
                  }}
                />
              )}
            </div>
            <OperatingAddress
              verificationStatus={addressVerificationStatus}
              formData={formData}
              handleChange={handleChange}
            />
          </div>
          {!(cacVerificationStatus || addressVerificationStatus) && (
            <div
              className="request-access-footer"
              onClick={(e) => e.stopPropagation()}
            >
              <Button
                type="button"
                size="sm"
                disabled={isDisabled}
                loading={formLoader}
                onClick={submitVerification}
              >
                Submit Verification
              </Button>
            </div>
          )}
        </div>
      }
    />
  );
};

export default RequestAccess;

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { env } from 'config';

const stripePromise = loadStripe(
  env === 'production' || env === 'production-sandbox'
    ? process.env.REACT_APP_STRIPE_KEY_LIVE
    : process.env.REACT_APP_STRIPE_KEY_TEST
);

const StripeProvider = ({ children }) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export { StripeProvider };

import { Button, TextField, TextArea, Select } from 'okraui-ts';
import isGibberish from 'is-gibberish';
import { industries } from 'helpers';

const ProjectSummary = ({ onPageChange, onChange, company, open }) => {
  const counter = { count: company?.purpose?.length || 0, limit: 50, max: 250 };

  const { industry } = company;

  const submit = () => {
    // do more
    if ((company?.purpose?.length || 0) < 50) {
      open({
        active: true,
        status: 'error',
        message: 'Please type in more than 50 characters in your overview',
      });
    } else {
      const isRubbish = isGibberish(company.purpose);
      if (isRubbish) {
        open({
          active: true,
          title: 'Really 😒',
          status: 'error',
          message: 'Sorry, our gibberish detector went off , please try again',
        });
      } else {
        company.app_name && onPageChange(null, 'forward');
      }
    }
  };
  const handleLimit = (e) => {
    const { value } = e.target;
    if (value.length <= 250) {
      onChange(e);
    }
  };
  return (
    <div className="onboarding-layout__inner">
      <div className="onboarding-layout__inner-right">
        <h4 className="semibold">Business summary</h4>
        <p className="body2">
          Please indicate your industry and brand name, and provide us with a
          brief overview of your product or business venture.
        </p>
      </div>
      <div className="onboarding-layout__inner-left">
        <div className="onboarding-layout__inner-form">
          <Select
            label="Industry"
            options={industries}
            name="industry"
            showSearch={false}
            onChange={onChange}
            value={industry}
            placeholder="Select industry"
          />

          <TextField
            value={company.app_name}
            label="Business name"
            placeholder="Business name"
            name="app_name"
            type="text"
            className="space-below"
            onChange={onChange}
            maxLength={50}
            minLength={3}
            required
            footnote="Your business name is the name by which your customers identify your brand or product."
          />
          <TextArea
            label="Business summary (minimum 50 characters)"
            placeholder="Write a short summary"
            name="purpose"
            type="text"
            required
            onChange={handleLimit}
            value={company.purpose || ''}
            className="space-below"
            min={50}
            max={250}
            message={`${counter.count} of ${
              counter.max || counter.limit
            } remaining`}
          />
          <div className="onboarding-layout__inner-footer">
            <Button
              type="submit"
              variant="secondary"
              onClick={() => onPageChange(null, 'backwards')}
            >
              Previous
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={submit}
              disabled={
                !company.purpose ||
                !(company.purpose && company.purpose.length > 49) ||
                !company.app_name ||
                !industry.length
              }
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSummary;

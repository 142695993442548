import { useBuilder } from 'store';
import { TextField, Icon, FileUploaderBasic } from 'okraui-ts';
import { FauxBank, SampleLogo } from 'assets';

const getErrorMessage = (rawMessage) => {
  if (typeof rawMessage === 'string') {
    return rawMessage.includes('size')
      ? 'Your file exceeds 50kb. Please upload a file that is 50kb or below.'
      : 'Something went wrong, please try again';
  }
  return '';
};

const Branding = () => {
  const { builder, setBuilder, updateBuilder } = useBuilder();
  const { name, uploadError } = builder;
  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onerror = () =>
      setBuilder(
        'logo',
        'https://dash.okra.ng/static/media/OkraFullLogo.4bc5f093.png'
      );
    reader.onload = () => {
      const binaryStr = reader.result;
      const newData = setBuilder('logo', binaryStr);
      const { builder } = newData;
      const updatedBuilder = { ...builder, uploadError: null };
      updateBuilder(updatedBuilder);
    };
    reader.readAsDataURL(file);
  };
  return (
    <>
      <div className="product-section-info">
        <p className="medium body3">Branding</p>
        <p className="subtitle body3">
          Enter your app name and upload a logo to help customers recognize your
          brand.
        </p>
      </div>

      <div className="product-section">
        <TextField
          label="App name"
          placeholder="Food Court"
          value={name}
          onChange={({ target }) => setBuilder('name', target.value)}
          maxLength={50}
        />

        <div className="file-picker">
          <FileUploaderBasic
            maxSize={50}
            onChange={handleFileUpload}
            allowedExtensions={['jpg', 'jpeg', 'png']}
            placeholder="Upload brand icon"
            onError={(msg) => {
              setBuilder('uploadError', msg);
            }}
            variant="v2"
            footnote={getErrorMessage(uploadError)}
          />
        </div>
        <div className="upload-instruction">
          <p className="medium body3 upload-instruction__title">Upload guide</p>

          <div className="upload-instruction__icons">
            <FauxBank />
            <Icon name="arrowRight" size="sm" color="#627490" />
            <SampleLogo />
          </div>

          <ul className="upload-instruction__guide-list">
            <li>Upload only your brand icon, as shown in the example above.</li>
            <li>
              Brand icon should be 24x24 pixels in dimension and in SVG, JPEG,
              or PNG formats.
            </li>
            <li>The maximum file size for the upload is 50KB.</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Branding;

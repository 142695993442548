const envs = ['sandbox', 'dev', 'staging', 'production'];

export const env = envs.includes(process.env.REACT_APP_ENV)
  ? process.env.REACT_APP_ENV
  : 'dev';

const api_urls = {
  sandbox: 'http://localhost:7777/',
  dev: 'https://dash-api.dev.okra.ng/',
  staging: 'https://dash-api.staging.okra.ng/',
  production: 'https://dash-api.okra.ng/',
};

const socket_urls = {
  sandbox: 'http://localhost:7777/v1/dash',
  dev: 'https://dash-api.dev.okra.ng/v1/dash',
  staging: 'https://dash-api.staging.okra.ng/v1/dash',
  production: 'https://dash-api.okra.ng/v1/dash',
};

const data_app_urls = {
  sandbox: 'https://app.dev.okra.ng/',
  dev: 'https://app.dev.okra.ng/',
  staging: 'https://app.staging.okra.ng/',
  production: 'https://app.okra.ng/',
};

const pay_app_urls = {
  sandbox: 'https://pay.dev.okra.ng/',
  dev: 'https://pay.dev.okra.ng/',
  staging: 'https://pay.staging.okra.ng/',
  production: 'https://pay.okra.ng/',
};

const base_urls = {
  sandbox: 'https://dev.okra.ng/',
  dev: 'https://dev.okra.ng/',
  staging: 'https://staging.okra.ng/',
  production: 'https://okra.ng/',
};

const urls = {
  api_url: api_urls[env],
  socket_url: socket_urls[env],
  data_app_url: data_app_urls[env],
  pay_app_urls: pay_app_urls[env],
  base_url: base_urls[env],
};

export const appUrl = (short_url, sUrl, isPayment = false) => {
  const isMainUrlValid =
    typeof sUrl === 'string' && !(sUrl && sUrl.includes('localhost'));
  if (isPayment) {
    return isMainUrlValid ? sUrl : `${urls.pay_app_urls}${short_url}`;
  }
  return isMainUrlValid ? sUrl : `${urls.data_app_url}${short_url}`;
};

export default urls;

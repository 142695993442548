import { Radio, Button } from 'okraui-ts';
import { useBuilder } from 'store';
import { GTBLogo } from 'assets';
import BaseScreen from './BaseScreen';

const BalanceScreen = () => {
  const { builder } = useBuilder();
  const { balance, billable_products } = builder;

  const hasBalance = billable_products.includes('balance');

  return (
    <BaseScreen className="balance-screen" title="Select an Account" grey>
      <div className="balance-container">
        <p className="semibold subtitle">Select an account</p>

        <div className="balance-container__header">
          <p className="caption">Account name</p>
          <p className="medium caption">Grace Ademola-Adenle</p>
        </div>

        <div className="balance-container__bank">
          <div className="bank-icon">
            <GTBLogo />
          </div>

          <p className="semibold body3">Guaranty Trust Bank</p>
        </div>

        <div className="bank-container-list">
          <div className="bank-container-list__item">
            <div className="balance-account">
              <p className="caption medium">Savings</p>
              <p className="caption">******1234</p>
            </div>
            <div className="balance-check">
              {balance?.showBalance && hasBalance && (
                <p className="caption medium">₦0.00</p>
              )}
              <Radio />
            </div>
          </div>
        </div>
        <div className="balance-container__footer">
          <Button variant="neutral">Continue</Button>
        </div>
      </div>
    </BaseScreen>
  );
};

export default BalanceScreen;

import { Button } from 'okraui-ts';

import { PaymentsFooter } from 'assets';

const Footer = ({ requestExists, requestLoader, requestPayment }) => (
  <div className="payments-overview-footer">
    <div className="payments-overview-footer__text">
      <h4>Ready to get started?</h4>
      <p>Request access to start receiving payments in minutes.</p>
      <Button loading={requestLoader} onClick={requestPayment}>
        {requestExists ? 'Check verification status' : 'Request access'}
      </Button>
    </div>
    <div className="payments-overview-footer__image">
      <PaymentsFooter />
    </div>
  </div>
);

export default Footer;

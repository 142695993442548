import { Button } from 'okraui-ts';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { MobileScreenIllustraction } from 'assets';
import { useBuilder } from 'store';
import { routes } from 'config';

import Landing from './Landing';
import Custom from './Custom';
import Edit from './Edit';
import BaseHeader from './components/BaseHeader';

const Builder = (props) => {
  const { history } = props;
  const { updateBuilder } = useBuilder();
  const { location } = props;
  const data = location?.state?.data;
  const { path } = useRouteMatch();

  if (data) {
    updateBuilder(data);
    history.push('/app-builder/edit/widget-properties');
  }
  return (
    <>
      <div className="viewport-msg">
        <BaseHeader />

        <div className="icon">
          <MobileScreenIllustraction />
        </div>

        <h4 className="bold body2">Not available on mobile</h4>
        <p className="body3 description">
          Switch to a desktop device to access the full customization
          functionality of the app builder. Alternatively, return to the apps
          page to use the quick link option.
        </p>

        <div className="viewport-msg__footer">
          <Button onClick={() => history.push(routes.business.apps)}>
            Return to apps
          </Button>
        </div>
      </div>
      <div className="viewport-action">
        <Switch>
          <Route exact path={[path, `${path}/landing`]} component={Landing} />
          <Route path={`${path}/custom`} component={Custom} />
          <Route path={`${path}/edit`} component={Edit} />
        </Switch>
      </div>
    </>
  );
};

export default Builder;

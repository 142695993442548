import { Button, Icon } from 'okraui-ts';
import cx from 'classnames';

import { useBuilder } from 'store';
import { WidgetPhone, WidgetGlobe } from 'assets';
import BaseScreen from './BaseScreen';

const AuthScreen = () => {
  const { builder } = useBuilder();

  const { auth, orientation } = builder;

  return (
    <BaseScreen
      className={cx('auth-screen', {
        mobile: orientation === 'mobile',
      })}
    >
      <h4 className="semibold">Select connection method</h4>
      <div className="auth-screen__option">
        <div>
          <div className="option">
            <WidgetPhone />
          </div>
          <div>
            <p className="semibold body2">Mobile app</p>
            <p className="caption body3">Use bank app login credentials</p>
          </div>
          <div className="check" />
        </div>
      </div>

      <div className="auth-screen__option">
        <div>
          <div className="option">
            <WidgetGlobe />
          </div>
          <div>
            <p className="semibold body2">Internet banking</p>
            <p className="caption body3">Connect with web credentials</p>
          </div>

          <div className="check" />
        </div>
      </div>

      {auth && auth.manual && (
        <div className="auth-screen__option">
          <div>
            <div className="option">
              <Icon name="keypad" color="#627490" />
            </div>
            <div>
              <p className="semibold body2">Account number</p>
              <p className="caption body3">Link with account number</p>
            </div>

            <div className="check" />
          </div>
        </div>
      )}

      <div className="auth-screen__footer">
        <p className="body3">
          By clicking “Continue”, you agree to Okra’s{' '}
          <span className="body3 link">End User Agreement</span>
        </p>
        <Button variant="neutral">Continue</Button>
      </div>
    </BaseScreen>
  );
};

export default AuthScreen;

import { Radio } from 'okraui-ts';
import { InfoIcon } from 'assets';
import { useBuilder } from 'store';

const ACCOUNT_SELECTION_OPTIONS = [
  { label: 'Single account', value: 'single' },
  { label: 'Multiple accounts', value: 'multiple' },
  { label: 'All accounts', value: 'none' },
];

const AccountSelected = () => {
  const { builder, updateBuilder } = useBuilder();
  const { selection_type } = builder;

  const showInfoText = selection_type === 'none';

  return (
    <>
      <div className="product-section-info">
        <p className="medium body3">Account selection</p>
        <p className="subtitle">
          Please choose a method for customers to connect their bank account(s)
          to your app.
        </p>
      </div>

      <div className="product-section account-types">
        <div className="action-section">
          <p className="body3 medium">Selection method</p>
        </div>
        <div className="account-types__options">
          {ACCOUNT_SELECTION_OPTIONS.map((option) => {
            return (
              <Radio
                label={option.label}
                checked={option.value === selection_type}
                onClick={() => {
                  if (option.value === 'none') {
                    updateBuilder({
                      enableAutoConnect: true,
                      selection_type: option.value,
                    });
                  } else {
                    updateBuilder({
                      enableAutoConnect: false,
                      selection_type: option.value,
                    });
                  }
                }}
              />
            );
          })}
        </div>
      </div>

      {showInfoText && (
        <div className="info-callout">
          <div>
            <InfoIcon />
          </div>
          <p className="body3">
            Account selection screen will be hidden if customers connect all
            their bank accounts from an institution.
          </p>
        </div>
      )}
    </>
  );
};

export default AccountSelected;

const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN': {
      const { user, token, loggedIn, subLoggedIn, refreshToken } = action;
      return {
        user,
        token,
        refreshToken,
        loggedIn,
        subLoggedIn,
      };
    }
    case 'LOGOUT': {
      const { initState } = action;
      return initState;
    }
    case 'UPDATE_USER': {
      const { data } = action;
      return {
        ...state,
        user: data,
      };
    }
    default: {
      return state;
    }
  }
};

export default authReducer;

import React from 'react';
import PropTypes from 'prop-types';

import { Popper, Menu, Close, Icon } from 'okraui-ts';
import {
  SlackMonoIcon,
  DocsIcon,
  ApiReferenceIcon,
  ApiStatusIcon,
  QuestionMarkInCircle,
  StarIcon,
  MapIcon,
  WriteIcon,
  DoubleSortIcon,
} from 'assets';

const TopBar = ({
  isSideNavActive,
  toggleSideNav,
  user,
  toggleEnvironment,
  onContactSupportClick,
}) => {
  const isProduction = user.mode === 'production';
  const isSandbox = user?.mode === 'production-sandbox';

  return (
    <div className="topbar-container">
      <div className="topbar-mobile-page">
        <div
          className="topbar-mobile-menu-toggle"
          onClick={() => toggleSideNav(!isSideNavActive)}
        >
          {isSideNavActive ? <Close /> : <Menu />}
        </div>
      </div>

      <div className="topbar-actions">
        <Popper
          className="topbar-support"
          element={
            <div className="topbar-support-avatar">
              <QuestionMarkInCircle />
              <p className="body3 semibold">Help</p>
            </div>
          }
          content={
            <SupportPoppoverMenu
              onContactSupportClick={onContactSupportClick}
            />
          }
        />

        <Popper
          className="topbar-actions__mode-toggle"
          element={
            <div className="env-toggle">
              <div
                className={`env-toggle_state body3 medium ${
                  isProduction ? 'live' : 'test'
                }`}
              >
                {isProduction ? 'Live' : 'Test'} mode
              </div>
              <DoubleSortIcon />
            </div>
          }
          content={
            <div className="topbar-dropdown">
              <div className="topbar-dropdown_section">
                <h5 className="topbar-dropdown_section__header">modes</h5>
                <div
                  className={`topbar-dropdown_section__item ${
                    isProduction && 'active'
                  }`}
                  onClick={() => toggleEnvironment('production')}
                >
                  <p className="topbar-dropdown_section__item_title">
                    Live mode
                  </p>
                  {isProduction && <Icon name="check" size="sm" />}
                </div>
                <div
                  className={`topbar-dropdown_section__item ${
                    isSandbox && 'active'
                  }`}
                  onClick={() => toggleEnvironment('production-sandbox')}
                >
                  <p className="stopbar-dropdown_section__item_title">
                    Test mode
                  </p>
                  {isSandbox && <Icon name="check" size="sm" />}
                </div>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

const SupportPoppoverMenu = ({ onContactSupportClick = () => {} }) => {
  const supportData = {
    slackLink:
      'https://join.slack.com/t/okracommunity/shared_invite/zt-19b27i9iq-_Ehi5WxfeicNnx7aPVKAMw',
    documentationLink: 'https://docs.okra.ng',
    apiReferenceLink: 'https://docs.okra.ng/reference',
    apiStatusLink: 'https://status.okra.ng/',
    whatsNew: 'https://okra.launchnotes.io/',
    productRoadmap: 'https://okra.launchnotes.io/board',
    requestFeature: 'https://okra.launchnotes.io/ideas',
  };

  return (
    <div className="topbar-support-content">
      <div className="support_section">
        <h5 className="support_section__header">support</h5>
        <div
          className="support_section__item contact-support"
          onClick={onContactSupportClick}
        >
          <Icon name="chat" size="sm" color="#737A91" />
          <p className="support_section__item_title">Contact support</p>
        </div>

        <div className="support_section__item">
          <a href={supportData.slackLink} target="_blank" rel="noreferrer">
            <SlackMonoIcon />
            <p className="support_section__item_title">Join us on Slack</p>
          </a>
        </div>
      </div>

      <div className="support_section">
        <h5 className="support_section__header">resources</h5>
        <div className="support_section__item">
          <a
            href={supportData.documentationLink}
            target="_blank"
            rel="noreferrer"
          >
            <DocsIcon />
            <p className="support_section__item_title">Documentation</p>
          </a>
        </div>

        <div className="support_section__item">
          <a
            href={supportData.apiReferenceLink}
            target="_blank"
            rel="noreferrer"
          >
            <ApiReferenceIcon />
            <p className="support_section__item_title">API reference</p>
          </a>
        </div>

        <div className="support_section__item">
          <a href={supportData.apiStatusLink} target="_blank" rel="noreferrer">
            <ApiStatusIcon />
            <p className="support_section__item_title">API Status</p>
          </a>
        </div>
      </div>

      <div className="support_section">
        <h5 className="support_section__header">product</h5>
        <div className="support_section__item">
          <a href={supportData.whatsNew} target="_blank" rel="noreferrer">
            <StarIcon />
            <p className="support_section__item_title">What's new</p>
          </a>
        </div>

        <div className="support_section__item">
          <a href={supportData.productRoadmap} target="_blank" rel="noreferrer">
            <MapIcon />
            <p className="support_section__item_title">Product roadmap</p>
          </a>
        </div>

        <div className="support_section__item">
          <a href={supportData.requestFeature} target="_blank" rel="noreferrer">
            <WriteIcon />
            <p className="support_section__item_title">Request a feature</p>
          </a>
        </div>
      </div>
    </div>
  );
};

TopBar.propTypes = {
  user: PropTypes.object,
  company: PropTypes.object,
  sla: PropTypes.object,
  logout: PropTypes.func,
  toggleEnvironment: PropTypes.func,
  toggle: PropTypes.func,
  search: PropTypes.func,
  switchProject: PropTypes.func,
};

TopBar.defaultProps = {
  user: {},
  company: {},
  sla: {},
  logout: () => {},
  logoutAll: () => {},
  isSideNavActive: false,
  toggleSideNav: () => {},
  toggle: () => {},
  search: () => {},
  switchProject: () => {},
  toggleMobileSideNav: () => {},
};

export default TopBar;

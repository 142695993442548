import { Switch, Route, useRouteMatch } from 'react-router-dom';
import EditHeader from '../components/EditHeader';
import Products from './Products';
import Properties from './Properties';
import Institutions from './Institutions';

const EditBuilder = () => {
  const { path } = useRouteMatch();
  return (
    <div className="builder-edit">
      <EditHeader />

      <div className="builder-container">
        <Switch>
          <Route path={`${path}/products`} component={Products} />
          <Route path={`${path}/widget-properties`} component={Properties} />
          <Route path={`${path}/institutions`} component={Institutions} />
        </Switch>
      </div>
    </div>
  );
};

export default EditBuilder;

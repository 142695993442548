import { createContext, useContext, useReducer, useMemo } from 'react';
import { api } from 'config';
import { createData, readData, clearData } from 'helpers';

const CompanyContext = createContext();

const companyReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE': {
      const { data } = action;
      return { ...data };
    }
    case 'REMOVE': {
      return {};
    }
    default: {
      return state;
    }
  }
};

const CompanyProvider = (props) => {
  const [state, dispatch] = useReducer(companyReducer, {}, () => {
    const localData = localStorage.getItem('company');
    return localData ? JSON.parse(localData) : {};
  });
  const value = useMemo(() => [state, dispatch], [state]);
  return <CompanyContext.Provider value={value} {...props} />;
};

const useCompany = () => {
  const context = useContext(CompanyContext);
  if (!context) {
    throw new Error(`useCompany must be used within a CompanyProvider`);
  }
  const [state, dispatch] = context;
  const updateCompanyObj = (obj) => {
    const company = readData('company') || {};
    const newCompany = { ...company, ...obj };
    createData('company', newCompany);
    dispatch({ type: 'UPDATE', data: newCompany });
    return { status: 'success', data: newCompany };
  };
  const remove = () => {
    clearData('company');
    dispatch({ type: 'REMOVE' });
  };
  const updateCompany = (obj) => {
    return api({ url: 'company/update', body: obj })
      .then((res) => {
        if (res.status === 'success') {
          return updateCompanyObj(res.data);
        }
        return {
          status: 'error',
          message: 'Error updating company, please try again',
        };
      })
      .catch(() => {
        return {
          status: 'error',
          message: 'Error updating company, please try again',
        };
      });
  };
  const resendDirectorLink = (obj) => {
    return api({ url: 'company/director/send', body: obj })
      .then((res) => {
        return res;
      })
      .catch(() => {
        return {
          status: 'error',
          message: 'Error Sending Link, please try again',
        };
      });
  };
  return {
    company: state,
    updateCompanyObj,
    remove,
    updateCompany,
    resendDirectorLink,
  };
};

export { CompanyProvider, useCompany };

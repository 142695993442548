export const getErrorMsg = (error = {}) => {
  return error.response
    ? error.response.data.message
    : 'An error has occured. Please try again.';
};

export const getPillVariant = {
  cancelled: 'neutral',
  completed: 'success',
  error: 'critical',
  failed: 'critical',
  'in-progress': 'warning',
  'success-pending-validation': 'warning',
  pending: 'info',
  'no-consent': 'tertiary',
  review: 'warning',
  success: 'success',
  verified: 'success',
};

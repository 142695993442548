const _abbreviate = (number, decPlaces) => {
  decPlaces = 10 ** decPlaces;
  const units = ['k', 'm', 'b', 't'];
  for (let i = units.length - 1; i >= 0; i--) {
    const size = 10 ** ((i + 1) * 3);
    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;

      if (number === 1000 && i < units.length - 1) {
        number = 1;
        i++;
      }

      number += units[i];

      break;
    }
  }
  return number;
};

export const abbreviateNumber = (number, decPlaces) => {
  if (Number.isNaN(number) || !number) return 0;

  const isNegative = number < 0;
  const abbreviatedNumber = _abbreviate(Math.abs(number), decPlaces || 1);
  return isNegative ? `-${abbreviatedNumber}` : abbreviatedNumber;
};

export const checkValidOrZero = (value) => {
  return value || value === 0;
};

import { clearData, readData } from 'helpers';
import urls, { env } from './urls';
import environments from './environments';

// const env = process.env.REACT_APP_ENV || 'dev';
const { SANDBOX } = environments;

const api = async ({
  externalUrl,
  url,
  method = 'POST',
  body,
  token,
  signal,
  formData,
}) => {
  try {
    const headers = {};
    const params = { method };
    const auth = readData('auth');

    const user = auth && auth;
    const savedToken = user?.token;

    if (body) {
      params.body = formData ? body : JSON.stringify(body);
    }

    if (signal) {
      params.signal = signal;
    }

    if (!formData) {
      headers['Content-Type'] = 'application/json';
    }

    if (savedToken || token) {
      headers.Authorization = `Bearer ${token || savedToken}`;
    }

    if (env === SANDBOX && !externalUrl) {
      headers.okra = process.env.REACT_APP_OKRA_KEY;
    }

    params.headers = headers;

    const response = await fetch(externalUrl || urls.api_url + url, params);
    const data = await response.json();

    if (data.logout) {
      // logout
      clearData('auth');
      clearData('company');
      clearData('sla');
      clearData('banks');
      clearData('ownAuth');
      clearData('ownSla');
      clearData('ownCompany');
      window.location.href = '/login';
      return { status: false, message: data.message };
    }

    return data;
  } catch (err) {
    if (err.name === 'AbortError') {
      return 'Request Aborted ';
    }
    return { status: false };
  }
};

export default api;

import { useState, useEffect } from 'react';
import { api } from 'config';
import { useAuth } from 'store';

import { useToaster, Button, HorizontalStepper, Modal, Icon } from 'okraui-ts';
import OkraLogo from 'assets/Images/OkraFullLogo.png';

import { readData, createData, clearData } from 'helpers';
import BusinessInformation from './BusinessInformation';
import RegistrationDoc from './RegistrationDocument';
import ProjectSummary from './ProjectSummary';
import VerificationSummary from './VerificationSummary';

export const SignoutModal = ({ active, onClose, nextRouteLink = null }) => {
  const { logoutAll } = useAuth();

  return (
    <Modal active={active} type="bare" modalClass="onboarding-close-modal">
      <Icon onClick={onClose} name="close" className="close-icon" size="sm" />
      <div className="warning-icon">
        <Icon name="warningIcon" />
      </div>

      <h5 className="semibold">Sign out </h5>

      <p className="body2">
        If you sign out now, any progress you've made will be lost. Are you sure
        you want to continue?
      </p>

      <Button
        variant="destructive"
        onClick={() => {
          clearData('onboarding');
          logoutAll({ nextRouteLink });
        }}
      >
        Sign out
      </Button>
    </Modal>
  );
};

const pages = [
  {
    page: BusinessInformation,
    name: 'Company',
    value: 'business-information',
  },
  {
    page: RegistrationDoc,
    name: 'Documents',
    value: 'registration-document',
  },
  { page: ProjectSummary, name: 'Business', value: 'project-summary' },
  {
    page: VerificationSummary,
    name: 'Summary',
    value: 'verification-summary',
  },
];
let store = readData('onboarding');
if (!store) store = {};

const Onboarding = ({ location, history }) => {
  const { open } = useToaster();
  const { user, token } = useAuth();
  const [position, setPosition] = useState(store.positon || 0);
  const [passed, setPassed] = useState(store.passed || []);
  const [countries, setCountries] = useState([]);
  const [showSignoutModal, setShowSignoutModal] = useState(false);
  const companyInfo = store.company || {
    country: '',
    name: '',
    industry: '',
    rc_number: '',
    tin_number: '',
    website: '',

    certOfIncorp: '',
    statusReport: '',
    fileNames: null,

    app_name: '',
    purpose: '',

    rc_info: '',
    rc_verified: false,
    migration_type: 'onboarding',
  };

  const [company, setCompany] = useState({
    ...companyInfo,
    owner: user.id || user._id,
    token,
  });

  useEffect(() => {
    const getCountries = async () => {
      const countries = await api({
        url: 'company/countries/list',
        method: 'GET',
      });
      if (countries.status === true || countries.status === 'success') {
        const countriesArray = countries.data.map((c) => {
          return { label: c.name, value: c._id };
        });
        setCountries(countriesArray);
      }
      return true;
    };
    getCountries();
  }, []);

  const handlePageSwitch = (i, type, data = {}) => {
    let newPosition = 0;
    let passedPosition = 0;
    let passedValues = passed;

    if (i && !Number.isNaN(i)) {
      newPosition = i;
    }

    if (type === 'forward' && position < pages.length - 1) {
      newPosition = position + 1;
      passedPosition = position;
    }

    if (type === 'backwards' && position > 0) {
      newPosition = position - 1;
    }

    if (passedValues.length === 0) {
      passedValues = [0];
    } else {
      passedValues = [...passed, passedPosition];
    }

    setPassed(passedValues);
    setPosition(newPosition);

    createData('onboarding', {
      passed: passedValues,
      position: newPosition,
      company: { ...company, ...data },
    });

    const strNewPath = `/onboarding/${pages[newPosition].value || ''}`;
    if (window.history.pushState)
      window.history.pushState({}, null, strNewPath);
  };

  useEffect(() => {
    const { pathname } = location;
    const urlPage = pathname && pathname.split('/') && pathname.split('/')[2];
    if (urlPage) {
      const isAllowed = (position) => {
        let status = false;
        if (position === 0 || position === 1) status = true;
        if (passed.includes(position)) status = true;
        return status;
      };
      const findPosition = pages.map((obj) => obj.value).indexOf(urlPage);
      let pos = 0;
      if (findPosition >= 0) {
        const allowed = isAllowed(findPosition);
        if (allowed) {
          pos = findPosition;
          setPosition(findPosition);
        } else {
          setPosition(0);
        }
      } else {
        setPosition(0);
      }
      const strNewPath = `/onboarding/${pages[pos].value || ''}`;
      if (window.history.pushState)
        window.history.pushState({}, null, strNewPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setCompany((prevCompany) => ({
      ...prevCompany,
      [name]: value,
    }));
  };
  const multiChange = (obj) => {
    setCompany((prevCompany) => ({
      ...prevCompany,
      ...obj,
    }));
  };

  return (
    <div className="onboarding-container">
      <SignoutModal
        active={showSignoutModal}
        onClose={() => setShowSignoutModal(false)}
      />
      <div className="onboarding-container__header">
        <img src={OkraLogo} alt="Okra Logo" />
        <div className="stepper-wrapper">
          <HorizontalStepper
            steps={pages}
            position={position}
            passed={passed}
          />
          <div className="mobile-stepper">
            <p>
              {position + 1} of {pages.length}{' '}
            </p>
          </div>
        </div>
        <div className="divider" />
        <Button
          variant="secondary-text"
          onClick={() => setShowSignoutModal(true)}
        >
          Sign out
        </Button>
      </div>
      <div className="onboarding-pages">
        {pages.map(({ page: Page, value }, i) => {
          if (i === position) {
            return (
              <Page
                key={`page-${value}`}
                onPageChange={handlePageSwitch}
                onChange={onChange}
                company={company}
                open={open}
                multiChange={multiChange}
                countries={countries}
                name={user.firstname || user.lastname}
                email={user.email}
                history={history}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default Onboarding;

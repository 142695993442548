import React, { useEffect, useState } from 'react';
import { Modal } from 'okraui-ts';

import { WifiIcon } from 'assets';

const expPages = [
  'login',
  'legacy-signup',
  'verify',
  'accept-invite',
  'social_link',
  'forgot',
  'secure_login',
  'reset',
  'onboarding',
];

const checkExp = () => {
  const path = window && window.location && window.location.pathname;
  if (path) {
    const firstPath = path.split('/');
    if (
      (firstPath && (expPages.includes(firstPath[1]) || firstPath[1] === '')) ||
      window.location.href === 'http://localhost/'
    ) {
      return true;
    }
    return false;
  }
  return false;
};
const NetworkNotification = () => {
  const [online, setOnline] = useState(window.navigator.onLine);
  useEffect(() => {
    const updateNetwork = () => {
      if (!checkExp()) setOnline(window.navigator.onLine);
    };
    window.addEventListener('online', updateNetwork);
    window.addEventListener('offline', updateNetwork);

    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  });

  return (
    <Modal active={!online} type="bare" modalClass="internet-notification">
      <div className="icon">
        <WifiIcon />
      </div>
      <p className="semibold dark">You are not connected to the internet</p>
      <p>Double-check your internet connection and try again.</p>
    </Modal>
  );
};

export default NetworkNotification;

import * as React from 'react';

export const SlackMonoIcon = (props) => {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.492 7.346h1.749a1.399 1.399 0 11-.027 2.799H8.762a1.4 1.4 0 11-.008-2.8c.58.002 1.16.002 1.738.001zM3.485 6.632H1.736A1.401 1.401 0 01.362 4.953a1.402 1.402 0 011.4-1.12h3.451a1.391 1.391 0 011.36 1.005 1.344 1.344 0 01-.286 1.314 1.36 1.36 0 01-1.048.481H3.485zM7.345 3.486V1.732A1.402 1.402 0 018.526.35a1.404 1.404 0 011.62 1.411v3.452a1.405 1.405 0 01-1.11 1.39 1.406 1.406 0 01-1.531-.724 1.417 1.417 0 01-.158-.656c0-.58 0-1.159-.002-1.737zM6.631 10.493v1.758a1.399 1.399 0 01-2.784.21 1.565 1.565 0 01-.017-.234V8.763a1.4 1.4 0 112.799-.006l.002 1.736zM7.345 11.61v-.721c0-.033.008-.043.042-.043.467 0 .934-.005 1.4 0 .34.004.665.137.91.373a1.398 1.398 0 11-2.35 1.013l-.002-.622zM6.631 2.368v.721c0 .035-.01.042-.043.042-.466 0-.933.004-1.4 0a1.331 1.331 0 01-.908-.374 1.35 1.35 0 01-.438-.871A1.358 1.358 0 014.17.826c.205-.248.493-.414.81-.466a1.356 1.356 0 011.26.408 1.35 1.35 0 01.39.975v.625zM11.61 6.633h-.72c-.037 0-.045-.011-.045-.045V5.206c.001-.342.133-.671.37-.919a1.365 1.365 0 01.975-.45 1.398 1.398 0 11.04 2.795h-.62zM3.132 8.104v.661a1.409 1.409 0 01-1.148 1.357A1.403 1.403 0 01.362 9.019a1.4 1.4 0 011.384-1.674H3.09c.038 0 .046.01.046.047-.005.238-.004.475-.004.712z"
        fill="#627490"
      />
    </svg>
  );
};

import cx from 'classnames';

const ProductInfoScreen = ({ className, icon, title, description }) => {
  return (
    <div className={cx(['product-info-container', className])}>
      <div className="content-container">
        <div className="product-info__illustration">{icon}</div>
        <p className="body2 semibold">{title}</p>
        <p className="body3">{description}</p>
      </div>
    </div>
  );
};

export default ProductInfoScreen;

import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

const TRACKING_ID = 'UA-149617011-1'; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const RouteChangeTracker = ({ history }) => {
  const isGAEnabled = process.env.REACT_APP_ENV || 'production';
  history.listen((location) => {
    if (isGAEnabled) {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }
  });

  return <div></div>;
};

export default withRouter(RouteChangeTracker);

import { useCallback, useMemo } from 'react';
import { useBanks, useBuilder } from 'store';
import { BankCover, Icon, SearchField } from 'okraui-ts';
import clx from 'classnames';

import BaseScreen from './BaseScreen';
import { formatBanks, fugaz } from '../../data/Functions';

const InstitutionScreen = () => {
  const { builder, setBuilder } = useBuilder();
  const { banks } = useBanks();
  const { institutions, filter, institutions_type } = builder;

  const defaultInstitutionList = banks.filter((b) => fugaz.includes(b.slug));

  const customInstitutionList = useCallback(() => {
    const filteredBanks = filter?.banks || [];
    return formatBanks(banks, institutions, null, filteredBanks, setBuilder);
  }, [banks, filter?.banks, institutions, setBuilder]);

  const institutionsToRender = useMemo(() => {
    return institutions_type === 'custom'
      ? customInstitutionList()
      : defaultInstitutionList;
  }, [institutions_type, customInstitutionList, defaultInstitutionList]);

  return (
    <BaseScreen className="institution-view-screen">
      <h4 className="bold">Select your account type</h4>

      <div>
        <SearchField
          onChange={() => {}}
          value=""
          placeholder="Find your bank"
        />

        <div className="institution-types-options">
          {institutionsToRender.map((bank, index) => {
            return (
              <div
                className={clx('institution-types-options__item', {
                  'show-border': institutionsToRender.length - 1 !== index,
                })}
              >
                <BankCover bank={bank} />
                <Icon name="chevronRight" size="sm" color="#627490" />
              </div>
            );
          })}
        </div>
      </div>
    </BaseScreen>
  );
};

export default InstitutionScreen;

export const Pagination = (totalDocsArray, page, searchValue, limit) => {
  let totalDocs;
  if (searchValue !== '') {
    totalDocs = totalDocsArray;
  }
  totalDocs = totalDocsArray.filter((doc) => {
    return doc.name.includes(searchValue);
  });

  const hasPrevPage = () =>
    page <= 1 ? false : totalDocs.length > (page - 1) * limit - limit;
  const hasNextPage = () => totalDocs.length > page * limit;
  const totalPages = Math.ceil(totalDocs.length / limit);
  const prevPage = hasPrevPage() ? page - 1 : null;
  const nextPage = hasNextPage() ? page + 1 : null;

  return {
    totalDocs: totalDocs?.length,
    limit,
    hasPrevPage,
    hasNextPage,
    page,
    totalPages,
    pagingCounter: 1,
    prevPage,
    nextPage,
  };
};

export const handleDocsFilter = (totalDocsArray, searchValue, page, limit) => {
  let docs;
  if (searchValue !== '') {
    docs = totalDocsArray;
  }
  docs = totalDocsArray.filter((doc) => {
    return doc.name.includes(searchValue);
  });

  return docs.filter((_, index) => {
    const startFrom = page * limit - limit;
    const endAt = page * limit - 1;
    return index >= startFrom && index <= endAt;
  });
};

export const getTotalFilterCount = (values) => {
  const filters = values;

  delete filters.endDate;
  delete filters.page;

  return Object.values(filters)
    .map((a) => {
      if (Array.isArray(a)) {
        return a.length > 0 ? 1 : null;
      }
      return a;
    })
    .filter(Boolean).length;
};

import { EmailSucess } from 'assets';
import OkraLogo from 'assets/Images/OkraFullLogo.png';

const RegisterSuccess = (props) => {
  const { location } = props;
  const email = location?.state?.email;
  if (!email) props.history.push('/register');
  return (
    <div className="auth spaced register-success">
      <img src={OkraLogo} alt="Okra Logo" className="auth__logo" />

      <div className="pending-content">
        <EmailSucess className="auth__success-logo" />
        <h4 className="bold">Account created</h4>
        <p className="center-text">
          Thank you for signing up. Please check your email at{' '}
          <span className="email"> {email}</span> to verify your account.
        </p>
      </div>
    </div>
  );
};

export default RegisterSuccess;

import * as React from 'react';

export const DocsIcon = (props) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 1H4a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2V3a2 2 0 00-2-2zM4 1v14"
        stroke="#627490"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 7.5l-2-2-2 2V1h4v6.5z"
        stroke="#627490"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import { Button, TextField, Radio } from 'okraui-ts';
import { api } from 'config';

const BusinessInformation = ({
  onPageChange,
  onChange,
  company,
  open,
  multiChange,
  email: username,
  countries: dataCountries,
}) => {
  const history = useHistory();
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    setCountries(dataCountries);
  }, [dataCountries]);
  const { country, name, rc_number, tin_number, website, rc_verified } =
    company;

  const [loading, setLoading] = useState(false);
  const [isRCValid, setisRCValid] = useState(false);
  const [isRCValidMsg, setIsRCValidMsg] = useState('');

  const submit = async () => {
    // do more
    if (!country || !name || !rc_number || !website) {
      open({
        active: true,
        message: 'Please fill all the required fields',
        status: 'error',
      });
      return;
    }
    const isCorrectRC = /^[a-z0-9-]+$/i.test(rc_number);
    if (!isCorrectRC) {
      open({
        active: true,
        message: 'Invalid RC number',
        status: 'error',
      });
      setIsRCValidMsg('Invalid RC number');
      return;
    }
    if (!validator.isURL(website)) {
      open({
        active: true,
        message: 'Invalid website, please confirm and try again',
        status: 'error',
      });
      return;
    }
    if (!rc_verified) {
      setLoading(true);
      const checkRC = await api({
        url: 'company/rc-verification',
        body: {
          verify: true,
          country,
          rc_number,
          tin_number,
          company_name: name,
          username,
        },
      });
      setLoading(false);
      setisRCValid(false);
      if (checkRC.status === 'success') {
        if (checkRC.message === 'invited') {
          const company = checkRC.data;
          history.push('/pending-invite', { type: 'exists', company });
        } else {
          multiChange({
            rc_verified: true,
            name: checkRC?.data?.details?.company_name,
            rc_info: checkRC?.data,
          });
          onPageChange(null, 'forward', {
            rc_verified: true,
            name: checkRC?.data?.details?.company_name,
            rc_info: checkRC?.data,
          });
        }
      } else {
        setIsRCValidMsg(
          checkRC.message || 'RC number invalid, please try again'
        );
        setisRCValid(true);
        open({
          active: true,
          message: checkRC.message || 'RC number invalid, please try again',
          status: 'error',
        });
      }
    } else {
      onPageChange(null, 'forward');
    }
  };
  const enabled = useMemo(() => {
    return rc_number && country && website && name;
  }, [rc_number, country, website, name]);

  return (
    <div className="onboarding-layout__inner">
      <div className="onboarding-layout__inner-right">
        <h4 className="semibold">Company Information</h4>
        <p className="body2">
          Please provide your company information so we can verify your details
          to ensure Okra's safety and security.{' '}
        </p>
      </div>
      <div className="onboarding-layout__inner-left">
        <div className="onboarding-layout__inner-form">
          <TextField
            label="Registered company name"
            value={name}
            name="name"
            type="text"
            placeholder="Enter company name"
            onChange={onChange}
            required
            disabled={rc_verified}
          />

          <TextField
            label="RC number"
            value={rc_number}
            name="rc_number"
            type="text"
            onChange={onChange}
            placeholder="11192863"
            required
            disabled={rc_verified}
            max={10}
            hasError={isRCValid}
            footnote={isRCValid ? isRCValidMsg : null}
          />

          <TextField
            label="Tax identification number (TIN) (Optional)"
            value={tin_number}
            placeholder="Enter number"
            name="tin_number"
            type="text"
            onChange={onChange}
          />

          <TextField
            label="Company website"
            value={website}
            placeholder="https://www.website.com"
            name="website"
            type="text"
            onChange={onChange}
            required
          />

          <div className="radio-group">
            <label className="radio-group__title">Country of operation</label>
            <div className="radio-group__item">
              {countries.map((c) => {
                return (
                  <Radio
                    onChange={onChange}
                    checked={c.value === country}
                    value={c.value}
                    name="country"
                    label={c.label}
                    key={c.value}
                  />
                );
              })}
            </div>
          </div>

          <Button
            type="submit"
            variant="primary"
            onClick={submit}
            loading={loading}
            disabled={!enabled}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BusinessInformation;

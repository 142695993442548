import { useParams } from 'react-router-dom';
import { PageLoader, useToaster } from 'okraui-ts';
import { useEffect } from 'react';
import { api } from 'config';

const VerifyInvite = (props) => {
  const { token } = useParams();
  const { open } = useToaster();
  if (!token) props.history.push('/');
  useEffect(() => {
    if (token) {
      api({
        url: 'auth/approve-invite',
        body: { token },
      }).then((res) => {
        if (res.status === 'success') {
          props.history.push('/');
          open({
            active: true,
            message: res.message || 'Successfully added to the team',
            status: 'success',
          });
        } else {
          open({
            active: true,
            message:
              res.message ||
              'There was an error approving the invite, try again',
            status: 'error',
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  return <PageLoader />;
};

export default VerifyInvite;

import { useState } from 'react';

import { api } from 'config';
import { useCompany } from 'store/Company';
import { useToaster } from 'okraui-ts';

import { getErrorMsg } from 'helpers';

import Hero from './Hero';
import Features from './Features';
import Footer from './Footer';

import RequestAccess from './RequestAccess';

const NewUserView = () => {
  const { open } = useToaster();
  const { company } = useCompany();

  const {
    cac_1_1,
    cac_2,
    cac_7,
    reg_application,
    operating_proof,
    gov_license,
  } = company || {};

  const getCacStatus = () => {
    const cacItems = [cac_1_1, cac_2, cac_7, reg_application];

    if (cacItems.some((item) => item && item.status === 'pending')) {
      return 'pending';
    }

    if (cacItems.some((item) => item && item.status === 'rejected')) {
      return 'failed';
    }

    if (cacItems.some((item) => item && item.status === 'approved')) {
      return 'verified';
    }

    return '';
  };

  const getAddressStatus = () => {
    if (operating_proof && operating_proof.status === 'pending') {
      return 'pending';
    }

    if (operating_proof && operating_proof.status === 'rejected') {
      return 'failed';
    }

    if (operating_proof && operating_proof.status === 'approved') {
      return 'verified';
    }

    return '';
  };

  const getMainlicenseStatus = () => {
    if (gov_license && gov_license.status === 'pending') {
      return 'pending';
    }

    if (gov_license && gov_license.status === 'rejected') {
      return 'failed';
    }

    if (gov_license && gov_license.status === 'approved') {
      return 'verified';
    }

    return '';
  };

  const [requestLoader, setRequestLoader] = useState(false);
  const [isRequestAccessActive, toggleRequestAccess] = useState(false);

  const requestPayment = async () => {
    setRequestLoader(true);
    try {
      const { data } = await api({
        url: 'company/request',
        body: {
          page: 'payment',
        },
      });

      setRequestLoader(false);
      open({
        active: true,
        message: data?.msg || 'Payment request successfully sent!',
        status: 'success',
      });
    } catch (error) {
      const errorMsg = getErrorMsg(error);

      setRequestLoader(false);
      open({
        active: true,
        message: errorMsg,
        status: 'error',
      });
    }
  };

  return (
    <div className="payments-overview payments-overview__new">
      <Hero
        requestExists={getCacStatus() || getAddressStatus()}
        requestLoader={requestLoader}
        requestPayment={() => toggleRequestAccess(true)}
      />
      <Features />
      <Footer
        requestExists={getCacStatus() || getAddressStatus()}
        requestLoader={requestLoader}
        requestPayment={() => toggleRequestAccess(true)}
      />

      <RequestAccess
        active={isRequestAccessActive}
        toggleRequestAccess={toggleRequestAccess}
        submitRequest={requestPayment}
        getCacStatus={getCacStatus}
        getAddressStatus={getAddressStatus}
        getMainlicenseStatus={getMainlicenseStatus}
      />
    </div>
  );
};

export default NewUserView;

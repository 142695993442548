import { useAuth } from 'store';
import { routes } from 'config';

const Onboarding = ({ component, redirect: Redirect }) => {
  const { subLoggedIn, user } = useAuth();
  const verify = user.account_verified;

  if (subLoggedIn) {
    if (verify) {
      return <Redirect to={routes.dashboard.home} />;
    }
    return component;
  }
  return <Redirect to="/" />;
};

export default Onboarding;

import * as React from 'react';

export const ApiReferenceIcon = (props) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#clip0_17432_17097)"
        stroke="#627490"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M5 1H2a1 1 0 00-1 1v12a1 1 0 001 1h3a1 1 0 001-1V2a1 1 0 00-1-1z"
          strokeWidth={1.5}
        />
        <path
          d="M3.45 3.71h.1M1 12.14h5M10.93 1.819l-2.897.776a1 1 0 00-.707 1.225l2.588 9.66a1 1 0 001.225.706l2.897-.776a1 1 0 00.707-1.225l-2.588-9.66a1 1 0 00-1.224-.706zM9.39 11.54l4.83-1.29"
          strokeWidth={1.2}
        />
        <path d="M7.58 4.78l4.83-1.29M8.1 6.72l4.83-1.3" strokeWidth={1.5} />
      </g>
      <defs>
        <clipPath id="clip0_17432_17097">
          <path fill="#fff" d="M0 0H16V16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

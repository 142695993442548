import React from 'react';
import PropTypes from 'prop-types';

const DisplaySearchResult = ({ row, icon, moveTo, icons, index }) => {
  const { title, location } = row;

  return (
    <div
      className="search-group-row cursor"
      key={`row-${index}`}
      onClick={() => moveTo(title, icon, location)}
    >
      <div>
        <i className="search-group-row-label">{icons[icon] || icons.pages}</i>
        <p>{title}</p>
      </div>
      <i className={`search-group-row-action fa fa-arrow-right`}></i>
    </div>
  );
};

const DisplaySearchHighlight = ({
  resultKey,
  row,
  moveTo,
  icons,
  reactHtmlParser,
}) => {
  const hightlights = Object.entries(row.highlight);

  return hightlights.map(([key, value], idx) => {
    const name = value[0];
    const icon = key === 'name' ? resultKey : key;
    const location = resultKey;
    const id =
      resultKey === 'transactionsets' ? row._source?.account?._id : row._id;

    return (
      <div
        className="search-group-row cursor"
        key={`row-inner-${idx}`}
        onClick={() => moveTo(name, icon, location, id)}
      >
        <div>
          <i className="search-group-row-label">{icons[key] || icons.pages}</i>
          <p>{reactHtmlParser(name)}</p>
        </div>
        <i className={`search-group-row-action fa fa-arrow-right`}></i>
      </div>
    );
  });
};

const DisplaySearchArrow = ({ index, row, key, icons, moveTo }) => {
  const { _id } = row;

  return (
    <div
      className="search-group-row cursor"
      key={`row-inner-${index}`}
      onClick={() => moveTo(_id, key, key, _id)}
    >
      <div>
        <i className={`search-group-row-label fa fa-${icons[key]}`}></i>
        <p>{_id}</p>
      </div>
      <i className={`search-group-row-action fa fa-arrow-right`}></i>
    </div>
  );
};

const DisplayResults = ({
  searchResult,
  moveTo,
  icons = {},
  reactHtmlParser,
}) => {
  const output = [];
  const searchResults = Object.entries(searchResult);

  searchResults.forEach(([key, value], idx) => {
    if (value?.docs?.length > 0) {
      output.push(
        <div className="search-group" key={`group-${idx}`}>
          <p className="search-group-title">
            {key === 'transactionsets' ? 'Transaction' : key}
          </p>
          {value.docs.map((row, idx) => {
            if (key === 'pages') {
              return (
                <DisplaySearchResult
                  icon={key}
                  icons={icons}
                  index={idx}
                  key={`${key}-${idx}`}
                  moveTo={moveTo}
                  row={row}
                />
              );
            }

            if (row.highlight) {
              return (
                <DisplaySearchHighlight
                  icons={icons}
                  key={key}
                  moveTo={moveTo}
                  reactHtmlParser={reactHtmlParser}
                  row={row}
                />
              );
            }

            return (
              <DisplaySearchArrow
                icons={icons}
                index={idx}
                key={key}
                moveTo={moveTo}
                row={row}
              />
            );
          })}
        </div>
      );
    }
  });

  return output;
};

DisplayResults.propTypes = {
  icons: PropTypes.object,
  moveTo: PropTypes.func,
  reactHtmlParser: PropTypes.func,
  searchResult: PropTypes.object,
};

export default DisplayResults;

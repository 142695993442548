export const fugaz = [
  'first-bank-of-nigeria',
  'united-bank-for-africa',
  'guaranty-trust-bank',
  'access-bank',
  'zenith-bank',
  'ecobank-nigeria',
  'stanbic-ibtc-bank',
  'first-city-monument-bank',
];

export const parseSelected = (banks, selectedBanks) => {
  return selectedBanks.map((ins_slug) => {
    if (ins_slug) {
      const bank = banks.find(({ slug }) => ins_slug === slug);
      if (bank) return bank;
    }
    return null;
  });
};

export const formatBanks = (
  banks,
  institutions,
  type,
  filterBanks,
  setBuilder
) => {
  let remainingBanks = [];

  if (!institutions?.length) {
    setBuilder?.('institutions', fugaz);
  }
  if (!filterBanks?.length) {
    const allBanksSlug = banks.map((v) => v.slug);
    remainingBanks = allBanksSlug.filter(
      (bnk) => !institutions.includes(bnk.slug)
    );
    setBuilder?.('filter', {
      industry_type: '',
      banks: allBanksSlug,
    });
  } else {
    // pick from filteredBanks
    remainingBanks = filterBanks.filter((slug) => !institutions.includes(slug));
  }
  const selectedBanks =
    institutions?.length && (type === 'grid' || !type)
      ? institutions
      : type === 'list'
      ? [...institutions, ...remainingBanks]
      : fugaz;
  const preselectedBanks = parseSelected(banks, selectedBanks);
  return preselectedBanks;
};

let mozillaClientX = 0;
let mozillaClientY = 0;

export const handleDrag = ({ target, clientX, clientY }) => {
  const selectedItem = target;
  const list = selectedItem.parentNode;
  const x = clientX || mozillaClientX;
  const y = clientY || mozillaClientY;

  let swapItem =
    document.elementFromPoint(x, y) === null
      ? selectedItem
      : document.elementFromPoint(x, y);

  if (list === swapItem.parentNode) {
    swapItem =
      swapItem !== selectedItem.nextSibling ? swapItem : swapItem.nextSibling;
    list.insertBefore(selectedItem, swapItem);
  }
};

export const handleDragOver = ({ clientX, clientY }) => {
  mozillaClientX = clientX;
  mozillaClientY = clientY;
};

import { TextField } from 'okraui-ts';

import { useBuilder } from 'store';

const Options = () => {
  const { builder, setBuilder } = useBuilder();
  const { callback_url, exp } = builder;

  const url = callback_url;

  const addZeroToDate = (value) => (value < 10 ? `0${value}` : value);

  const currentDate = new Date();
  const tomorrow = new Date(currentDate.setDate(currentDate.getDate() + 1));

  const dateString = `${tomorrow.getFullYear()}-${addZeroToDate(
    tomorrow.getMonth() + 1
  )}-${addZeroToDate(tomorrow.getDate())}`;

  return (
    <>
      <div className="product-section-info">
        <p className="medium body3">Settings</p>
        <p className="subtitle body3">
          Set the properties that help your widget work perfectly.
        </p>
      </div>
      <div className="product-section settings">
        <div className="form-control">
          <TextField
            label="Callback URL (Optional)"
            labelTooltip="Callback URL receives the data of an event that occurs in your Okra account."
            placeholder="https://example.com/callback"
            value={url}
            onChange={({ target }) => {
              setBuilder('callback_url', target.value);
            }}
          />
        </div>

        <div>
          <TextField
            label="Add expiry date (Optional)"
            type="date"
            placeholder="Jun 11, 2021"
            value={exp}
            min={dateString}
            onChange={({ target }) => {
              setBuilder('exp', target.value);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Options;

import { useState, useEffect } from 'react';
import { useToaster, Card, TextField, Button } from 'okraui-ts';
import OkraLogo from 'assets/Images/OkraFullLogo.png';

import { useAuth } from 'store';

const TwoFactor = (props) => {
  const { access } = useAuth();
  const { open } = useToaster();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [code, setCode] = useState('');
  useEffect(() => {
    const { location } = props;
    const user = location?.state?.email;
    if (!user) props.history.push('/login');
    const scribbleArray = user && user.split('@');
    const scribble =
      user && `${user[0]}*********@${scribbleArray[scribbleArray.length - 1]}`;
    setUser({ value: user, scribble });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { status, message, data } = await access('auth/verify-2fa', {
      user: user.value,
      code,
    });
    if (data || message) setLoading(false);
    if (status !== 'success') {
      open({
        active: true,
        title: 'Invalid Code',
        message:
          message || `This code looks good, but it's not from us. Check again.`,
        status: 'error',
      });
    }
  };

  return (
    <div className="auth login-layout">
      <img src={OkraLogo} alt="Okra Logo" className="auth__logo" />
      <Card>
        <form onSubmit={handleSubmit}>
          <h4 className="auth__title">Enter verification code.</h4>
          <p className="auth__subtitle">
            Please enter the OTP you received to {user.scribble}
          </p>
          <TextField
            label="OTP"
            placeholder="Enter OTP"
            type="tel"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
          <Button
            type="submit"
            variant="primary"
            loading={loading}
            disabled={!code}
          >
            Confirm Code
          </Button>
        </form>
      </Card>
    </div>
  );
};

export default TwoFactor;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, Suspense } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { api, routes } from 'config';
import clsx from 'classnames';

import { Pill, useToaster } from 'okraui-ts';

import { useAuth, useCompany } from 'store';

import { generateObjectId } from 'helpers';
import {
  GlobalSearch,
  NoProject,
  SupportModal,
  SideNav,
  TopBar,
} from 'components';
import { useScrollToTop } from 'hooks';
import { menu, sections, findPage } from './SideNavData';

const DashLayout = ({ children, type }) => {
  const location = useLocation();
  const history = useHistory();

  const isFullLayout = type === 'full-dashboard';

  const defaultPage = findPage(location.pathname);
  const [isSideNavActive, toggleSideNav] = useState(false);
  const [isSearchActive, toggleSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(defaultPage);
  const [showSupportModal, setShowSupportModal] = useState(false);

  const { user, logout, loggedIn, logoutAll, updateUser, updateUserObj } =
    useAuth();

  const { open } = useToaster();
  const { company, updateCompanyObj } = useCompany();

  const ownRoles = (user && user.roles) || [];
  // REMOVE THIS AFTER LOCAL DEVELOPMENT
  const roles = [...ownRoles, 'all'];

  useScrollToTop();
  const SLA = company?.signed_sla;

  useEffect(() => {
    const { pathname } = location;
    setPage(findPage(pathname));
  }, [location]);

  const toggle = async ({ target }) => {
    const { checked } = target;
    setLoading(true);
    const response = await updateUser({
      mode: checked ? 'production' : 'production-sandbox',
    });
    setLoading(false);
    open({
      active: true,
      message:
        response.status === 'success'
          ? checked
            ? 'Environment switched to production'
            : 'Environment switched to Sandbox'
          : 'Could not switch enviroment, please try again',
      status: response.status || 'error',
    });
    // thit timeout enables the toast notification to be on screen long enough for user to see information relative to transitioning environments
    return setTimeout(() => window.location.reload(), 1500);
  };

  const toggleEnvironment = async (envState) => {
    if (envState === user?.mode) return;

    setLoading(true);

    const response = await updateUser({
      mode: envState,
    });
    setLoading(false);

    open({
      active: true,
      message:
        response.status === 'success'
          ? envState === 'production'
            ? 'Environment switched to production'
            : 'Environment switched to Sandbox'
          : 'Could not switch enviroment, please try again',
      status: response.status || 'error',
    });

    // thit timeout enables the toast notification to be on screen long enough for user to see information relative to transitioning environments
    // eslint-disable-next-line consistent-return
    return setTimeout(() => window.location.reload(), 1500);
  };

  const switchProject = (id) => {
    setLoading(true);
    api({
      url: 'project/switch',
      body: { switchTo: id },
    }).then((res) => {
      setLoading(true);
      if (res.status === 'success') {
        const { user, company } = res.data;
        updateUserObj(user);
        updateCompanyObj(company);
        open({
          active: true,
          message: res?.message || 'Switched to project successfully.',
          status: 'success',
        });
        window.location.reload();
      } else {
        open({
          active: true,
          message:
            res?.message ||
            'Cannot switch to this project or project not found',
          status: 'error',
        });
      }
    });
  };

  const createProject = () => {
    history.push(`${routes.settings.business}?new=true`);
  };

  const handleNotifications = () => {
    const messages = [];
    if (!(company?.kyb?.status === 'approved')) {
      messages.push(
        <div className="topbar-notifications-item" key="not-signed-sla">
          <Pill variant="warning">KYB</Pill>
          <h4>Your company's account is pending approval</h4>
          <p>
            Your company's KYB infomation has not been approved. Please visit
            the home page to check the verification status.
          </p>
        </div>
      );
    }
    return messages;
  };

  return (
    <div className="dashboard-layout">
      <SupportModal
        id={generateObjectId()}
        active={showSupportModal}
        close={() => setShowSupportModal(false)}
        className="support-modal__new"
        modalOptions={{
          title: 'Contact support',
          type: 'standard',
          confirmTxt: 'Send Message',
          description:
            'Please be as detailed as possible so that our support team can better understand the issue.',
        }}
        type="support"
        closeTxt="Send Message"
        title="Contact support"
      />

      {loggedIn && user?.project && user?.project._id ? (
        <div className="dashboard-layout-body">
          <GlobalSearch
            active={isSearchActive}
            close={() => toggleSearch(false)}
          />
          <SideNav
            menu={menu}
            sections={sections}
            allowed={roles}
            prod={user.mode === 'production'}
            active={isSideNavActive}
            company={company}
            user={user}
            logoutAll={logoutAll}
            sla={SLA}
            loading={loading}
            toggle={toggle}
            logout={logout}
            switchProject={switchProject}
            createProject={createProject}
            showMobileSideNav={isSideNavActive}
            toggleSideNav={toggleSideNav}
          />
          <div
            className={clsx('dashboard-layout-container', {
              'full-layout': isFullLayout,
            })}
          >
            <Suspense fallback={<div />}>
              <>
                <TopBar
                  user={user}
                  company={company}
                  notifications={handleNotifications()}
                  logout={logout}
                  logoutAll={logoutAll}
                  toggleEnvironment={toggleEnvironment}
                  loading={loading}
                  onContactSupportClick={() =>
                    setShowSupportModal(!showSupportModal)
                  }
                  search={() => toggleSearch(true)}
                  isSideNavActive={isSideNavActive}
                  toggleSideNav={toggleSideNav}
                  page={page}
                  switchProject={switchProject}
                />

                <div className="dashboard-layout-content">
                  <div className="dashboard-page">{children}</div>
                </div>
              </>
            </Suspense>
          </div>
        </div>
      ) : (
        <NoProject
          switchProject={switchProject}
          projects={user?.projects?.length ? user?.projects : null}
          loading={loading}
        />
      )}
    </div>
  );
};

export default DashLayout;

import { Radio, StatusChip, Warning } from 'okraui-ts';
import { useBuilder } from 'store';

const Institutions = () => {
  const { builder, setBuilder } = useBuilder();
  const { institutions_type = 'default' } = builder;
  return (
    <>
      <div className="product-section-info">
        <p className="medium body3">Financial institutions</p>
        <p className="subtitle body3">
          Customize the institutions visible to your customers when they connect
          their bank account(s) to your app.
        </p>
      </div>

      <div className="product-section institution-options">
        <div className="institution-options__item">
          <Radio
            label={
              <>
                <p className="medium body3">
                  Default Institutions
                  <StatusChip variant="info">Recommended</StatusChip>
                </p>
                <p className="body3">
                  These institutions are pre-selected based on product
                  compatibility.
                </p>
              </>
            }
            checked={institutions_type === 'default'}
            onChange={() => {
              setBuilder('institutions_type', 'default');
            }}
          />
        </div>
        <div className="institution-options__item">
          <Radio
            label={
              <>
                <p className="medium body3">Custom Institutions</p>
                <p className="body3">
                  Order and change the institutions visible to your customers.
                </p>
              </>
            }
            checked={institutions_type === 'custom'}
            onChange={() => setBuilder('institutions_type', 'custom')}
          />
        </div>

        {institutions_type === 'custom' && (
          <div className="sub-info colored">
            <div className="colored__icon">
              <Warning />
            </div>
            <p>
              You may experience return of partial data by some institutions.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default Institutions;

import { useEffect } from 'react';

const useOutsideClick = (ref, func, condition = true) => {
  useEffect(() => {
    const action = (e) => {
      if (
        condition &&
        ref.current &&
        ref.current.contains &&
        !ref.current.contains(e.target)
      ) {
        func(e);
      }
    };
    window.addEventListener('click', action);
    return () => window.removeEventListener('click', action);
  }, [condition, ref, func]);
};

export default useOutsideClick;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Avatar from 'assets/Icons/Profile/Avatar.svg';

const ProfileUploader = ({ imageData, sendImage, alt, loading }) => {
  const [imgData, setImgData] = useState(imageData);

  const finished = (result) => {
    sendImage(result);
  };
  const onChange = (event) => {
    const reader = new FileReader();
    try {
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setImgData(reader.result);
        finished(reader.result);
      };
    } catch (err) {
      reader.onerror = (error) => finished(error);
    }
  };

  return (
    <div className="profile-uploader">
      <section className="profile-uploader--avatar">
        <div className="avatar-holder">
          {loading ? (
            <div className="loader">
              <div className="shine" />
            </div>
          ) : (
            <div className="gray-holder">
              <label htmlFor="fileToUpload">
                <div
                  className="pic"
                  style={{ backgroundImage: `url(${imgData || Avatar})` }}
                >
                  <span>{alt}</span>
                </div>
              </label>
              <input
                name="profile_photo"
                placeholder="Photo"
                id="fileToUpload"
                type="file"
                onChange={(event) => onChange(event)}
                accept="image/png, image/jpeg, image/svg"
              />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

ProfileUploader.defaultProps = {
  alt: 'Upload Your Profile Picture',
  sendImage: () => '',
};

ProfileUploader.propTypes = {
  alt: PropTypes.string,
  imageData: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
  sendImage: PropTypes.func,
};
export default ProfileUploader;

import { useState, useMemo, useEffect } from 'react';
import { Button, FileUploaderBasic } from 'okraui-ts';
import { InfoIcon } from 'assets';
import { useWipeableState } from 'hooks';

import { convertDataURIToFile } from 'helpers';

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const RegDoc = ({ onPageChange, multiChange, company }) => {
  const [files, setFiles, wipeFile] = useWipeableState({
    certOfIncorp:
      company?.certOfIncorp &&
      convertDataURIToFile(
        company?.certOfIncorp,
        company?.fileNames?.certOfIncorp
      ),
    statusReport:
      company?.statusReport &&
      convertDataURIToFile(
        company?.statusReport,
        company?.fileNames?.statusReport
      ),
  });

  const [fileDataURIs, setFileDataURI, wipeFileDataURI] = useWipeableState({
    certOfIncorp: null,
    statusReport: null,
  });

  const [fileNames, setFileNames, wipeFileName] = useWipeableState({
    certOfIncorp: company?.fileNames?.certOfIncorp,
    statusReport: company?.fileNames?.statusReport,
  });

  const [errorMessages, setErrorMessages, wipeErrorMesasge] = useWipeableState({
    certOfIncorp: null,
    statusReport: null,
  });

  const [fileChanged, setFileChanged] = useState(false);

  const submit = async () => {
    const certOfIncorp = await toBase64(files?.certOfIncorp);
    const statusReport = await toBase64(files?.statusReport);

    multiChange({
      certOfIncorp,
      statusReport,
      fileNames,
    });

    if (files?.certOfIncorp && files?.statusReport) {
      onPageChange(null, 'forward', {
        certOfIncorp,
        statusReport,
        fileNames,
      });
    }
  };

  const onChange = (file, name) => {
    setFiles({
      ...files,
      [name]: file,
    });
    setFileNames({
      ...fileNames,
      [name]: file.name,
    });
    wipeErrorMesasge(name);
    setFileChanged((prev) => !prev);
  };

  const onError = (message, name) => {
    setFiles({
      ...files,
      [name]: null,
    });

    setErrorMessages({
      ...errorMessages,
      [name]: message,
    });
  };

  const removeFile = (name) => {
    wipeFile(name);
    wipeFileDataURI(name);
    wipeFileName(name);
    wipeErrorMesasge(name);
  };

  const no = useMemo(
    () => !!files?.certOfIncorp + !!files?.statusReport,
    [files]
  );

  useEffect(() => {
    let fileReader;
    let isCancel = false;

    if (files.certOfIncorp) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURI({
            ...fileDataURIs,
            certOfIncorp: result,
          });
        }
      };
      fileReader.readAsDataURL(files.certOfIncorp);
    }

    if (files.statusReport) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURI({
            ...fileDataURIs,
            statusReport: result,
          });
        }
      };
      fileReader.readAsDataURL(files.statusReport);
    }

    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileChanged]);

  return (
    <div className="onboarding-layout__inner">
      <div className="onboarding-layout__inner-right">
        <h4 className="semibold">Registration Documents</h4>
        <p>
          Please upload your certificate of incorporation and search report
          certified as a true copy by the Registrar of Companies.
        </p>
      </div>

      <div className="onboarding-layout__inner-left">
        <div className="onboarding-layout__inner-form">
          <FileUploaderBasic
            placeholder="Certification of Incorporation"
            name="certOfIncorp"
            onError={onError}
            onChange={onChange}
            maxSize={5000}
            variant="v2"
            isCompleted={files?.certOfIncorp}
            fileName={fileNames?.certOfIncorp}
            onClear={() => removeFile('certOfIncorp')}
          />
          {errorMessages?.certOfIncorp && (
            <p className="caption file-uploader__error-messsage">
              {errorMessages?.certOfIncorp}
            </p>
          )}

          <FileUploaderBasic
            placeholder="Status Report"
            name="statusReport"
            onError={onError}
            onChange={onChange}
            maxSize={5000}
            variant="v2"
            fileName={fileNames?.statusReport}
            isCompleted={files?.statusReport}
            onClear={() => removeFile('statusReport')}
          />

          {errorMessages?.statusReport && (
            <p className="caption file-uploader__error-messsage">
              {errorMessages?.statusReport}
            </p>
          )}

          <div className="onboarding-layout__inner-info">
            <div className="icon">
              <InfoIcon />
            </div>
            <p className="body3">
              The allowed file types are PDF, JPG, and PNG. The maximum upload
              file size is 5MB.
            </p>
          </div>
          <div className="onboarding-layout__inner-footer">
            <Button
              type="submit"
              variant="secondary"
              onClick={() => onPageChange(null, 'backwards')}
            >
              Previous
            </Button>
            <Button
              type="submit"
              variant="primary"
              onClick={submit}
              disabled={no !== 2}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegDoc;

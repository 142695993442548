import { Link } from 'react-router-dom';
import { Card, Success } from 'okraui-ts';
import OkraLogo from 'assets/Images/OkraFullLogo.png';

const ResetPasswordSuccess = (props) => {
  const { location } = props;
  const email = location?.state?.email;

  if (!email) {
    props.history.push('/login');
  }

  return (
    <div className="auth login-layout">
      <img src={OkraLogo} alt="Okra Logo" className="auth__logo" />
      <Card className="success-card">
        <Success />
        <p>
          We've sent a password reset instruction to {email}
          {/* <span className="p medium">{email}</span> */}
        </p>
        <Link to="/login" className="center-text more-space p">
          Return to sign in
        </Link>
      </Card>
    </div>
  );
};

export default ResetPasswordSuccess;

import { createContext, useContext, useReducer, useMemo } from 'react';
import { useFlagsmith } from 'flagsmith/react';
import { api } from 'config';
import { createData, readData, clearData } from 'helpers';
import authReducer from './AuthReducer';
import { useCompany } from '../Company';
import { useSla } from '../SLA';
import { useBanks } from '../Banks';

const AuthContext = createContext();

const initState = {
  loggedIn: false,
  subLoggedIn: false,
  user: {},
  token: '',
  refreshToken: '',
};
const AuthProvider = (props) => {
  const [state, dispatch] = useReducer(authReducer, initState, () => {
    const localData = readData('auth');
    return localData || initState;
  });
  const value = useMemo(() => [state, dispatch], [state]);
  return <AuthContext.Provider value={value} {...props} />;
};

const useAuth = () => {
  const context = useContext(AuthContext);
  const { updateCompanyObj, remove: removeCompany } = useCompany();
  const { remove: removeSla } = useSla();
  const { remove: removeBanks } = useBanks();
  const flagsmith = useFlagsmith();

  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  const [state, dispatch] = context;
  const access = async (url, obj, signal) => {
    return api({ url, signal, body: obj })
      .then((res) => {
        if (res.status === 'success') {
          const {
            type,
            user,
            token,
            refresh_token,
            email,
            company: invitedCompany,
          } = res.data;

          if (type === '2fa') {
            return {
              status: 'success',
              data: email,
              message: '2fa',
            };
          }
          if (type === 'invite') {
            return {
              status: 'success',
              data: invitedCompany,
              message: 'invite',
            };
          }

          const { company, ...rest } = user;

          const data = {
            loggedIn: user.account_verified,
            subLoggedIn: !user.account_verified,
            token,
            refreshToken: refresh_token,
            user: { ...rest, company: company && company._id },
          };

          company && updateCompanyObj(company);
          createData('auth', data);

          if (company && !company.signed_sla) {
            return {
              status: 'success',
              data: user?.email,
              message: 'sla_not_signed',
            };
          }

          flagsmith.identify(user?.email);
          dispatch({ type: 'LOGIN', ...data });
          return { status: 'success' };
        }
        return {
          status: 'error',
          message: res.message || 'Error logging you in, please try again',
          data: res.data,
        };
      })
      .catch(() => {
        return {
          status: 'error',
          message: 'Error logging you in, please try again',
        };
      });
  };

  const LogoutActions = (props) => {
    const nextRouteLink = props?.nextRouteLink;

    dispatch({ type: 'LOGOUT', initState });
    clearData('auth');
    removeCompany();
    removeSla();
    removeBanks();

    clearData('ownAuth');
    clearData('ownSla');
    clearData('ownCompany');
    flagsmith.logout();

    if (!nextRouteLink) {
      window.location.reload();
    } else {
      window.location.pathname = nextRouteLink;
    }

    return true;
  };
  const ClearOnboarding = () => {
    dispatch({ type: 'LOGOUT', initState });
    clearData('auth');
    clearData('onboarding');
    clearData('company');
  };
  const loginUpdatedUser = (s = state) => {
    state.loggedIn = true;
    state.subLoggedIn = false;
    createData('auth', s);
  };
  const logout = () => {
    api({ url: 'auth/logout' });
    LogoutActions();
    return true;
  };
  const logoutAll = ({ nextRouteLink = null }) => {
    api({ url: 'auth/logout-all' });
    LogoutActions({ nextRouteLink });
    return true;
  };
  const updateUserObj = ({ company, tokens, ...rest }, login) => {
    const newUser = { ...state.user, ...rest };
    state.user = newUser;
    if (login) {
      state.loggedIn = true;
      state.subLoggedIn = false;
    }
    createData('auth', state);
    dispatch({ type: 'UPDATE_USER', data: newUser });
    return {
      status: 'success',
      message: 'Changes successfully saved',
      user: newUser,
    };
  };

  const updateUserAfterSigningSLA = ({ user, refreshToken, token }) => {
    const newUser = { ...state.user, ...user };

    state.user = newUser;
    state.loggedIn = true;
    state.subLoggedIn = false;
    state.refreshToken = refreshToken;
    state.token = token;
    createData('auth', state);
    dispatch({ type: 'UPDATE_USER', data: newUser });
  };

  const updateUser = (obj) => {
    return api({ url: 'user/update', body: obj })
      .then((res) => {
        if (res.status === 'success') {
          return updateUserObj(res.data);
        }
        return {
          status: 'error',
          message: res?.message || 'Error updating user, please try again',
        };
      })
      .catch(() => {
        return {
          status: 'error',
          message: 'Error updating user, please try again',
        };
      });
  };

  const ownerAuth = readData('ownAuth') || null;

  const toggleToOwner = () => {
    const auth = readData('ownAuth');
    const sla = readData('ownSla');
    const company = readData('ownCompany');

    createData('auth', auth);
    createData('sla', sla);
    createData('company', company);

    clearData('ownAuth');
    clearData('ownSla');
    clearData('ownCompany');

    window.location.href = '/';
  };

  const hasSeenUpdate = readData('hasSeenUpdate') || false;
  const updateSeenUpdate = (value) => {
    createData('hasSeenUpdate', value);
    window.location.reload();
  };

  return {
    ...state,
    access,
    logout,
    logoutAll,
    updateUser,
    updateUserObj,
    updateUserAfterSigningSLA,
    ownerAuth,
    toggleToOwner,
    loginUpdatedUser,
    ClearOnboarding,
    hasSeenUpdate,
    updateSeenUpdate,
  };
};

export { AuthProvider, useAuth };

import { fugaz } from '../pages/Builder/data/Functions';

export const normalizeAppData = (data) => {
  const { customInstitutions, institutions, institutions_type, ...rest } = data;

  return {
    ...rest,
    orientation: 'desktop',
    institutions:
      institutions?.length && institutions.length === 8 ? institutions : fugaz,
    institutions_type,
    customInstitutions: customInstitutions ?? institutions,
  };
};

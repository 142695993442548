import { TransactionIllustration } from 'assets';
import ProductInfoScreen from './ProductInfoScreen';

const TransScreen = () => {
  return (
    <ProductInfoScreen
      icon={<TransactionIllustration />}
      title="Access transactional statements across all banks."
      description=" Gain valuable insights into your customers financial patterns and behaviours. "
    />
  );
};

export default TransScreen;

import { useMemo, useState } from 'react';
import { Button, Icon } from 'okraui-ts';
import { api } from 'config';
import { useCompany, useAuth } from 'store';
import { clearData, convertDataURIToFile } from 'helpers';
import { Pencil } from 'assets';

const VerificationSummary = ({
  onPageChange,
  company,
  open,
  countries,
  history,
}) => {
  const [loading, setLoading] = useState(false);
  const { updateCompanyObj } = useCompany();
  const { updateUserObj } = useAuth();

  const submit = () => {
    const data = new FormData();
    data.append('app_name', company.app_name);
    data.append(
      'status_report',
      convertDataURIToFile(
        company?.statusReport,
        company?.fileNames?.statusReport
      )
    );
    data.append('country', company.country);
    data.append('industry', company.industry);
    data.append(
      'cert_of_incorp',
      convertDataURIToFile(
        company?.certOfIncorp,
        company?.fileNames?.certOfIncorp
      )
    );
    data.append('migration_type', company.migration_type);
    data.append('name', company.name);
    data.append('purpose', company.purpose);
    data.append('rc_info', JSON.stringify(company.rc_info));
    data.append('rc_number', company.rc_number);
    data.append('rc_verified', company.rc_verified);
    data.append('tin_number', company.tin_number);
    data.append('website', company.website);
    data.append('owner', company.owner);
    setLoading(true);

    api({
      url: 'company/migrate-account',
      body: data,
      token: company.token,
      formData: true,
    }).then((response) => {
      setLoading(false);
      if (response.status === 'success') {
        clearData('onboarding');
        const { company, project, projects, companies, account_verified } =
          response.data; // TODO
        updateCompanyObj(company);
        updateUserObj(
          {
            company: company._id,
            project,
            projects,
            companies,
            account_verified,
          },
          true
        );
        history.push('/sla');
      } else {
        open({
          active: true,
          message:
            response.message || 'Could not register company please try again',
          status: 'error',
        });
      }
    });
    // onPageChange(null, 'forward');
  };

  const countryName = useMemo(() => {
    return countries.filter((country) => country.value === company.country)?.[0]
      ?.label;
  }, [countries, company]);

  return (
    <div className="onboarding-layout__inner onboarding-layout__inner--summary">
      <div className="onboarding-layout__inner-description">
        <div>
          <h4 className="semibold">Summary</h4>
          <p>
            Please review and confirm your information, and make any necessary
            changes or updates.
          </p>
        </div>

        <div className="onboarding-layout__inner-action">
          <Button
            type="submit"
            variant="primary"
            onClick={submit}
            loading={loading}
          >
            Submit and continue
          </Button>
        </div>
      </div>
      <div className="onboarding-layout__inner-right">
        <div className="onboarding-layout__inner-form">
          <div className="row-line">
            <div className="row-line__header">
              <p className="semibold body2">Company information</p>
              <Button
                onClick={() => onPageChange(0)}
                variant="secondary"
                leftIcon={<Pencil />}
              >
                Edit
              </Button>
            </div>

            <div className="row-line__item">
              <label className="caption">Registered company name</label>
              <p className="body3">{company.name}</p>
            </div>

            <div className="row-line__item">
              <label className="caption">RC number</label>
              <p className="body3">{company.rc_number}</p>
            </div>

            <div className="row-line__item">
              <label className="caption">Tax Identification number (TIN)</label>
              <p className="body3">{company.tin_number}</p>
            </div>

            <div className="row-line__item">
              <label className="caption">Company website</label>
              <a
                className="body3"
                href={company.website}
                target="_blank"
                rel="noreferrer"
              >
                {company.website}
              </a>
            </div>

            <div className="row-line__item">
              <label className="caption">Country</label>
              <p className="body3">{countryName}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="onboarding-layout__inner-left">
        <div className="onboarding-layout__inner-form">
          <div className="row-line">
            <div className="row-line__header">
              <p className="semibold body2">Registered documents</p>
              <Button
                onClick={() => onPageChange(1)}
                variant="secondary"
                leftIcon={<Pencil />}
              >
                Edit
              </Button>
            </div>

            {company?.fileNames?.certOfIncorp && (
              <div className="row-line__item uploaded-file">
                <p className="caption">{company.fileNames.certOfIncorp}</p>
                <Icon name="fileAlt" size="sm" />
              </div>
            )}

            {company?.fileNames?.statusReport && (
              <div className="row-line__item uploaded-file">
                <p className="caption">{company.fileNames.statusReport}</p>
                <Icon name="fileAlt" size="sm" />
              </div>
            )}
          </div>

          <div className="row-line">
            <div className="row-line__header">
              <p className="semibold body2">Business summary</p>
              <Button
                onClick={() => onPageChange(2)}
                variant="secondary"
                leftIcon={<Pencil />}
              >
                Edit
              </Button>
            </div>

            <div className="row-line__item">
              <label className="caption">Industry</label>
              <p className="body3">{company.industry.toString()}</p>
            </div>

            <div className="row-line__item">
              <label className="caption">Business name</label>
              <p className="body3">{company.app_name}</p>
            </div>

            <div className="row-line__item">
              <label className="caption">Business summary</label>
              <p className="body3">{company.purpose}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerificationSummary;

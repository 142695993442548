import { Button, Toggle } from 'okraui-ts';
import { useBuilder } from 'store';
import { addRemove } from 'helpers';
import clsx from 'classnames';

const Identity = () => {
  const { builder, setBuilder } = useBuilder();
  const { billable_products } = builder;
  const hasIdentity = billable_products.includes('identity');
  const instantPaymentEnabled = builder.type === 'one-time';

  return (
    <>
      <div className="product-section-info">
        <p className="semibold body2">Identity</p>
        <p className="subtitle body3">
          Retrieve the complete KYC profile for an individual or corporate
          entity.
        </p>

        <a
          href="https://docs.okra.ng/account-data/identity"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="primary-link">Read the docs</Button>
        </a>
      </div>
      <div className="product-section">
        <div
          className={clsx('action-section', {
            disabled: instantPaymentEnabled,
          })}
        >
          <p className="body3 medium">Enable identity</p>
          <Toggle
            checked={hasIdentity}
            disabled={instantPaymentEnabled}
            onChange={({ target }) => {
              const { checked } = target;

              if (checked) {
                const newPrds = addRemove(billable_products, 'identity');
                setBuilder('billable_products', newPrds);
              } else {
                const newPrds = addRemove(billable_products, 'identity');
                setBuilder('billable_products', newPrds);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Identity;

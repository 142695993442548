import { Button, Card } from 'okraui-ts';
import { NotFoundIcon } from 'assets';

const NotFound = (props) => {
  const { history } = props;
  return (
    <Card className="not-found">
      <h2>404 Error</h2>
      <p>Sorry, the page you requested does not exist!</p>
      <NotFoundIcon />
      <Button type="primary" onClick={() => history.push('/')}>
        Return Home
      </Button>
    </Card>
  );
};

export default NotFound;

import { getPillVariant } from 'helpers';
import { Folder, Radio, Pill, FileUploaderBasic } from 'okraui-ts';

const CACForms = ({ verificationStatus, formData, handleChange }) => {
  const { cac_form_type } = formData;

  const descriptions = {
    none: 'Which of these CAC forms is relevant to your company?',
    pending:
      'Your CAC documents is undergoing checks by our team. This could take up to 2 working days, please be patient.',
    failed:
      'Your registration documents could not be verified. Please re-upload the appropriate CAC form relevant to your company so we can fully grant you access to use payments.',
    success: 'Your CAC form is fully verified',
  };

  const isSelected = (formType) => {
    return cac_form_type === formType;
  };

  return (
    <div className="request-access-section">
      <span className="request-access-section__icon">
        <Folder />
      </span>
      <div className="request-access-section__container">
        <div className="request-access-section__header">
          <p>CAC forms</p>
          {verificationStatus && (
            <Pill
              variant={getPillVariant[verificationStatus]}
              className="text-capitalize"
            >
              {verificationStatus}
            </Pill>
          )}
        </div>
        <p className="dark-grey-font">
          {descriptions[verificationStatus || 'none']}
        </p>
        {(!verificationStatus || verificationStatus === 'failed') && (
          <div className="request-access-section__radio-group">
            <Radio
              label={
                <>
                  <div className="action-text">CAC 2 & CAC 7</div>
                  <div className="caption dark-grey-font">
                    Applicable to Companies incorporated before the Executive
                    Order for Ease of Doing Business approved in 2017.
                  </div>
                </>
              }
              name="cac_form_type"
              value="cac_2_7"
              checked={isSelected('cac_2_7')}
              onChange={handleChange}
              boxed
            />
            {isSelected('cac_2_7') && (
              <div className="cac-forms-inputs">
                <FileUploaderBasic
                  placeholder="CAC 2"
                  onError={() =>
                    handleChange({ target: { name: 'cac_2', value: null } })
                  }
                  onChange={(file) =>
                    handleChange({ target: { name: 'cac_2', value: file } })
                  }
                  maxSize={5000}
                />
                <FileUploaderBasic
                  placeholder="CAC 7"
                  name="cac_7"
                  onError={() =>
                    handleChange({ target: { name: 'cac_7', value: null } })
                  }
                  onChange={(file) =>
                    handleChange({ target: { name: 'cac_7', value: file } })
                  }
                  maxSize={5000}
                />
              </div>
            )}
            <Radio
              label={
                <>
                  <div className="action-text">Form CAC 1.1</div>
                  <div className="caption dark-grey-font">
                    Application for Registration of Company for entities
                    incorporated post the executive order for Ease of Doing
                    Business approved in 2017.
                  </div>
                </>
              }
              name="cac_form_type"
              value="cac_1_1"
              checked={isSelected('cac_1_1')}
              onChange={handleChange}
              boxed
            />
            {isSelected('cac_1_1') && (
              <div className="cac-forms-inputs">
                <FileUploaderBasic
                  placeholder="Form CAC 1.1"
                  onError={() =>
                    handleChange({ target: { name: 'cac_1_1', value: null } })
                  }
                  onChange={(file) =>
                    handleChange({ target: { name: 'cac_1_1', value: file } })
                  }
                  maxSize={5000}
                />
              </div>
            )}
            <Radio
              label={
                <>
                  <div className="action-text">
                    Application for registration
                  </div>
                  <div className="caption dark-grey-font">
                    Electronically-generated copy of Application for
                    Registration Company (Companies incorporated after December
                    2020)
                  </div>
                </>
              }
              name="cac_form_type"
              value="reg_application"
              checked={isSelected('reg_application')}
              onChange={handleChange}
              boxed
            />

            {isSelected('reg_application') && (
              <div className="cac-forms-inputs">
                <FileUploaderBasic
                  placeholder="Application for registration"
                  onError={() =>
                    handleChange({
                      target: { name: 'reg_application', value: null },
                    })
                  }
                  onChange={(file) =>
                    handleChange({
                      target: { name: 'reg_application', value: file },
                    })
                  }
                  maxSize={5000}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CACForms;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { TextArea, Modal } from 'okraui-ts';
import { api } from 'config';
import classNames from 'classnames';

const defaultModalOptions = {
  title: 'Log issue with support',
  type: 'standard',
  status: 'success',
  message: '',
  closeTxt: 'Cancel',
  description: 'You may also want to add a note to this issue where necessary.',
  confirmTxt: 'Log issue',
};

const SupportModal = ({
  active,
  close,
  type,
  id,
  modalOptions,
  className = 'support-modal',
  placeholder = 'Write a note',
  maxLength,
}) => {
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);
  const [_modalOptions, setModalOptions] = useState(
    modalOptions || defaultModalOptions
  );

  const handleSubmit = async () => {
    setLoading(true);

    const data = {
      type,
      id,
      note,
    };

    const { status, message } = await api({
      url: 'company/support',
      body: data,
    });

    setLoading(false);

    if (status === 'success') {
      setModalOptions({
        title: 'Report sent',
        type: 'alert',
        status: 'success',
        message:
          'Your report is sent successfully. Our support team will respond to your report shortly.',
        closeTxt: 'Done',
      });
    } else {
      setModalOptions({
        title: 'Error sending report',
        type: 'alert',
        status: 'failed',
        message: message || 'Some error occurred, please try again',
        closeTxt: 'Close',
      });
    }
  };

  const disabledForm = !note.length;

  const closeModal = () => {
    close();
    setNote('');
    setLoading(false);
    setTimeout(() => {
      setModalOptions(modalOptions || defaultModalOptions);
    }, 1000);
  };

  return (
    <Modal
      title={_modalOptions?.title}
      active={active}
      close={closeModal}
      confirmTxt={_modalOptions?.confirmTxt}
      confirm={_modalOptions?.type === 'standard' ? handleSubmit : null}
      closeTxt={_modalOptions?.closeTxt}
      loading={loading}
      type={_modalOptions?.type}
      status={_modalOptions?.status}
      modalClass={classNames(`support-modal ${className}`, {
        'sla-support-modal__disabled': disabledForm,
        'support-modal__disabled': disabledForm,
        'support-modal__loading': loading,
      })}
    >
      {_modalOptions?.type === 'standard' ? (
        <>
          {_modalOptions?.description && (
            <p className="medium space-below">{_modalOptions?.description}</p>
          )}
          <TextArea
            style={{ resize: 'none' }}
            label="Message"
            placeholder={placeholder}
            value={note}
            maxLength={maxLength}
            onChange={(e) => setNote(e.target.value)}
          />
        </>
      ) : (
        <p className="dark-grey small">{_modalOptions?.message}</p>
      )}
    </Modal>
  );
};

SupportModal.propTypes = {
  active: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default SupportModal;

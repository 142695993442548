import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';

import { Button, useToaster } from 'okraui-ts';

const StripeCheckoutForm = () => {
  const baseStyle = {
    '::placeholder': {
      color: '#7888A1',
    },
  };
  const invalidStyle = {
    color: '#344053',
  };
  return (
    <div className="stripe-form">
      <div className="input-container">
        <p className="body3 regular">Card number</p>
        <CardNumberElement
          className="textfield-container "
          options={{
            showIcon: true,
            style: {
              base: baseStyle,
              invalid: invalidStyle,
            },
          }}
        />
      </div>
      <div className="input-columns input-columns-2">
        <div className="input-container">
          <p className="body3 regular">Expiry date</p>
          <CardExpiryElement
            className="textfield-container"
            options={{
              style: {
                base: baseStyle,
                invalid: invalidStyle,
              },
            }}
          />
        </div>
        <div className="input-container">
          <p className="body3 regular">CVC</p>
          <CardCvcElement
            className="textfield-container"
            options={{
              style: {
                base: baseStyle,
                invalid: invalidStyle,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

const StripeCheckoutButton = ({
  disabled,
  amount,
  onSuccess,
  loading,
  text,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { open } = useToaster();

  const [isGeneratingToken, setGeneratingToken] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emptyElements = document.getElementsByClassName(
      'StripeElement--empty'
    );
    const invalidElements = document.getElementsByClassName(
      'StripeElement--invalid'
    );

    if (emptyElements.length) {
      open({
        active: true,
        message: 'Please fill in all fields',
        status: 'error',
      });
    } else if (invalidElements.length) {
      open({
        active: true,
        message: 'Please provide valid card details',
        status: 'error',
      });
    } else {
      setGeneratingToken(true);

      try {
        const { token } = await stripe.createToken(
          elements.getElement(CardNumberElement)
        );

        onSuccess?.(token);
        setGeneratingToken(false);
      } catch (error) {
        setGeneratingToken(false);
        open({
          active: true,
          message: 'An error occured. Please try again.',
          status: 'error',
        });
      }
    }
  };

  return (
    <Button
      type="button"
      onClick={handleSubmit}
      disabled={disabled || !stripe || !elements}
      loading={loading || isGeneratingToken}
    >
      {amount ? `Pay ${amount}` : text}
    </Button>
  );
};

StripeCheckoutButton.propTypes = {
  amount: PropTypes.number,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onSuccess: PropTypes.func,
  text: PropTypes.string,
};

export { StripeCheckoutButton, StripeCheckoutForm };

import { Button } from 'okraui-ts';
import { CustomAppIcon, RecommendedTemplatesIcon } from 'assets/Icons';
import BaseHeader from './components/BaseHeader';

const Landing = (props) => {
  const { history } = props;
  return (
    <div className="builder-landing">
      <BaseHeader />
      <div className="builder-landing-content">
        <h4 className="bold">How would you like to create your app?</h4>
        <div className="builder-landing-content__options">
          <div>
            <div className="icon">
              <RecommendedTemplatesIcon />
            </div>
            <h5 className="bold">Recommended templates</h5>
            <p className="body2">
              Answer a few simple questions and automatically get an app
              customized just for you in minutes.
            </p>
            <Button onClick={() => history.push('/app-builder/custom')}>
              Use template
            </Button>
          </div>
          <div>
            <div className="icon">
              <CustomAppIcon />
            </div>
            <h5 className="bold">Custom app</h5>
            <p className="body2">
              Easily customize your app to match product and customer
              expectations in less than two minutes.
            </p>
            <Button onClick={() => history.push('/app-builder/edit/products')}>
              Create custom app
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;

import {
  APIKeys,
  HomeIcon,
  WalletAltIcon,
  BriefcaseIcon,
  CirclePlusIcon,
  UsersIcon,
  // HomeAltIcon,
  FolderAltIcon,
  // LockAltIcon,
  IdentityIcon,
  BalanceIcon,
  ArrowInOutIcon,
  IncomeIcon,
  // CardLinkIcon,
  // SendIcon,
  // CardArrowOutIcon,
  // FlagIcon,
  BellIcon,
  LayersIcon,
  // ServerIcon,
  ReceiptIcon,
  UserAltIcon,
  // BuildingIcon,
  ListsIcon,
  BuildingAlt,
  // CalculatorIcon,
  PayoutsIcon,
  RecordAltIcon,
  RefundsAltIcon,
} from 'assets';

import { routes } from 'config';

export const menu = [
  {
    label: 'Home',
    route: routes.dashboard.home,
    permission: 'dashboard',
    icon: <HomeIcon />,
  },

  // How does the premission work now for business?

  {
    label: 'Business',
    route: '/business',
    group: 'BUSINESS',
    permission: 'business',
    children: [
      {
        label: 'Customers',
        route: routes.business.customers,
        icon: <UsersIcon />,
        permission: 'customers',
      },

      {
        label: 'Apps',
        route: routes.business.apps,
        icon: <CirclePlusIcon />,
        permission: 'links',
      },

      {
        label: 'Records',
        route: routes.data.records,
        icon: <FolderAltIcon />,
        permission: 'records',
      },

      {
        label: 'Wallet',
        route: routes.business.wallet,
        icon: <WalletAltIcon />,
        permission: 'wallet',
        prodOnly: true,
      },
    ],
  },

  {
    label: 'Data',
    route: '/data',
    group: 'DATA',
    permission: 'products',
    children: [
      // {
      //   label: 'Overview',
      //   route: routes.data.overview,
      //   icon: <HomeAltIcon />,
      //   permission: 'products',
      // },

      // What happens to this view?
      /*   {
        label: 'Authentication',
        route: routes.data.authenication,
        icon: <LockAltIcon />,
        permission: 'auths',
      }, */

      {
        label: 'Identity',
        route: routes.data.identity,
        icon: <IdentityIcon />,
        permission: 'identities',
      },
      {
        label: 'Balance',
        route: routes.data.balance,
        icon: <BalanceIcon />,
        permission: 'balances',
      },
      {
        label: 'Transactions',
        route: routes.data.transactions,
        icon: <ArrowInOutIcon />,
        permission: 'transactions',
      },
      {
        label: 'Income',
        route: routes.data.income,
        icon: <IncomeIcon />,
        permission: 'incomes',
      },

      // {
      //   label: 'Reconciliation',
      //   route: routes.data.reconciliation,
      //   icon: <CalculatorIcon />,
      //   permission: 'incomes',
      //   status: 'new',
      // },
    ],
  },

  {
    label: 'Payments',
    route: '/payments',
    group: 'PAYMENTS',
    permission: 'payments',
    children: [
      // {
      //   label: 'Overview',
      //   route: routes.payments.overview,
      //   icon: <HomeAltIcon />,
      //   permission: 'products',
      // },

      {
        label: 'Records',
        route: routes.payments.records,
        icon: <RecordAltIcon />,
        permission: 'payments',
      },
      // {
      //   label: 'Links',
      //   route: routes.payments.links,
      //   icon: <CardLinkIcon />,
      //   permission: 'payments',
      // },
      // {
      //   label: 'Transfers',
      //   route: routes.payments.transfers,
      //   icon: <SendIcon />,
      //   permission: 'transactions',
      // },
      {
        label: 'Payouts',
        route: routes.payments.payouts,
        icon: <PayoutsIcon />,
        permission: 'payments',
      },
      {
        label: 'Refunds',
        route: routes.payments.refunds,
        icon: <RefundsAltIcon />,
        permission: 'payments',
      },

      // {
      //   label: 'Disputes',
      //   route: routes.payments.disputes,
      //   icon: <FlagIcon />,
      //   permission: 'payments',
      //   badge: 4,
      // },
    ],
  },
  {
    group: 'MANAGE',
    label: 'Manage',
    route: null,
    permission: 'all',
  },

  {
    label: 'Developers',
    route: '/developers',
    permission: 'payments',
    children: [
      {
        label: 'API Keys',
        route: routes.developers.apiKeys,
        icon: <APIKeys />,
        permission: 'keys',
      },
      {
        label: 'Webhooks',
        route: routes.developers.webhook,
        icon: <BellIcon />,
        permission: 'webhooks',
      },
      {
        label: 'Events',
        route: routes.developers.events,
        icon: <LayersIcon />,
        permission: 'events',
        prodOnly: true,
      },
      // {
      //   label: 'Bank Status',
      //   route: routes.developers.bankStatus,
      //   icon: <ServerIcon />,
      //   permission: 'all',
      // },
    ],
  },
  {
    label: 'Settings',
    route: '/settings',
    permission: 'all',
    children: [
      {
        label: 'Businesses',
        route: routes.settings.business,
        icon: <BriefcaseIcon />,
        permission: 'projects',
      },

      {
        label: 'Clients',
        route: routes.business.clients,
        icon: <BuildingAlt />,
        permission: 'clients',
      },

      {
        label: 'Billing',
        route: routes.settings.billing,
        icon: <ReceiptIcon />,
        permission: 'plan',
      },
      {
        label: 'Profile',
        route: routes.settings.profile,
        icon: <UserAltIcon />,
        permission: 'all',
      },
      // {
      //   label: 'Company',
      //   route: routes.settings.company,
      //   icon: <BuildingIcon />,
      //   permission: 'team',
      // },

      {
        label: 'Team',
        route: routes.settings.team,
        icon: <UsersIcon />,
        permission: 'bank_accounts',
      },
      {
        label: 'Notifications',
        route: routes.settings.notifications,
        icon: <BellIcon />,
        variant: 'fill',
        permission: 'notifications',
      },
      {
        label: 'Audit Logs',
        route: routes.settings.auditLogs,
        icon: <ListsIcon />,
        permission: 'logs',
      },
    ],
  },
];

export const sections = {
  dashboard: 0,
  business: 1,
  data: 2,
  payments: 3,
  developers: 4,
  settings: 5,
};

export const findPage = (path) => {
  if (path) {
    const paths = path.split('/');
    const parent = paths[1];
    const child = paths[2];
    let page = {};

    const Parent = menu.find(
      (objs) => objs?.route?.replace?.('/', '') === parent
    );
    if (Parent) {
      const Children = Parent.children;
      const Child = Children.find((objs) => {
        return objs.route.replace('/', '') === child;
      });
      page = Child;
    }
    return page;
  }
  return {};
};

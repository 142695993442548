import React from 'react';
import PropTypes from 'prop-types';
import ProfilePlaceholderImage from 'assets/Images/ProfilePlaceholder.png';

const altText = 'profile placeholder image';

function ProfilePlaceholder({
  alt = altText,
  src = ProfilePlaceholderImage,
  ...rest
}) {
  return <img alt={alt} src={src} {...rest} />;
}

ProfilePlaceholder.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
};

export default ProfilePlaceholder;

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Select, EmptyState } from 'okraui-ts';
import { EmptyStateImage } from 'assets';

const NoProject = ({ switchProject, projects, loading }) => {
  const [showProjects, setShowProjects] = useState(false);
  const [selected, setSelected] = useState(null);
  const handleSwitch = () => {
    if (selected) {
      switchProject(selected);
      setShowProjects(false);
    }
  };
  const displayProjects = () => {
    const newProjects =
      projects &&
      projects.map((p) => {
        return {
          label: p.name,
          value: p._id,
        };
      });
    return newProjects || [];
  };
  return (
    <div className="no-project">
      <EmptyState
        icon={<EmptyStateImage />}
        title="No access to business"
        description="You no longer have access to this project because your admin removed
          you. Contact your admin for more info."
        action={
          projects && (
            <Button
              size="md"
              onClick={() => setShowProjects(true)}
              loading={loading}
            >
              Switch Project
            </Button>
          )
        }
      />

      <Modal
        title="Switch to project"
        active={showProjects}
        close={() => setShowProjects(false)}
        confirmTxt="Switch to project"
        confirm={handleSwitch}
      >
        <p className="space-below">
          Please select a project you want to switch to.
        </p>

        <Select
          label="Projects"
          options={displayProjects()}
          onChange={({ target }) => setSelected(target.value)}
          showSearch={false}
          className="space-below"
        />
      </Modal>
    </div>
  );
};

NoProject.propTypes = {
  loading: PropTypes.bool,
  projects: PropTypes.array,
  switchProject: PropTypes.func,
};

export default NoProject;

import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/index.scss';
import 'okraui-ts/dist/styles/index.scss';
import Okra from './Okra';

ReactDOM.render(
  <React.StrictMode>
    <Okra />
  </React.StrictMode>,
  document.getElementById('root')
);

import { Button, Toggle, CurrencyField, TextArea, Checkbox } from 'okraui-ts';
import { useBuilder } from 'store';
import { useState } from 'react';

const InstantPayment = () => {
  const { setBuilder, builder } = useBuilder();
  const [showForm, setShowForm] = useState(builder.type === 'one-time');

  const onFormValueChange = ({ target }) => {
    const { name, value } = target;
    setBuilder(name, value);
  };

  return (
    <>
      <div className="product-section-info">
        <p className="semibold body2">Instant payment</p>
        <p className="subtitle body3">
          Connect customers’ bank accounts to your app and start receiving
          real-time bank payments.
        </p>

        <a
          href="https://docs.okra.ng/payments"
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="primary-link">Read the doc</Button>
        </a>
      </div>
      <div className="product-section">
        <div className="action-section">
          <p className="body3 medium">Enable instant payment</p>
          <Toggle
            checked={showForm}
            onChange={({ target }) => {
              const { checked } = target;
              setShowForm(checked);
              setBuilder('type', checked ? 'one-time' : 'none');
            }}
          />
        </div>
      </div>

      {showForm && (
        <div className="product-form">
          <TextArea
            placeholder="Please see payment details below"
            label="Subtext"
            maxLength={false}
            name="payment_subtext"
            value={builder.payment_subtext}
            onChange={onFormValueChange}
          />

          <TextArea
            placeholder="Burger and chips"
            label="Product or service description"
            maxLength={false}
            name="note"
            value={builder?.note}
            onChange={onFormValueChange}
          />

          <CurrencyField
            label="Amount"
            value={builder.amount}
            name="amount"
            disabled={builder?.variablePayment}
            onChange={onFormValueChange}
            disabledCurrencySelector
          />
          <Checkbox
            label="Let customers choose the payment amount."
            checked={builder?.variablePayment}
            name="variablePayment"
            onChange={(e) => {
              const { checked, name } = e.target;
              onFormValueChange({ target: { name, value: checked } });
            }}
          />
        </div>
      )}
    </>
  );
};

export default InstantPayment;
